import { Save } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import { Button, Divider, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { Paths } from 'src/Routes';
import { postRequest } from 'src/ajax/ajax';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemHeader,
  DescriptionListItemValue,
} from 'src/components/shared/DescriptionList/DescriptionList';
import { ValidationErrors } from 'src/components/shared/Error/ValidationErrors';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { LeadType } from 'src/components/shared/Typography/LeadType';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useRemainingPageHeight } from 'src/hooks/useRemainingPageHeight';
import { StevneUpload } from 'src/models/Stevne';
import { Page } from '../../../shared/Page/Page';
import { Header1 } from '../../../shared/Typography/Header1';
import { StyledWarningButton } from '../StevneEditPage';
import { mapStevneUploadToStevneFormState } from '../Stevner.mappers';
import { StevnerUploadProtokollResponse } from '../Stevner.types';
import { stevneSchema } from '../StevnerForm/StevnerForm.schema';
import { StevnerUploadWarningModal } from '../StevnerForm/StevnerFormWarningModal';
import { StevnerUploadProtokollModal } from '../StevnerUploadProtokollModal';
import { StevneUploadPulje } from './StevneUploadPulje';
import { findStevnePeriodFromStevneUpload } from 'src/utils/stevne';

export const StevneUploadPreviewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState(0);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [showStevneWarnings, setShowStevneWarnings] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState<
    string[]
  >([]);

  const { stevne, warnings } =
    (location.state as StevnerUploadProtokollResponse) ?? {};

  const hasWarnings = warnings.length > 0;

  useEffect(() => {
    setShowStevneWarnings(warnings.length > 0);
  }, [warnings]);

  useEffect(() => {
    setShowStevneWarnings(hasWarnings);
  }, [hasWarnings]);

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const handleSaveStevne = async (stevne: StevneUpload) => {
    const stevneFormState = mapStevneUploadToStevneFormState(stevne);
    let isValid = false;

    await stevneSchema
      .validate(stevneFormState, { abortEarly: false })
      .then(() => {
        isValid = true;
      })
      .catch(err => {
        const messages = err.inner.map(
          (e: { message: string; path: string }) => e.message
        );
        setValidationErrorMessages(messages);
      });

    if (!isValid) {
      return;
    }

    const response = await postRequest('/stevne/', stevneFormState);

    if (response.ok) {
      showSuccessNotification('Stevne opprettet');
      navigate(Paths.stevner.base);
    } else {
      const errorResponse = response.error?.response;
      const messages = errorResponse?.data;
      if (errorResponse?.status === 400 && messages?.length > 0) {
        setValidationErrorMessages(messages);
      } else {
        showErrorNotification('Kunne ikke opprette stevne');
      }
    }
  };

  const numberOfLifters = stevne?.puljeList.reduce(
    (acc, cum) => acc + (cum.resultatList?.length ?? 0),
    0
  );
  const datePeriod = findStevnePeriodFromStevneUpload(stevne);

  const { ref: tabsRef } = useRemainingPageHeight<HTMLButtonElement>();

  return (
    <Page>
      {validationErrorMessages.length > 0 && (
        <ValidationErrors messages={validationErrorMessages} />
      )}
      {!!stevne && (
        <>
          <Header1>{stevne?.stevnetype?.navn ?? '–'}</Header1>

          <Divider className="mt-4 mb-6" />

          <div className="flex jc-sb">
            <DescriptionList className="mb-8">
              <DescriptionListItem>
                <DescriptionListItemHeader>Type</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {stevne.stevnetype?.navn ?? '–'}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>Sted</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {stevne.sted ?? '–'}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>Klubb</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {stevne.arrangorklubb?.fulltNavn ?? '–'}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>
                  Antall løftere
                </DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {numberOfLifters}
                </DescriptionListItemValue>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionListItemHeader>Dato</DescriptionListItemHeader>
                <DescriptionListItemValue>
                  {datePeriod || 'Mangler dato'}
                </DescriptionListItemValue>
              </DescriptionListItem>
            </DescriptionList>

            <div className="flex align-center gap-4 ml-auto">
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSaveStevne(stevne)}
                disabled={hasWarnings}
              >
                Lagre stevne
              </Button>

              <Button
                variant="outlined"
                onClick={() => setIsUploadModalOpen(true)}
                endIcon={<UploadIcon />}
              >
                Last opp på nytt
              </Button>
            </div>
          </div>

          <Tabs
            ref={tabsRef}
            orientation="horizontal"
            variant="scrollable"
            value={tab}
            onChange={onTabChange}
            className="mb-8"
          >
            {stevne.puljeList.map(pulje => (
              <Tab
                key={pulje.puljeNr}
                label={`Pulje ${pulje.puljeNr}`}
                className="align-start"
              />
            ))}
          </Tabs>

          {stevne.puljeList.map((pulje, index) => (
            <TabPanel key={pulje.puljeNr} value={tab} index={index}>
              <StevneUploadPulje pulje={pulje} />

              {pulje.beskrivelse && (
                <section className="mt-12" style={{ maxWidth: '70ch' }}>
                  <LeadType>Beskrivelse</LeadType>
                  <Paragraph className="mt-4">{pulje.beskrivelse}</Paragraph>
                </section>
              )}
            </TabPanel>
          ))}
        </>
      )}

      <StevnerUploadProtokollModal
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        setValidationErrorMessages={setValidationErrorMessages}
      />

      {hasWarnings && (
        <StyledWarningButton
          variant="extended"
          onClick={() => setShowStevneWarnings(true)}
          size="medium"
        >
          Vis merknader fra opplasting
        </StyledWarningButton>
      )}

      <StevnerUploadWarningModal
        open={showStevneWarnings}
        warnings={warnings || []}
        onClose={() => setShowStevneWarnings(false)}
      />
    </Page>
  );
};
