import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { useSort } from 'src/hooks/useSort';
import { PublicResultat } from 'src/models/Stevne';
import { Colors } from 'src/utils/colors';
import { sortByProperty } from 'src/utils/sort';

type PublicResultatListProps = {
  resultater: PublicResultat[];
};

export function PublicResultatList({ resultater }: PublicResultatListProps) {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<PublicResultat>('historicPersonName');

  const sortedResultater = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(resultater, sortedBy, sortDirection);
    }

    return resultater;
  }, [resultater, sortedBy, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('historicPersonName')}
                direction={getDirection('historicPersonName')}
                onClick={() => requestSort('historicPersonName')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>Klubb</TableCellHeader>
            <TableCellHeader>Vektklasse</TableCellHeader>
            <TableCellHeader>Kategori</TableCellHeader>
            <TableCellHeader>Rykk 1</TableCellHeader>
            <TableCellHeader>Rykk 2</TableCellHeader>
            <TableCellHeader>Rykk 3</TableCellHeader>
            <TableCellHeader>Støt 1</TableCellHeader>
            <TableCellHeader>Støt 2</TableCellHeader>
            <TableCellHeader>Støt 3</TableCellHeader>
            <TableCellHeader>Total</TableCellHeader>
            <TableCellHeader>Poeng</TableCellHeader>
            <TableCellHeader>Veteranpoeng</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedResultater.map(resultat => (
            <TableRow key={resultat.startnummer}>
              <TableCell>{resultat.historicPersonName}</TableCell>
              <TableCell>{resultat.historicKlubb.navn}</TableCell>
              <TableCell>{resultat.vektklasse.navn}</TableCell>
              <TableCell>{resultat.kategori.forkortelse}</TableCell>
              <ForsoekCell
                weight={resultat.rykk1}
                maxWeight={resultat.besteRykk}
              />
              <ForsoekCell
                weight={resultat.rykk2}
                maxWeight={resultat.besteRykk}
              />
              <ForsoekCell
                weight={resultat.rykk3}
                maxWeight={resultat.besteRykk}
              />
              <ForsoekCell
                weight={resultat.stot1}
                maxWeight={resultat.besteStot}
              />
              <ForsoekCell
                weight={resultat.stot2}
                maxWeight={resultat.besteStot}
              />
              <ForsoekCell
                weight={resultat.stot3}
                maxWeight={resultat.besteStot}
              />
              <TableCell>{resultat.total ?? '–'}</TableCell>
              <TableCell>{resultat.poeng ?? '–'}</TableCell>
              <TableCell>{resultat.veteranpoeng ?? '–'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const UnderkjentLoeftCell = styled(TableCell)`
  color: ${Colors.tertiary};
  text-decoration: line-through;
`;

const BesteLoeftCell = styled(TableCell)`
  font-weight: bold;
`;

type ForsoekCellProps = {
  weight: Nullable<number>;
  maxWeight?: number;
};

export function ForsoekCell({ weight, maxWeight }: ForsoekCellProps) {
  if (!weight) {
    return <TableCell>–</TableCell>;
  }

  if (weight < 0) {
    return <UnderkjentLoeftCell>{weight}</UnderkjentLoeftCell>;
  }

  if (weight === maxWeight) {
    return <BesteLoeftCell>{weight}</BesteLoeftCell>;
  }

  return <TableCell>{weight}</TableCell>;
}
