import { format, isSameDay, isSameMonth, isSameYear, isValid, parse } from 'date-fns';

export const DateFormatApi = 'yyyy-MM-dd';
export const DateFormat = 'dd.MM.yyyy';

export function formatFromApiDate(apiDate: string) {
  const dateItems = apiDate.split('-');

  return `${dateItems[2]}.${dateItems[1]}.${dateItems[0]}`;
}

export function formatDate(
  date: Date | number | string,
  dateFormat = DateFormat
) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, dateFormat);
}

export function formatToApiDate(date: Date): string {
  return formatDate(date, DateFormatApi);
}

export function formatDatePeriod(dateA: Date, dateB: Date): string {
  if (isSameDay(dateA, dateB)) {
    return formatDate(dateA);
  }

  if (isSameMonth(dateA, dateB)) {
    const dateFormatWithoutYearAndMonth = 'dd';
    return `${formatDate(dateA, dateFormatWithoutYearAndMonth)} - ${formatDate(dateB)}`;
  }
  
  if (isSameYear(dateA, dateB)) {
    const dateFormatWithoutYear = 'dd.MM'
    return `${formatDate(dateA, dateFormatWithoutYear)} - ${formatDate(dateB)}`;
  }

  return `${formatDate(dateA)} - ${formatDate(dateB)}`; 
}

export function parseDate(dateString: string): Date {
  return parse(dateString, 'dd/MM/yyyy', new Date());
}

export function isValidDate(date: any): date is Date {
  if (!date) return false;

  return isValid(date);
}
