import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'src/components/shared/Form/Form';
import { Klubb } from 'src/models/Klubb';
import * as Yup from 'yup';
import { KlubbFormState, mapKlubbToKlubbFormState } from './KlubbForm.types';

export const validationSchema = Yup.object().shape({
  navn: Yup.string().nullable().required('Navn er påkrevd'),
  fulltNavn: Yup.string().nullable().required('Full navn er påkrevd'),
  forkortelse: Yup.string().nullable().required('Forkortelse er påkrevd'),
});

export const DefaultKlubb = {} as Klubb;

type KlubbFormProps = {
  klubb: Nullable<Klubb>;
  onSubmit: (state: KlubbFormState) => void;
  onCancel: () => void;
};

export function KlubbForm({ klubb, onSubmit, onCancel }: KlubbFormProps) {
  const klubbFormState = klubb ? mapKlubbToKlubbFormState(klubb) : DefaultKlubb;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<KlubbFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues: klubbFormState,
  });

  const onFormSubmit = (state: KlubbFormState) => {
    onSubmit(state);
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        name="navn"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Navn"
            error={!!errors.navn}
            helperText={errors.navn?.message}
          />
        )}
      />

      <Controller
        name="fulltNavn"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Fullt navn"
            error={!!errors.fulltNavn}
            helperText={errors.fulltNavn?.message}
          />
        )}
      />

      <Controller
        name="forkortelse"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Fortkortelse"
            error={!!errors.forkortelse}
            helperText={errors.forkortelse?.message}
          />
        )}
      />

      <div className="flex align-center mt-8">
        <Button variant="contained" type="submit">
          Lagre
        </Button>
        <Button variant="text" onClick={onCancel} className="ml-8">
          Avbryt
        </Button>
      </div>
    </Form>
  );
}
