import { Person } from 'src/models/Person';
import { RankingResultat } from 'src/models/Stevne';
import { postRequest } from './ajax';
import { DommerStatistics } from './hooks/useDommere';

export const ReportsApi = {
  downloadLoeftere: (personer: Person[]) => {
    return postRequest('/reports/loeftere/', personer);
  },
  downloadResultater: (resultater: RankingResultat[]) => {
    return postRequest('/reports/resultater/', resultater);
  },
  downloadDommere: (dommere: DommerStatistics[]) => {
    return postRequest(
      '/reports/dommere/',
      dommere.map(stat => {
        return {
          nvfId: stat.personNvfId,
          klubb: stat.personKlubbName,
          fornavn: stat.personFirstName,
          etternavn: stat.personLastName,
          fodselsdato: stat.personFodselsdato,
          kjonn: stat.personKjonn,
          dommergrad: stat.dommergrad,
          kursdato: stat.kursdato,
          stevnerCountLastYear: stat.stevnerCountLastYear,
          stevnerCountThisYear: stat.stevnerCountThisYear,
        };
      })
    );
  },
  downloadStevnePdf: (stevneId: number) => {
    return postRequest(
      '/reports/stevne-pdf/',
      { stevneId },
      { responseType: 'arraybuffer' }
    );
  },
};
