import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { StevnerUploadWarning } from '../Stevner.types';
import { StevnerFormWarningModalList } from './StevnerFormWarningModalList';

type StevnerUploadWarningModalProps = {
  open: boolean;
  warnings: StevnerUploadWarning[];
  onClose: () => void;
};

export function StevnerUploadWarningModal({
  open,
  warnings,
  onClose,
}: StevnerUploadWarningModalProps) {
  const personNotFoundWarningMessages = warnings
    .filter(warning => warning.key === 'person-not-found')
    .map(warning => warning.message);
  const dommerNotFoundWarningMessages = warnings
    .filter(warning => warning.key === 'dommer-not-found')
    .map(warning => warning.message);
  const validationWarningMessages = warnings
    .filter(warning => warning.key === 'validation')
    .map(warning => warning.message);

  const hasPersonNotFoundWarnings = personNotFoundWarningMessages.length > 0;
  const hasDommerNotFoundWarnings = dommerNotFoundWarningMessages.length > 0;
  const hasvValidationWarnings = validationWarningMessages.length > 0;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        <Header2>Merknader fra opplasting</Header2>
      </ModalHeader>

      <ModalBody className="flex gap-2">
        {hasPersonNotFoundWarnings && (
          <StevnerFormWarningModalList
            title="Personer som ikke finnes i systemet"
            subtitle="Undersøk om IDen er skrevet riktig eller opprett personen i
          systemet"
            messages={personNotFoundWarningMessages}
          />
        )}

        {hasDommerNotFoundWarnings && (
          <StevnerFormWarningModalList
            title="Personer som ikke er registrert som dommere"
            subtitle="Ta kontakt med NVF om du vil registrere en ny dommer"
            messages={dommerNotFoundWarningMessages}
          />
        )}

        {hasvValidationWarnings && (
          <StevnerFormWarningModalList
            title="Dette ser ut til å være feil i protokollen"
            messages={validationWarningMessages}
          />
        )}
      </ModalBody>
    </Modal>
  );
}
