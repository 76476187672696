import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { postRequest, putRequest, deleteRequest } from 'src/ajax/ajax';
import { KLUBBER_URI } from 'src/ajax/hooks/useKlubber';
import { ConfirmModal } from 'src/components/shared/Modal/ConfirmModal';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalHeader,
  ModalBody,
} from 'src/components/shared/Modal/Modal.styles';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useKlubb } from 'src/context/KlubbContext';
import { Klubb } from 'src/models/Klubb';
import {
  showSuccessNotification,
  showErrorNotification,
} from 'src/Notifications';
import { mutate } from 'swr';
import { Page } from '../../shared/Page/Page';
import { Header1 } from '../../shared/Typography/Header1';
import { KlubbForm } from './Klubb/KlubbForm';
import { KlubbFormState } from './Klubb/KlubbForm.types';
import { KlubberTable } from './KlubberTable';

export const KlubberPage = () => {
  const { klubber } = useKlubb();
  const [klubb, setKlubb] = useState<Nullable<Klubb>>(null);
  const [klubbToDelete, setKlubbToDelete] = useState<Nullable<Klubb>>(null);

  const onCreate = () => {
    setKlubb({} as Klubb);
  };

  const onCreateSubmit = async (state: KlubbFormState) => {
    const response = await postRequest<Klubb>(KLUBBER_URI, state);

    if (response.ok) {
      showSuccessNotification('Klubb opprettet');
      mutate(KLUBBER_URI);
    } else {
      showErrorNotification('Kunne ikke opprette klubb');
    }
    setKlubb(null);
  };

  const onEdit = (klubb: Klubb) => {
    setKlubb(klubb);
  };

  const onEditSubmit = async (id: number, state: KlubbFormState) => {
    const response = await putRequest<Klubb>(`${KLUBBER_URI}${id}/`, state);

    if (response.ok) {
      showSuccessNotification('Klubb oppdatert');
      mutate(KLUBBER_URI);
    } else {
      showErrorNotification('Kunne ikke oppdatere klubb');
    }
    setKlubb(null);
  };

  const onFormSubmit = async (state: KlubbFormState) => {
    if (state.id) {
      onEditSubmit(state.id, state);
    } else {
      onCreateSubmit(state);
    }
  };

  const onDelete = (klubb: Klubb) => {
    setKlubbToDelete(klubb);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteRequest(`${KLUBBER_URI}${klubbToDelete?.id}/`);

    if (response.ok) {
      showSuccessNotification('Klubb slettet');
      mutate(
        KLUBBER_URI,
        klubber.filter(x => x.id !== klubbToDelete?.id),
        false
      );
    } else {
      showErrorNotification('Kunne ikke slette klubb');
    }

    setKlubbToDelete(null);
  };

  const onDeleteCancel = () => {
    setKlubbToDelete(null);
  };

  const onCancel = () => {
    setKlubb(null);
  };

  return (
    <>
      <Page>
        <Header1>Klubber</Header1>

        <div className="flex jc-sb mt-6">
          <Paragraph>
            Her finner du alle klubber som er opprettet i resultatsystemet.
          </Paragraph>

          <Button variant="outlined" onClick={onCreate} startIcon={<AddIcon />}>
            Legg til ny klubb
          </Button>
        </div>

        <section className="mt-10">
          <KlubberTable
            klubber={klubber}
            onRowClick={onEdit}
            onDeleteClick={onDelete}
          />
        </section>
      </Page>

      <Modal open={!!klubb} onClose={onCancel}>
        <ModalHeader>
          <Header1>{klubb?.id ? 'Rediger' : 'Opprett'} klubb</Header1>
        </ModalHeader>
        <ModalBody>
          <KlubbForm
            klubb={klubb}
            onSubmit={onFormSubmit}
            onCancel={onCancel}
          />
        </ModalBody>
      </Modal>

      <ConfirmModal
        open={!!klubbToDelete}
        onConfirm={onDeleteConfirm}
        onClose={onDeleteCancel}
        title="Slett klubb"
        confirmButtonText="Slett"
      >
        Er du sikker på at du vil slette
        <b className="ml-2">{`${klubbToDelete?.navn}`}</b>?
      </ConfirmModal>
    </>
  );
};
