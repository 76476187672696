import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridInitialState,
  GridValueGetterParams,
  gridVisibleSortedRowEntriesSelector,
} from '@mui/x-data-grid';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { DommerStatistics } from 'src/ajax/hooks/useDommere';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { mapKjonnToReadable } from 'src/models/Person';
import { formatFromApiDate } from 'src/utils/date';

const columns: GridColDef<DommerStatistics>[] = [
  {
    field: 'personNvfId',
    headerName: 'NVF-ID',
    width: 110,
    filterable: false,
  },
  {
    field: 'personName',
    headerName: 'Navn',
    width: 250,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.personLastName}, ${params.row.personFirstName}`,
  },
  {
    field: 'personFodselsdato',
    headerName: 'Fødselsdato',
    width: 120,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      formatFromApiDate(params.row.personFodselsdato),
  },
  {
    field: 'personKlubbName',
    headerName: 'Klubb',
    width: 200,
    filterable: false,
  },
  {
    field: 'personKjonn',
    headerName: 'Kjønn',
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      mapKjonnToReadable(params.row.personKjonn),
  },
  {
    field: 'dommergrad',
    headerName: 'Dommergrad',
    width: 110,
    filterable: false,
  },
  {
    field: 'kursdato',
    headerName: 'Kursdato',
    width: 110,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.kursdato ? formatFromApiDate(params.row.kursdato) : '-',
  },
  {
    field: 'stevnerCountLastYear',
    width: 110,
    filterable: false,
    renderHeader: () => <b>{`Stevner ${new Date().getFullYear() - 1}`}</b>,
  },
  {
    field: 'stevnerCountThisYear',
    width: 110,
    filterable: false,
    renderHeader: () => <b>{`Stevner ${new Date().getFullYear()}`}</b>,
  },
];

type DommereTableProps = {
  dommere: DommerStatistics[];
  isLoadingDommere: boolean;
  onRowsChange: (dommere: DommerStatistics[]) => void;
};

export const DommereTable = ({
  dommere,
  isLoadingDommere,
  onRowsChange,
}: DommereTableProps) => {
  const handleStateChange = (state: GridStateCommunity) => {
    const visibleDommere = gridVisibleSortedRowEntriesSelector(state).map(
      x => x.model as DommerStatistics
    );

    onRowsChange(visibleDommere);
  };

  if (dommere.length === 0 && !isLoadingDommere) {
    return <Paragraph className="mt-2">Ingen dommere funnet</Paragraph>;
  }

  const initialState: GridInitialState = {
    sorting: {
      sortModel: [{ field: 'personName', sort: 'asc' }],
    },
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        initialState={initialState}
        autoHeight
        rows={dommere}
        columns={columns}
        onStateChange={handleStateChange}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoadingDommere}
        getRowId={row => row.personNvfId}
      />
    </Box>
  );
};
