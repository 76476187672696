import { Link, useNavigate } from 'react-router-dom';
import logo from 'src/assets/images/nvf-logo.png';
import { Menu } from '../Menu/Menu';
import { Logo, StyledHeaderItem } from './Navigation.styles';
import { NavigationItem } from './Navigation.types';
import { Button } from '@mui/material';
import { Paths } from 'src/Routes';

type Props = {
  items: NavigationItem[];
};

export function PublicHeaderSmall({ items }: Props) {
  const navigate = useNavigate();

  const menuItems = items.map(item => ({
    title: item.name,
    onClick: () => navigate(item.path),
  }));

  return (
    <>
      <StyledHeaderItem>
        <Logo src={logo} alt="Norges Vektløfterforbund logo" />
      </StyledHeaderItem>

      <Menu iconType="menu" items={menuItems} />

      <StyledHeaderItem style={{ marginLeft: 'auto' }}>
        <Link to={Paths.base} style={{ color: 'inherit' }}>
          <Button>Logg inn admin</Button>
        </Link>
      </StyledHeaderItem>
    </>
  );
}
