import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import { Person, mapPersonIdToReadable } from 'src/models/Person';
import { convertDateField } from '../StevnerForm/StevnerForm.schema';
import { StevnerInternasjonalFormState } from './StevnerInternasjonalForm.types';

type StevnerInternasjonalFormResultatProps = {
  index: number;
  personer: Person[];
  removeResultat: (index: number) => void;
};

export function StevnerInternasjonalFormResultat({
  index,
  personer,
  removeResultat,
}: StevnerInternasjonalFormResultatProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<StevnerInternasjonalFormState>();

  const handleRemoveResultat = () => {
    removeResultat(index);
  };

  return (
    <Paper className="flex flexcol gap-4 p-4">
      <div className="flex gap-4">
        <Controller
          name={`resultater.${index}.personId`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              options={personer.map(person => person.id)}
              getOptionLabel={option => mapPersonIdToReadable(option, personer)}
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label="Løfter"
                  error={!!errors.resultater?.[index]?.personId}
                  helperText={errors.resultater?.[index]?.personId?.message}
                />
              )}
              value={value === undefined ? null : value}
              onChange={(_, item) => {
                onChange(item);
              }}
            />
          )}
        />

        <Controller
          name={`resultater.${index}.kroppsvekt`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label="Kroppsvekt"
              fullWidth
              error={!!errors.resultater?.[index]?.kroppsvekt}
              helperText={errors.resultater?.[index]?.kroppsvekt?.message}
              value={
                field.value === undefined || field.value === null
                  ? ''
                  : field.value
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          name={`resultater.${index}.dato`}
          control={control}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label="Dato"
              inputFormat="dd/MM/yyyy"
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.resultater?.[index]?.dato}
                  helperText={errors.resultater?.[index]?.dato?.message}
                  value={field.value}
                />
              )}
              // TODO: Rar oppførsel på fjerning av år
              value={convertDateField(field.value)}
              onChange={item => {
                field.onChange(item);
              }}
            />
          )}
        />
      </div>

      <div>
        <Grid container columns={3} spacing={2}>
          <Grid item xs={1}>
            <Controller
              name={`resultater.${index}.rykk1`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Rykk 1"
                  error={!!errors.resultater?.[index]?.rykk1}
                  helperText={errors.resultater?.[index]?.rykk1?.message}
                  value={
                    field.value === undefined || field.value === null
                      ? ''
                      : field.value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <Controller
              name={`resultater.${index}.rykk2`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Rykk 2"
                  error={!!errors.resultater?.[index]?.rykk2}
                  helperText={errors.resultater?.[index]?.rykk2?.message}
                  value={
                    field.value === undefined || field.value === null
                      ? ''
                      : field.value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`resultater.${index}.rykk3`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Rykk 3"
                  error={!!errors.resultater?.[index]?.rykk3}
                  helperText={errors.resultater?.[index]?.rykk3?.message}
                  value={
                    field.value === undefined || field.value === null
                      ? ''
                      : field.value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`resultater.${index}.stot1`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Støt 1"
                  error={!!errors.resultater?.[index]?.stot1}
                  helperText={errors.resultater?.[index]?.stot1?.message}
                  value={
                    field.value === undefined || field.value === null
                      ? ''
                      : field.value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`resultater.${index}.stot2`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Støt 2"
                  error={!!errors.resultater?.[index]?.stot2}
                  helperText={errors.resultater?.[index]?.stot2?.message}
                  value={
                    field.value === undefined || field.value === null
                      ? ''
                      : field.value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`resultater.${index}.stot3`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Støt 3"
                  error={!!errors.resultater?.[index]?.stot3}
                  helperText={errors.resultater?.[index]?.stot3?.message}
                  value={
                    field.value === undefined || field.value === null
                      ? ''
                      : field.value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Button onClick={handleRemoveResultat} variant="outlined" color="error">
          Fjern resultat
        </Button>
      </div>
    </Paper>
  );
}
