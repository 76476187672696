import { differenceInDays, subMonths } from 'date-fns';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicPaths } from 'src/Routes';
import { usePublicStevner } from 'src/ajax/hooks/usePublicStevner';
import { PublicPage } from 'src/components/shared/Page/PublicPage';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import { PublicStevne } from 'src/models/Stevne';
import { DateFormatApi, formatDate } from 'src/utils/date';
import {
  PublicStevnerSearchForm,
  PublicStevnerSearchFormState,
} from './PublicStevnerSearchForm';
import { PublicStevnerTable } from './PublicStevnerTable';

export function PublicStevnerPage() {
  const today = useMemo(() => new Date(), []);
  
  const todaysYear = today.getFullYear();

  const firstDayOfYear = useMemo(
    () => new Date(`${todaysYear}/01/01`),
    [todaysYear]
  );

  const fromDate = useMemo(
    () =>
      differenceInDays(today, firstDayOfYear) < 30
        ? subMonths(today, 1)
        : firstDayOfYear,
    [firstDayOfYear, today]
  );

  const [fromDateISO, setFromDateISO] = useSessionStorage(
    'filter-stevner-from-date',
    formatDate(fromDate, DateFormatApi)
  );
  const [toDateISO, setToDateISO] = useSessionStorage(
    'filter-stevner-to-date',
    formatDate(today, DateFormatApi)
  );

  const { stevner, isLoadingStevner } = usePublicStevner(
    fromDateISO,
    toDateISO
  );

  const navigate = useNavigate();

  const navigateToPublicStevnePage = (stevne: PublicStevne) => {
    if (stevne.origin === 'INTERNASJONALT') {
      navigate(PublicPaths.stevner.internasjonal.useShow(stevne.id));
    } else {
      navigate(PublicPaths.stevner.useShow(stevne.id));
    }
  };

  const handleSubmit = (searchState: PublicStevnerSearchFormState) => {
    const fromDateISO = formatDate(searchState.fromDate, DateFormatApi);
    const toDateISO = formatDate(searchState.toDate, DateFormatApi);

    setFromDateISO(fromDateISO);
    setToDateISO(toDateISO);
  };

  return (
    <PublicPage>
      <Header1>Stevner</Header1>

      <Paragraph className="align-self-start mt-4 mb-8">
        Her finner du en oversikt over alle stevner som er registrert i
        resultatsystemet.
      </Paragraph>

      <PublicStevnerSearchForm
        fromDateISO={fromDateISO}
        toDateISO={toDateISO}
        onSubmit={handleSubmit}
      />

      <PublicStevnerTable
        stevner={stevner}
        isLoadingStevner={isLoadingStevner}
        onRowClick={navigateToPublicStevnePage}
        className="mt-8"
      />
    </PublicPage>
  );
}
