import { Box } from '@mui/material';
import { HTMLAttributes } from 'react';

type TabPanelProps = {
  index: number;
  value: number;
  children?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export function TabPanel({ children, value, index, ...rest }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && <Box className="h100">{children}</Box>}
    </div>
  );
}
