import { Header3 } from 'src/components/shared/Typography/Header3';
import styled from 'styled-components';

export const CardHeader = styled.div`
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Header3} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardContent = styled.div`
  display: flex;
  padding: 0;
`;

export const CardDetails = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 1rem;
  padding: 1.25rem;
  color: inherit;

  :first-child {
    padding-left: 0;
  }
`;
