import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMountEffect } from 'src/hooks/useMountEffect';
import { KategoriType } from 'src/models/Stevne';
import { Colors } from 'src/utils/colors';
import { RankingSearchFormSchema } from './RankingSearchForm.schema';
import {
  DefaultRankingSearchFormState,
  RankingSearchFormState,
} from './RankingSearchForm.types';
import { getVektklasseOptions } from './RankingSearchForm.utils';

type RankingSearchFormProps = {
  submitDefaultStateOnMount?: boolean;
  onSubmit: (state: RankingSearchFormState) => void;
};

export function RankingSearchForm({
  submitDefaultStateOnMount,
  onSubmit,
}: RankingSearchFormProps) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RankingSearchFormState>({
    resolver: yupResolver(RankingSearchFormSchema),
    defaultValues: DefaultRankingSearchFormState,
  });

  useMountEffect(() => {
    if (submitDefaultStateOnMount) {
      onSubmit(DefaultRankingSearchFormState);
    }
  });

  const selectedKategorier = watch('kategorier');
  const selectedGender = watch('genderOption');
  const selectedVektklasse = watch('vektklasse');

  const isVektklasseDisabled =
    selectedGender === 'ALL' || selectedKategorier.length === 0;

  const vektklasseOptions = useMemo(() => {
    // Nullstiller valgt vektklasse - valget kan være "ugyldig" i forhold til evt. nye valg
    const vektklasseOptions = getVektklasseOptions(
      selectedGender,
      selectedKategorier
    );

    if (selectedVektklasse && !vektklasseOptions.includes(selectedVektklasse)) {
      setValue('vektklasse', null);
    }

    return vektklasseOptions;
  }, [selectedGender, selectedKategorier, selectedVektklasse, setValue]);

  const isKategoriSelected = (kategori: KategoriType) => {
    return selectedKategorier.includes(kategori);
  };

  const onKategorierChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const kategori = event.target.value as KategoriType;

    const newKategorier = checked
      ? [...selectedKategorier, kategori]
      : selectedKategorier.filter(k => k !== kategori);

    setValue('kategorier', newKategorier);
  };

  const handleFormSubmit = (state: RankingSearchFormState) => {
    onSubmit(state);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex align-end gap-4">
        <Box display="flex" alignItems="center" gap="2rem" flexWrap="wrap">
          <Box display="flex" flexDirection="column" gap="1rem" width="16rem">
            <Controller
              name="fromDate"
              control={control}
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  label="Fra dato"
                  inputFormat="dd/MM/yyyy"
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={!!errors.fromDate}
                      helperText={errors.fromDate?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="toDate"
              control={control}
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  label="Til dato"
                  inputFormat="dd/MM/yyyy"
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={!!errors.toDate}
                      helperText={errors.toDate?.message}
                    />
                  )}
                />
              )}
            />
          </Box>

          <FormControl>
            <FormLabel id="gender-label">Kjønn</FormLabel>
            <Controller
              name="genderOption"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} aria-labelledby="gender-label">
                  <FormControlLabel
                    label="Alle"
                    value="ALL"
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    label="Menn"
                    value="M"
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    label="Kvinner"
                    value="K"
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormGroup>
            <FormLabel id="category-label">Kategori</FormLabel>

            <FormControlLabel
              control={
                <Checkbox
                  value={'S'}
                  checked={isKategoriSelected('S')}
                  onChange={onKategorierChange}
                  size="small"
                  className="py-1"
                />
              }
              label="Senior"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={'J'}
                  checked={isKategoriSelected('J')}
                  onChange={onKategorierChange}
                  size="small"
                  className="py-1"
                />
              }
              label="Junior"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={'U'}
                  checked={isKategoriSelected('U')}
                  onChange={onKategorierChange}
                  size="small"
                  className="py-1"
                />
              }
              label="Ungdom"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={'V'}
                  checked={isKategoriSelected('V')}
                  onChange={onKategorierChange}
                  size="small"
                  className="py-1"
                />
              }
              label="Veteran"
            />

            {errors.kategorier && (
              <Typography variant="caption" color={Colors.danger}>
                {errors.kategorier.message}
              </Typography>
            )}
          </FormGroup>

          <div className="align-self-start" style={{ maxWidth: '15rem' }}>
            <FormLabel>Vektklasse</FormLabel>
            <FormHelperText className="mb-3">
              Kun ett kjønn kan være valgt før du velger vektklasse
            </FormHelperText>
            <Controller
              name="vektklasse"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={vektklasseOptions}
                  renderInput={params => (
                    <TextField {...params} label="Velg vektklasse" />
                  )}
                  disabled={isVektklasseDisabled}
                  value={value}
                  onChange={(_, item) => {
                    onChange(item);
                  }}
                />
              )}
            />
          </div>

          <div className="align-self-start" style={{ maxWidth: '12rem' }}>
            <FormLabel>Kun beste resultat</FormLabel>
            <FormHelperText className="mb-3">
              Ta bare med det beste resultatet for hver person
            </FormHelperText>
            <Controller
              control={control}
              name="onlyBestResultatForPerson"
              defaultValue={false}
              render={({ field }) => {
                return <Switch {...field} />;
              }}
            />
          </div>
        </Box>
      </div>

      <Button variant="contained" type="submit" className="mt-2">
        Søk etter resultater
      </Button>
    </form>
  );
}
