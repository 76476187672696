import { PublicStevneDetail, StevneDetail, StevneUpload } from 'src/models/Stevne';
import { formatFromApiDate, formatDatePeriod } from './date';

export function findStevnePeriodFromPublicStevne(
  stevne: PublicStevneDetail
): string {
  const puljer = stevne.puljer;

  if (puljer.length === 1) {
    return formatFromApiDate(puljer[0].dato);
  }

  const firstDate = new Date(puljer[0].dato);
  const lastDate = new Date(puljer.at(-1)?.dato as string);

  return formatDatePeriod(firstDate, lastDate);
}

export function findStevnePeriodFromStevne(
  stevne: StevneDetail
): string {
  const puljer = stevne.puljeList;

  if (puljer.length === 1) {
    return formatFromApiDate(puljer[0].dato);
  }

  const firstDate = new Date(puljer[0].dato);
  const lastDate = new Date(puljer.at(-1)?.dato as string);

  return formatDatePeriod(firstDate, lastDate);
}

export function findStevnePeriodFromStevneUpload(
  stevne: StevneUpload
): string | null {
  const puljer = stevne.puljeList;

  if (puljer.length === 1) {
    if (puljer[0].dato) {
      return formatFromApiDate(puljer[0].dato);
    }

    return null;
  }

  const firstDateString = puljer[0].dato;
  const lastDateString = puljer.at(-1)?.dato as string;

  if (firstDateString && lastDateString) {
    const firstDate = new Date(firstDateString);
    const lastDate = new Date(lastDateString);

    return formatDatePeriod(firstDate, lastDate);
  }

  return null;
}
