import ReactDOM from 'react-dom';
import { App } from './App';
import './assets/styles/fonts.scss';
import './assets/styles/font-faces.scss';
import './assets/styles/margin.scss';
import './assets/styles/flex.scss';
import './assets/styles/gap.scss';
import './assets/styles/padding.scss';

ReactDOM.render(<App />, document.getElementById('root'));
