import { AxiosResponse } from 'axios';

function getFileNameFromContentDispositionHeader(header: string): string {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return '';
}

function getFileNameFromResponse(response: AxiosResponse): string {
  const contentDisposition = response.headers['content-disposition'];
  return getFileNameFromContentDispositionHeader(contentDisposition);
}

export function getBlobFromFileResponse(response: AxiosResponse) {
  const contentType = response.headers['content-type'];
  return new Blob([response.data], {
    type: contentType,
  });
}

export function downloadFileFromResponse(response: AxiosResponse) {
  const fileName = getFileNameFromResponse(response);
  const blob = getBlobFromFileResponse(response);

  const url = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
