import { RekordInput, RekordResultatInput } from 'src/models/Rekord';
import { publicGetRequest, postRequest } from './ajax';

export const RekorderApi = {
  createRekord: (input: RekordInput) => {
    return postRequest<number>('/rekorder/', input);
  },
  createRekordFromResultat: (input: RekordResultatInput) => {
    return postRequest<number>('/rekorder/resultat/', input);
  },
  createRekordBatch: (inputs: RekordInput[]) => {
    return postRequest<number[]>('/rekorder/batch/', inputs);
  },
  downloadPublicRekorder: () => {
    return publicGetRequest('/public/rekorder/report/', {
      responseType: 'blob',
    });
  },
};
