import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import {
  DommerStatistics,
  useDommerStatistics,
  useDommere,
} from 'src/ajax/hooks/useDommere';
import { ReportsApi } from 'src/ajax/reports.api';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { useAuth } from 'src/context/AuthContext';
import { isKlubbAdmin, isNvfAdmin } from 'src/models/User';
import { downloadFileFromResponse } from 'src/utils/file.utils';
import { DommereTable } from './DommereTable';
import { DommerStevnerModal } from './DommerStevnerModal';

export function DommerePage() {
  const { user } = useAuth();
  const [dommerStevnerIsOpen, setDommerStevnerIsOpen] = useState(false);

  const { dommerStatistics, isLoading } = useDommerStatistics(
    isKlubbAdmin(user) ? user.klubb.id : undefined
  );
  const [visibleDommere, setVisibleDommere] = useState<DommerStatistics[]>([]);

  const { dommere } = useDommere();

  const openDommerStevner = () => {
    setDommerStevnerIsOpen(true);
  };

  const closeDommerStevner = () => {
    setDommerStevnerIsOpen(false);
  };

  const handleDownloadDommere = async () => {
    const response = await ReportsApi.downloadDommere(visibleDommere);

    if (response.ok) {
      downloadFileFromResponse(response.axiosResponse);
    }
  };

  return (
    <section>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="mb-4"
      >
        {isNvfAdmin(user) && <Header3>Alle dommere</Header3>}
        {isKlubbAdmin(user) && <Header3>Dommere i {user.klubb.navn}</Header3>}

        <div className="flex gap-8">
          {isNvfAdmin(user) && (
            <Button variant="outlined" onClick={openDommerStevner}>
              Hent stevner for dommer
            </Button>
          )}

          <Button
            variant="outlined"
            onClick={handleDownloadDommere}
            endIcon={<DownloadIcon />}
          >
            Last ned
          </Button>
        </div>
      </Box>

      <DommereTable
        dommere={dommerStatistics}
        isLoadingDommere={isLoading}
        onRowsChange={setVisibleDommere}
      />

      <DommerStevnerModal
        isOpen={dommerStevnerIsOpen}
        dommere={dommere}
        onClose={closeDommerStevner}
      />
    </section>
  );
}
