import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { Loader } from 'src/components/shared/Loader/Loader';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useSort } from 'src/hooks/useSort';
import { PublicStevne } from 'src/models/Stevne';
import { formatDate } from 'src/utils/date';
import { SortDirection, sortByProperty } from 'src/utils/sort';

type PublicStevnerTableProps = {
  stevner: PublicStevne[];
  isLoadingStevner: boolean;
  onRowClick: (stevne: PublicStevne) => void;
  className?: string;
};

export const PublicStevnerTable = ({
  stevner,
  isLoadingStevner,
  onRowClick,
  className,
}: PublicStevnerTableProps) => {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<PublicStevne>('startDato', SortDirection.Descending);

  const sortedStevner = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(stevner, sortedBy, sortDirection);
    }

    return stevner;
  }, [stevner, sortedBy, sortDirection]);

  if (isLoadingStevner) {
    return <Loader active={true} className={className} />;
  }

  if (stevner.length === 0 && !isLoadingStevner) {
    return (
      <Paragraph
        className={className}
        style={{ fontStyle: 'italic', color: '#494949' }}
      >
        Ingen stevner å vise fram
      </Paragraph>
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: 1000 }}
      className={className}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader width={250}>
              <TableSortLabel
                active={isActive('stevnetype')}
                direction={getDirection('stevnetype')}
                onClick={() => requestSort('stevnetype')}
              >
                Stevnetype
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader width={130}>
              <TableSortLabel
                active={isActive('startDato')}
                direction={getDirection('startDato')}
                onClick={() => requestSort('startDato')}
              >
                Dato
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader width={200}>
              <TableSortLabel
                active={isActive('klubbName')}
                direction={getDirection('klubbName')}
                onClick={() => requestSort('klubbName')}
              >
                Klubb
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('sted')}
                direction={getDirection('sted')}
                onClick={() => requestSort('sted')}
              >
                Lokasjon
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingStevner ? (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                <Loader active size={40} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedStevner.map(stevne => (
                <TableRow
                  key={`${stevne.origin}-${stevne.id}`}
                  onClick={() => onRowClick(stevne)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{stevne.stevnetype}</TableCell>
                  <TableCell>
                    {stevne.startDato ? formatDate(stevne.startDato) : '–'}
                  </TableCell>
                  <TableCell>{stevne.klubbName}</TableCell>
                  <TableCell>{stevne.sted}</TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
