import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { Loader } from 'src/components/shared/Loader/Loader';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useSort } from 'src/hooks/useSort';
import { PublicRankingResultat } from 'src/models/Stevne';
import { formatDate } from 'src/utils/date';
import { SortDirection, sortByProperty } from 'src/utils/sort';
import { showNullableNumber } from 'src/utils/table';

type PublicRankingTableProps = {
  resultater: PublicRankingResultat[];
  isLoading: boolean;
};

export const PublicRankingTable = ({
  resultater,
  isLoading,
}: PublicRankingTableProps) => {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<PublicRankingResultat>('poeng', SortDirection.Descending);

  const sortedResultater = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(resultater, sortedBy, sortDirection);
    }

    return resultater;
  }, [resultater, sortedBy, sortDirection]);

  if (isLoading) {
    return <Loader active={true} />;
  }

  if (resultater.length === 0 && !isLoading) {
    return (
      <Paragraph
        className="mt-2"
        style={{ fontStyle: 'italic', color: '#494949' }}
      >
        Ingen resultater å vise fram
      </Paragraph>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('navn')}
                direction={getDirection('navn')}
                onClick={() => requestSort('navn')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader width={200}>
              <TableSortLabel
                active={isActive('klubbName')}
                direction={getDirection('klubbName')}
                onClick={() => requestSort('klubbName')}
              >
                Klubb
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader width={100}>
              <TableSortLabel
                active={isActive('kategori')}
                direction={getDirection('kategori')}
                onClick={() => requestSort('kategori')}
              >
                Kategori
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader width={100}>
              <TableSortLabel
                active={isActive('vektklasse')}
                direction={getDirection('vektklasse')}
                onClick={() => requestSort('vektklasse')}
              >
                Vektklasse
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('stevnetype')}
                direction={getDirection('stevnetype')}
                onClick={() => requestSort('stevnetype')}
              >
                Stevnetype
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('stevnested')}
                direction={getDirection('stevnested')}
                onClick={() => requestSort('stevnested')}
              >
                Stevnested
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('dato')}
                direction={getDirection('dato')}
                onClick={() => requestSort('dato')}
              >
                Dato
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('besteRykk')}
                direction={getDirection('besteRykk')}
                onClick={() => requestSort('besteRykk')}
              >
                Rykk
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('besteStot')}
                direction={getDirection('besteStot')}
                onClick={() => requestSort('besteStot')}
              >
                Støt
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('total')}
                direction={getDirection('total')}
                onClick={() => requestSort('total')}
              >
                Total
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('poeng')}
                direction={getDirection('poeng')}
                onClick={() => requestSort('poeng')}
              >
                Poeng
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('veteranpoeng')}
                direction={getDirection('veteranpoeng')}
                onClick={() => requestSort('veteranpoeng')}
              >
                Veteranpoeng
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                <Loader active size={40} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedResultater.map(resultat => (
                <TableRow key={`${resultat.navn}-${resultat.dato}`}>
                  <TableCell>{resultat.navn}</TableCell>
                  <TableCell>{resultat.klubbName}</TableCell>
                  <TableCell>{resultat.kategori}</TableCell>
                  <TableCell>{resultat.vektklasse}</TableCell>
                  <TableCell>{resultat.stevnetype}</TableCell>
                  <TableCell>{resultat.stevnested}</TableCell>
                  <TableCell>{formatDate(resultat.dato)}</TableCell>
                  <TableCell>
                    {showNullableNumber(resultat.besteRykk)}
                  </TableCell>
                  <TableCell>
                    {showNullableNumber(resultat.besteStot)}
                  </TableCell>
                  <TableCell>{showNullableNumber(resultat.total)}</TableCell>
                  <TableCell>{showNullableNumber(resultat.poeng)}</TableCell>
                  <TableCell>
                    {showNullableNumber(resultat.veteranpoeng)}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
