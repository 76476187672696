import { SWRConfig } from 'swr';
import { ApiError, fetcher } from './ajax/ajax';

type SwrProps = {
  children: React.ReactNode;
};

export const Swr = ({ children }: SwrProps) => {
  return (
    <SWRConfig
      value={{
        fetcher: fetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        onError: (error: ApiError) => {
          if (error.status === 500) {
            console.log(error);
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
