import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { VektklasseRekord } from 'src/models/Rekord';
import { RekorderTableRowCellLoeft } from './RekorderTableRowCellLoeft';
import { RekorderTableRowDetail } from './RekorderTableRowDetail';

export const MISSING_VALUE = '-';

type RekorderTableRowProps = {
  rekord: VektklasseRekord;
  initialOpen: boolean;
};

export function RekorderTableRow({ rekord, initialOpen }: RekorderTableRowProps) {
  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    setOpen(initialOpen);
  }, [rekord, initialOpen]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{rekord.vektklasseNavn}</TableCell>
        <RekorderTableRowCellLoeft rekordLoeft={rekord.rykk} />
        <RekorderTableRowCellLoeft rekordLoeft={rekord.stot} />
        <RekorderTableRowCellLoeft rekordLoeft={rekord.sammenlagt} />
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RekorderTableRowDetail rekord={rekord} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
