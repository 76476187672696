import { List, ListSubheader, ListItem, ListItemText } from '@mui/material';
import { Header3 } from 'src/components/shared/Typography/Header3';

type StevnerFormWarningModalListProps = {
  title: string;
  subtitle?: string;
  messages: string[];
};

export function StevnerFormWarningModalList({
  title,
  subtitle,
  messages,
}: StevnerFormWarningModalListProps) {
  return (
    <section>
      <Header3 bold>{title}</Header3>

      <List
        dense
        subheader={
          subtitle && (
            <ListSubheader disableGutters className="pl-1">
              {subtitle}
            </ListSubheader>
          )
        }
      >
        {messages.map((message, index) => (
          <ListItem key={index}>
            <ListItemText primary={message} />
          </ListItem>
        ))}
      </List>
    </section>
  );
}
