import styled from 'styled-components';
import { Loader } from './Loader';

const LoaderContainer = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
`;

export const PageLoader = () => {
  return (
    <LoaderContainer>
      <Loader active size={60}></Loader>
      <h3 className="mt-4">Laster...</h3>
    </LoaderContainer>
  );
};
