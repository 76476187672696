export const Colors = {
  primary: '#1a2639',
  primaryRGB: '26, 38, 57',
  secondary: '#3e4a61',
  secondaryRGB: '62, 74, 97',
  tertiary: '#c24d2c',
  danger: '#d32f2f',
  tertiaryRGB: '194, 77, 44',
  quaternary: '#d9dad7',
  quaternaryRGB: '194, 77, 44',
};
