import { RekordResultatInput } from 'src/models/Rekord';
import { RekorderResultatFormState } from './RekorderResultatForm.types';

export function mapRekorderResultatFormStateToRekordResultatInput(
  state: RekorderResultatFormState
): RekordResultatInput {
  return {
    nasjonaltResultatId: state.nasjonaltResultatId,
    internasjonaltResultatId: state.internasjonaltResultatId,
    ovelse: state.ovelse!!,
  };
}
