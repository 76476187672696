import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';

const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 32rem;
`;

export const validationSchema = Yup.object().shape({
  username: Yup.string().required('Brukernavn er påkrevd'),
  password: Yup.string().required('Passord er påkrevd'),
});

export type LoginState = {
  username: string;
  password: string;
};

type LoginFormProps = {
  onSubmit: (state: LoginState) => void;
  loginError: Nullable<string>;
};

export function LoginForm({ onSubmit, loginError }: LoginFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginState>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  function onFormSubmit(state: LoginState) {
    onSubmit(state);
  }

  return (
    <StyledLoginForm onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        name="username"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Brukernavn"
            error={!!errors.username}
            helperText={errors.username?.message}
            className="mb-6"
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Passord"
            type="password"
            error={!!errors.password || !!loginError}
            helperText={errors.password?.message || loginError}
            className="mb-6"
          />
        )}
      />

      <Button variant="contained" type="submit">
        Logg inn
      </Button>
    </StyledLoginForm>
  );
}
