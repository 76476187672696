import React from 'react';
import styled from 'styled-components';
import { Loader } from '../Loader/Loader';

const StyledPage = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 4rem);
  margin-top: 4rem;
  margin-left: 10rem;
  padding: 1.5rem;
`;

export type PageProps = {
  isLoading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

export function Page({ isLoading, className, style, children }: PageProps) {
  if (isLoading) {
    return (
      <StyledPage className="jc-center align-center">
        <Loader active />
      </StyledPage>
    );
  }

  return (
    <StyledPage className={className} style={style}>
      {children}
    </StyledPage>
  );
}
