import { Klubb } from 'src/models/Klubb';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export const KLUBBER_URI = '/klubb/';

export function useKlubber() {
  const { data, error } = useSWR<Klubb[], ApiError>(KLUBBER_URI);

  return {
    klubber: data || [],
    isLoadingKlubber: !data && !error,
    error: error,
  };
}
