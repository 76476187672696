import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ResultatUpload } from 'src/models/Stevne';
import { formatDate } from 'src/utils/date';
import { ForsoekCell } from '../Stevne/ResultatList';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';

type ResultatUploadListProps = {
  resultater: ResultatUpload[];
};

export function ResultatList({ resultater }: ResultatUploadListProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>Navn</TableCellHeader>
            <TableCellHeader>Vektklasse</TableCellHeader>
            <TableCellHeader>Kroppsvekt</TableCellHeader>
            <TableCellHeader>Fødselsdato</TableCellHeader>
            <TableCellHeader>Kategori</TableCellHeader>
            <TableCellHeader>Startnr.</TableCellHeader>
            <TableCellHeader>Rykk 1</TableCellHeader>
            <TableCellHeader>Rykk 2</TableCellHeader>
            <TableCellHeader>Rykk 3</TableCellHeader>
            <TableCellHeader>Støt 1</TableCellHeader>
            <TableCellHeader>Støt 2</TableCellHeader>
            <TableCellHeader>Støt 3</TableCellHeader>
            <TableCellHeader>Poeng</TableCellHeader>
            <TableCellHeader>Veteranpoeng</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {resultater.map((resultat, index) => (
            <TableRow key={index}>
              <TableCell>
                {resultat.person?.fornavn} {resultat.person?.etternavn}
              </TableCell>
              <TableCell>{resultat.vektklasse?.navn}</TableCell>
              <TableCell>{resultat.kroppsvekt} kg</TableCell>
              <TableCell>
                {resultat.person?.fodselsdato
                  ? formatDate(resultat.person?.fodselsdato)
                  : '-'}
              </TableCell>
              <TableCell>{resultat.kategori?.forkortelse}</TableCell>
              <TableCell>{resultat.startnummer}</TableCell>
              <ForsoekCell weight={resultat.rykk1} />
              <ForsoekCell weight={resultat.rykk2} />
              <ForsoekCell weight={resultat.rykk3} />
              <ForsoekCell weight={resultat.stot1} />
              <ForsoekCell weight={resultat.stot2} />
              <ForsoekCell weight={resultat.stot3} />
              <TableCell>{resultat.poeng?.toFixed(2) || '-'}</TableCell>
              <TableCell>{resultat.veteranpoeng?.toFixed(2) || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
