import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Person } from 'src/models/Person';
import { convertDateField } from '../StevnerForm/StevnerForm.schema';
import {
  internasjonalStevneDefaultValues,
  internasjonalStevneSchema,
} from './StevnerInternasjonalForm.schema';
import { StevnerInternasjonalFormState } from './StevnerInternasjonalForm.types';
import { StevnerInternasjonalFormResultater } from './StevnerInternasjonalFormResultater';

type StevnerInternasjonalFormProps = {
  personer: Person[];
  submitLabel: string;
  defaultValues?: StevnerInternasjonalFormState;
  onSubmit: (state: StevnerInternasjonalFormState) => void;
};

export function StevnerInternasjonalForm({
  personer,
  submitLabel,
  defaultValues,
  onSubmit,
}: StevnerInternasjonalFormProps) {
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(internasjonalStevneSchema),
    defaultValues: defaultValues || internasjonalStevneDefaultValues,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex jc-end">
          <Button type="submit" variant="contained">
            {submitLabel}
          </Button>
        </div>

        <Grid container columns={3} spacing={2} className="mt-4">
          <Grid item xs={3} sm={1} className="flex flexcol gap-8">
            <Controller
              name="navn"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Navn på stevne"
                  error={!!errors.navn}
                  helperText={errors.navn?.message}
                  value={field.value}
                />
              )}
            />

            <Controller
              name="sted"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sted"
                  error={!!errors.sted}
                  helperText={errors.sted?.message}
                  value={field.value}
                />
              )}
            />

            <Controller
              name="startdato"
              control={control}
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  label="Startdato"
                  inputFormat="dd/MM/yyyy"
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.startdato}
                      helperText={errors.startdato?.message}
                      value={field.value}
                    />
                  )}
                  // TODO: Rar oppførsel på fjerning av år
                  value={convertDateField(field.value)}
                  onChange={item => {
                    field.onChange(item);
                  }}
                />
              )}
            />

            <Controller
              name="sluttdato"
              control={control}
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  label="Sluttdato"
                  inputFormat="dd/MM/yyyy"
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.sluttdato}
                      helperText={errors.sluttdato?.message}
                      value={field.value}
                    />
                  )}
                  value={convertDateField(field.value)}
                  onChange={item => {
                    field.onChange(item);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={3} sm={2}>
            <StevnerInternasjonalFormResultater personer={personer} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
