import { Modal as MuiModal } from '@mui/material';
import React, { useRef, useState } from 'react';
import { CloseButton, ModalWrapper } from './Modal.styles';
import CloseIcon from '@mui/icons-material/Close';

type ModalProps = {
  open: boolean;
  allowCommonCloseOptions?: boolean;
  width?: number;
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
};

export const Modal = ({
  width = 620,
  open = false,
  allowCommonCloseOptions = true,
  onClose,
  children,
}: ModalProps) => {
  const [wiggle, setWiggle] = useState<boolean>(false);

  const modal = useRef<HTMLDivElement>(null);

  const handleOnClose = () => {
    if (allowCommonCloseOptions) {
      onClose();
    } else {
      onWiggle();
    }
  };

  const onWiggle = () => {
    setWiggle(true);
    setTimeout(() => setWiggle(false), 400);
  };

  return (
    <MuiModal open={open} className="flex" onClose={handleOnClose}>
      <ModalWrapper
        ref={modal}
        className={wiggle ? 'wiggle' : ''}
        style={{
          maxWidth: width,
        }}
      >
        <CloseButton size="large" onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        {children}
      </ModalWrapper>
    </MuiModal>
  );
};
