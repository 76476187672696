import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { Loader } from 'src/components/shared/Loader/Loader';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { useSort } from 'src/hooks/useSort';
import { InternasjonaltStevneList } from 'src/models/InternasjonaltStevne';
import { formatDatePeriod } from 'src/utils/date';
import { SortDirection, sortByProperty } from 'src/utils/sort';

type StevnerInternasjonalTableProps = {
  internasjonaleStevner: InternasjonaltStevneList[];
  isLoading: boolean;
  onRowClick: (id: number) => void;
};

export function StevnerInternasjonalTable({
  internasjonaleStevner,
  isLoading,
  onRowClick,
}: StevnerInternasjonalTableProps) {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<InternasjonaltStevneList>('startdato', SortDirection.Descending);

  const sortedInternasjonaleStevner = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(internasjonaleStevner, sortedBy, sortDirection);
    }

    return internasjonaleStevner;
  }, [internasjonaleStevner, sortedBy, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('navn')}
                direction={getDirection('navn')}
                onClick={() => requestSort('navn')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('sted')}
                direction={getDirection('sted')}
                onClick={() => requestSort('sted')}
              >
                Sted
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('startdato')}
                direction={getDirection('startdato')}
                onClick={() => requestSort('startdato')}
              >
                Dato
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                <Loader active size={40} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedInternasjonaleStevner.map(stevne => (
                <TableRow
                  key={stevne.id}
                  onClick={() => onRowClick(stevne.id)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{stevne.navn}</TableCell>
                  <TableCell>{stevne.sted}</TableCell>
                  <TableCell>
                    {formatDatePeriod(
                      new Date(stevne.startdato),
                      new Date(stevne.sluttdato)
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
