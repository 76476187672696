import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    line-height: 1;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-size: 16px;
    color: #1a2639;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;

    --primary-color: #1a2639;
    --primary-color-rgb: 26, 38, 57;
    --secondary-color: #3e4a61;
    --secondary-color-rgb: 62, 74, 97;
    --tertiary-color: #c24d2c;
    --tertiary-color-rgb: 194, 77, 44;
    --quaternary-color: #d9dad7;
    --quaternary-color-rgb: 194, 77, 44;

    --small-screen: 768px;
    --medium-screen: 1024px;
    --large-screen: 1280px;
    --xlarge-screen: 1920px;
  }
`;
