import { TextField } from '@mui/material';
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type FormInputProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  placeholder?: string;
  type?: 'string' | 'number';
  disabled?: boolean;
};

export function FormInput<T extends FieldValues>({
  name,
  control,
  defaultValue,
  label,
  placeholder,
  type = 'string',
  disabled,
  ...rest
}: FormInputProps<T>) {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    ...rest,
  });

  const error = fieldState.error?.message;

  const { ref, ...fieldRest } = field;

  const value = field.value || '';

  return (
    <Controller
      {...fieldRest}
      control={control}
      render={({ field: renderField }) => (
        <TextField
          {...renderField}
          ref={ref}
          fullWidth
          label={label}
          value={value}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
}
