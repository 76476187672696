import { Stevne } from 'src/models/Stevne';
import { getRequest } from './ajax';
import { formatToApiDate } from 'src/utils/date';

export const DommerApi = {
  getDommerStevner: (personId: number, fromDate: Date, toDate: Date) => {
    return getRequest<Stevne[]>(
      `/dommere/${personId}/stevner?from_date=${formatToApiDate(
        fromDate
      )}&to_date=${formatToApiDate(toDate)}`
    );
  },
};
