import { Button } from '@mui/material';
import { Loader } from '../Loader/Loader';

type Props = {
  label: string;
  loading?: boolean;
};

export function FormButton({ label, loading }: Props) {
  return (
    <Button variant="contained" type="submit" disabled={loading}>
      {loading ? <Loader size={25} active={true} /> : label}
    </Button>
  );
}
