import { Klubb } from 'src/models/Klubb';

export type KlubbFormState = {
  id?: number;
  navn: Nullable<string>;
  fulltNavn: Nullable<string>;
  forkortelse: Nullable<string>;
};

export function mapKlubbToKlubbFormState(klubb: Klubb): KlubbFormState {
  return {
    id: klubb.id,
    navn: klubb.navn ?? '',
    fulltNavn: klubb.fulltNavn ?? '',
    forkortelse: klubb.forkortelse ?? '',
  };
}
