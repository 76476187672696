import { PersonFormState } from 'src/components/pages/Personer/PersonerForm.schema';
import { Person } from 'src/models/Person';
import { deleteRequest, postRequest, putRequest } from './ajax';

export const PersonerApi = {
  createPerson: (state: PersonFormState) => {
    return postRequest<Person>('/person/', state);
  },
  updatePerson: (nvfId: string, data: PersonFormState) => {
    return putRequest<Person>(`/person/${nvfId}/`, data);
  },
  deletePerson: (nvfId: string) => {
    return deleteRequest(`/person/${nvfId}/`);
  },
  createNewNvfId: (nvfId: string) => {
    return postRequest(`/person/${nvfId}/nvf-id/`);
  }
};
