import {
  KategoriType,
  vektklasserKvinner,
  vektklasserMenn,
  vektklasserUngdomKvinner,
  vektklasserUngdomMenn,
} from 'src/models/Stevne';
import { GenderOption } from './RankingSearchForm.types';

export function getVektklasseOptions(
  genderOption: GenderOption,
  kategorier: KategoriType[]
): ReadonlyArray<string> {
  if (genderOption === 'K' && kategorier.length > 0) {
    if (kategorier.length === 1 && kategorier[0] === 'U') {
      return vektklasserUngdomKvinner;
    }

    return vektklasserKvinner;
  }

  if (genderOption === 'M' && kategorier.length > 0) {
    if (kategorier.length === 1 && kategorier[0] === 'U') {
      return vektklasserUngdomMenn;
    }

    return vektklasserMenn;
  }

  return [];
}
