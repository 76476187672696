import { useCallback, useState } from 'react';

export function useRemainingPageHeight<T extends HTMLElement>() {
  const [height, setHeight] = useState<number>();

  const onRefChange = useCallback((node: T) => {
    const { innerHeight } = window;
    const offsetToBrowserTop = node?.getBoundingClientRect().top;

    const height = offsetToBrowserTop
      ? innerHeight - offsetToBrowserTop
      : undefined;

    setHeight(height);
  }, []);

  return {
    ref: onRefChange,
    height: height,
  };
}
