import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  clearTokens,
  getAccessToken,
  refreshToken,
  setAccessToken,
} from '../utils/auth';
import { Paths } from 'src/Routes';

export class ApiError extends Error {
  info: any;
  status?: number;
  response?: AxiosResponse;

  constructor(error: AxiosError) {
    super(error.message);
    this.status = error.response?.status;
    this.response = error.response;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export type ApiSuccessResponse<T> = {
  ok: true;
  axiosResponse: AxiosResponse;
  data: T;
};

export type ApiErrorResponse = {
  ok: false;
  axiosResponse: AxiosResponse;
  error: AxiosError;
};

export type ApiResponse<T = unknown> = ApiSuccessResponse<T> | ApiErrorResponse;

const acceptJsonHeader = {
  Accept: 'application/json',
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  async config => {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (window.location.pathname === Paths.innlogging) {
      return clearTokens();
    }
    
    const status = error?.response?.status;

    const isRefreshTokenRequest = error.config?.url === '/token/refresh/';

    if (isRefreshTokenRequest) {
      clearTokens();

      if (window.location.pathname !== Paths.innlogging) {
        window.location.href = Paths.innlogging;
      }
    }

    const isCurrentUserRequest = error.config?.url === '/users/me/';

    if (status === 401 && !isCurrentUserRequest) {
      const response = await refreshToken();
      if (response.ok) {
        setAccessToken(response.data.access);
        return axios.request(error.config);
      } else {
        clearTokens();

        if (window.location.pathname !== Paths.innlogging) {
          window.location.href = Paths.innlogging;
        }
      }
    }

    throw error;
  }
);

export const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

function isAxiosError(error: unknown): error is AxiosError {
  return !!error && (error as AxiosError).isAxiosError;
}

async function tryAjax<T>(
  func: () => AxiosPromise<T>
): Promise<ApiResponse<T>> {
  try {
    const response = await func();
    return {
      ok: true,
      data: response.data,
      axiosResponse: response,
    };
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      return {
        ok: false,
        axiosResponse: error.response as AxiosResponse,
        error: error,
      };
    }

    return {
      ok: false,
      axiosResponse: {} as AxiosResponse,
      error: {} as AxiosError,
    };
  }
}

export async function fetcher(url: string) {
  return axios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw new ApiError(error);
    });
}

export const publicGetRequest = <T = unknown>(url: string, config?: AxiosRequestConfig) => {
  return tryAjax<T>(() =>
    publicAxios({
      method: 'GET',
      url: url,
      headers: {
        ...acceptJsonHeader,
      },
      ...(config || {}),
    })
  );
}

export const getRequest = <T = unknown>(url: string, config?: AxiosRequestConfig) => {
  return tryAjax<T>(() =>
    axios({
      method: 'GET',
      url: url,
      headers: {
        ...acceptJsonHeader,
      },
      ...(config || {}),
    })
  );
}

export const postRequest = <T = unknown>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
) => {
  const headers = {
    ...acceptJsonHeader,
  };

  return tryAjax<T>(() =>
    axios({
      method: 'POST',
      url: url,
      headers: headers,
      data: data,
      ...(config || {}),
    })
  );
};

export const publicPostRequest = <T = unknown>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
) => {
  const headers = {
    ...acceptJsonHeader,
  };

  return tryAjax<T>(() =>
    publicAxios({
      method: 'POST',
      url: url,
      headers: headers,
      data: data,
      ...(config || {}),
    })
  );
};

export const putRequest = <T = unknown>(url: string, data: unknown) => {
  const headers = {
    ...acceptJsonHeader,
  };

  return tryAjax<T>(() =>
    axios({
      method: 'PUT',
      url: url,
      headers: headers,
      data: data,
    })
  );
};

export const deleteRequest = (url: string) => {
  return tryAjax<void>(() =>
    axios({
      method: 'DELETE',
      url: url,
    })
  );
};
