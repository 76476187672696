import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { useState } from 'react';
import { RekorderApi } from 'src/ajax/rekorder.api';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { RekordList } from 'src/models/Rekord';
import {
  AlderskategoriToReadable,
  VeteranAlderskategori,
  vektklasserKvinner,
  vektklasserMenn,
  vektklasserUngdomKvinner,
  vektklasserUngdomMenn,
  veteranAlderskategorierKvinner,
  veteranAlderskategorierMenn,
} from 'src/models/Stevne';
import { downloadFileFromResponse } from 'src/utils/file.utils';
import styled from 'styled-components';
import { device } from '../Page/PublicPage';
import { RekorderTable } from './RekorderTable';
import { RekorderVeteran } from './RekorderVeteran';
import { mapRekorderToVektklasseRekorder } from './rekord.mappers';

type RekorderOverviewProps = {
  rekorder: RekordList[];
};

export function RekorderOverview({ rekorder }: RekorderOverviewProps) {
  const [tab, setTab] = useState(RekorderOverviewTab.UngdomKvinner);
  const [showVeteranKategoriSelect, setShowVeteranKategoriSelect] =
    useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [isLoadingExcelDownload, setIsLoadingExcelDownload] = useState(false);

  const [selectedVeteranKategori, setSelectedVeteranKategori] =
    useState<VeteranAlderskategori>(veteranAlderskategorierKvinner[0]);
  const [veteranKategorier, setVeteranKategorier] = useState<
    VeteranAlderskategori[]
  >(Array.from(veteranAlderskategorierKvinner));

  const handleTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);

    if (tab === RekorderOverviewTab.VeteranKvinner) {
      setVeteranKategorier(Array.from(veteranAlderskategorierKvinner));
      setSelectedVeteranKategori(veteranAlderskategorierKvinner[0]);
      setShowVeteranKategoriSelect(true);
    } else if (tab === RekorderOverviewTab.VeteranMenn) {
      setVeteranKategorier(Array.from(veteranAlderskategorierMenn));
      setSelectedVeteranKategori(veteranAlderskategorierMenn[0]);
      setShowVeteranKategoriSelect(true);
    } else {
      setShowVeteranKategoriSelect(false);
    }
  };

  const handleDownloadRekorder = async () => {
    setIsLoadingExcelDownload(true);
    const response = await RekorderApi.downloadPublicRekorder();

    if (response.ok) {
      downloadFileFromResponse(response.axiosResponse);
    }
    setIsLoadingExcelDownload(false);
  };

  const ungdomKvinnerVektklasseRekorder = mapRekorderToVektklasseRekorder(
    'UK',
    Array.from(vektklasserUngdomKvinner),
    rekorder
  );
  const ungdomMennVektklasseRekorder = mapRekorderToVektklasseRekorder(
    'UM',
    Array.from(vektklasserUngdomMenn),
    rekorder
  );

  const juniorKvinnerVektklasseRekorder = mapRekorderToVektklasseRekorder(
    'JK',
    Array.from(vektklasserKvinner),
    rekorder
  );
  const juniorMennVektklasseRekorder = mapRekorderToVektklasseRekorder(
    'JM',
    Array.from(vektklasserMenn),
    rekorder
  );

  const seniorKvinnerVektklasseRekorder = mapRekorderToVektklasseRekorder(
    'SK',
    Array.from(vektklasserKvinner),
    rekorder
  );
  const seniorMennVektklasseRekorder = mapRekorderToVektklasseRekorder(
    'SM',
    Array.from(vektklasserMenn),
    rekorder
  );

  const veteranKvinnerVektklasseRekorder = Array.from(
    veteranAlderskategorierKvinner
  ).flatMap(kategori =>
    mapRekorderToVektklasseRekorder(
      kategori,
      Array.from(vektklasserKvinner),
      rekorder
    )
  );
  const veteranMennVektklasseRekorder = Array.from(
    veteranAlderskategorierMenn
  ).flatMap(kategori =>
    mapRekorderToVektklasseRekorder(
      kategori,
      Array.from(vektklasserMenn),
      rekorder
    )
  );

  return (
    <Content>
      <div className="flex flexcol">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
          >
            <Tab label="Ungdom kvinner" />
            <Tab label="Ungdom menn" />
            <Tab label="Junior kvinner" />
            <Tab label="Junior menn" />
            <Tab label="Senior kvinner" />
            <Tab label="Senior menn" />
            <Tab label="Veteran kvinner" />
            <Tab label="Veteran menn" />
          </Tabs>
        </Box>
        <TabPanel index={RekorderOverviewTab.UngdomKvinner} value={tab}>
          <RekorderTable
            rekorder={ungdomKvinnerVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.UngdomMenn} value={tab}>
          <RekorderTable
            rekorder={ungdomMennVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.JuniorKvinner} value={tab}>
          <RekorderTable
            rekorder={juniorKvinnerVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.JuniorMenn} value={tab}>
          <RekorderTable
            rekorder={juniorMennVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.SeniorKvinner} value={tab}>
          <RekorderTable
            rekorder={seniorKvinnerVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.SeniorMenn} value={tab}>
          <RekorderTable
            rekorder={seniorMennVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.VeteranKvinner} value={tab}>
          <RekorderVeteran
            kategori={selectedVeteranKategori}
            rekorder={veteranKvinnerVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
        <TabPanel index={RekorderOverviewTab.VeteranMenn} value={tab}>
          <RekorderVeteran
            kategori={selectedVeteranKategori}
            rekorder={veteranMennVektklasseRekorder}
            expandAll={expandAll}
          />
        </TabPanel>
      </div>
      <div>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => setExpandAll(!expandAll)}
        >
          {expandAll ? 'Lukk alle vektklasser' : 'Åpne alle vektklasser'}
        </Button>
        <Button
          fullWidth
          onClick={handleDownloadRekorder}
          variant="outlined"
          disabled={isLoadingExcelDownload}
          className="mt-4"
        >
          {isLoadingExcelDownload ? (
            <CircularProgress size={24} />
          ) : (
            'Last ned rekorder (excel)'
          )}
        </Button>
        {showVeteranKategoriSelect && (
          <div>
            <Select
              value={selectedVeteranKategori}
              onChange={e =>
                setSelectedVeteranKategori(
                  e.target.value as VeteranAlderskategori
                )
              }
              fullWidth
              className="mt-4"
            >
              {veteranKategorier.map(kategori => (
                <MenuItem key={kategori} value={kategori}>
                  {AlderskategoriToReadable[kategori]}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
    </Content>
  );
}

const Content = styled.section`
  display: flex;
  flex-direction: column-reverse;

  gap: 24px;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: minmax(400px, 1200px) minmax(230px, 250px);

    > *:last-child {
      margin-top: 48px;
    }
  }
`;

const RekorderOverviewTab = {
  UngdomKvinner: 0,
  UngdomMenn: 1,
  JuniorKvinner: 2,
  JuniorMenn: 3,
  SeniorKvinner: 4,
  SeniorMenn: 5,
  VeteranKvinner: 6,
  VeteranMenn: 7,
};
