import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { FunksjonaerUpload } from 'src/models/Stevne';

type FunksjonaerUploadListProps = {
  funksjonaerer: FunksjonaerUpload[];
};

export function FunksjonaerUploadList({
  funksjonaerer,
}: FunksjonaerUploadListProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Rolle</TableCellHeader>
            <TableCellHeader>Navn</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {funksjonaerer.map((funksjonaer, index) => (
            <TableRow key={index}>
              <TableCell>{funksjonaer.funksjonaerrolle?.navn}</TableCell>
              <TableCell>
                {funksjonaer.person?.fornavn} {funksjonaer.person?.etternavn}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
