import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { useSort } from 'src/hooks/useSort';
import { PublicFunksjonaer } from 'src/models/Stevne';
import { sortByProperty } from 'src/utils/sort';

type PublicFunksjonaerListProps = {
  funksjonaerer: PublicFunksjonaer[];
};

export function PublicFunksjonaerList({
  funksjonaerer,
}: PublicFunksjonaerListProps) {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<PublicFunksjonaer>('funksjonaerrolle');

  const sortedFunksjonarer = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(funksjonaerer, sortedBy, sortDirection);
    }

    return funksjonaerer;
  }, [funksjonaerer, sortedBy, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader width={250}>
              <TableSortLabel
                active={isActive('funksjonaerrolle')}
                direction={getDirection('funksjonaerrolle')}
                onClick={() => requestSort('funksjonaerrolle')}
              >
                Rolle
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('funksjonaerName')}
                direction={getDirection('funksjonaerName')}
                onClick={() => requestSort('funksjonaerName')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('klubbName')}
                direction={getDirection('klubbName')}
                onClick={() => requestSort('klubbName')}
              >
                Klubb
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('dommergrad')}
                direction={getDirection('dommergrad')}
                onClick={() => requestSort('dommergrad')}
              >
                Dommergrad
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedFunksjonarer.map((funksjonaer, index) => (
            <TableRow key={index}>
              <TableCell>{funksjonaer.funksjonaerrolle}</TableCell>
              <TableCell>{funksjonaer.funksjonaerName}</TableCell>
              <TableCell>{funksjonaer.klubbName}</TableCell>
              <TableCell>{funksjonaer.dommergrad || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
