import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { usePersoner } from 'src/ajax/hooks/usePersoner';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { useKlubb } from 'src/context/KlubbContext';
import { Person } from 'src/models/Person';
import { LoeftereTable } from './LoeftereTable';
import DownloadIcon from '@mui/icons-material/Download';
import { ReportsApi } from 'src/ajax/reports.api';
import { downloadFileFromResponse } from 'src/utils/file.utils';

export function LoefterePage() {
  const { klubb } = useKlubb();
  const { personer, isLoadingPersoner } = usePersoner(klubb?.id);

  const [visiblePersoner, setVisiblePersoner] = useState<Person[]>([]);

  const handleDownloadLoeftere = async () => {
    const response = await ReportsApi.downloadLoeftere(visiblePersoner);

    if (response.ok) {
      downloadFileFromResponse(response.axiosResponse);
    }
  };

  return (
    <section>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="mb-4"
      >
        <Header3>Løftere i {klubb?.navn}</Header3>
        <Button
          variant="outlined"
          onClick={handleDownloadLoeftere}
          endIcon={<DownloadIcon />}
        >
          Last ned
        </Button>
      </Box>

      <LoeftereTable
        personer={personer}
        isLoadingPersoner={isLoadingPersoner}
        onRowsChange={setVisiblePersoner}
      />
    </section>
  );
}
