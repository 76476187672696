import {
  InternasjonaltStevne,
  InternasjonaltStevneList,
} from 'src/models/InternasjonaltStevne';
import useSWR from 'swr';
import { ApiError } from '../ajax';

const INTERNASJONAL_STEVNER_URI = '/internasjonal/stevner/';

export function useInternasjonalStevner() {
  const { data: internasjonaleStevner, error } = useSWR<
    InternasjonaltStevneList[],
    ApiError
  >(INTERNASJONAL_STEVNER_URI);

  return {
    internasjonaleStevner: internasjonaleStevner ?? [],
    isLoadingInternasjonalStevner: !internasjonaleStevner && !error,
    error: error,
  };
}

export function useInternasjonalStevne(id: string | undefined) {
  const { data: internasjonalStevne, error, mutate } = useSWR<
    InternasjonaltStevne,
    ApiError
  >(`${INTERNASJONAL_STEVNER_URI}${id}/`);

  return {
    internasjonalStevne: internasjonalStevne,
    isLoadingInternasjonalStevne: !internasjonalStevne && !error && !!id,
    error: error,
    refreshInternasjonaltStevne: mutate,
  };
}
