import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridInitialState,
  GridValueGetterParams,
  gridVisibleSortedRowEntriesSelector,
} from '@mui/x-data-grid';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { mapKjonnToReadable, Person } from 'src/models/Person';
import { formatFromApiDate } from 'src/utils/date';

const columns: GridColDef<Person>[] = [
  {
    field: 'nvfId',
    headerName: 'NVF-ID',
    width: 120,
    filterable: false,
  },
  {
    field: 'fullName',
    headerName: 'Navn',
    width: 300,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.etternavn}, ${params.row.fornavn}`,
  },
  {
    field: 'fodselsdato',
    headerName: 'Fødselsdato',
    width: 160,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      formatFromApiDate(params.row.fodselsdato),
  },
  {
    field: 'kjonn',
    headerName: 'Kjønn',
    filterable: false,
    valueGetter: (params: GridValueGetterParams) =>
      mapKjonnToReadable(params.row.kjonn),
  },
];

type LoeftereTableProps = {
  personer: Person[];
  isLoadingPersoner: boolean;
  onRowsChange: (personer: Person[]) => void;
};

export const LoeftereTable = ({
  personer,
  isLoadingPersoner,
  onRowsChange,
}: LoeftereTableProps) => {
  const handleStateChange = (state: GridStateCommunity) => {
    const visiblePersons = gridVisibleSortedRowEntriesSelector(state).map(
      x => x.model as Person
    );

    onRowsChange(visiblePersons);
  };

  if (personer.length === 0) {
    return <Paragraph className="mt-2">Ingen løftere i klubben</Paragraph>;
  }

  const initialState: GridInitialState = {
    sorting: {
      sortModel: [{ field: 'fullName', sort: 'asc' }],
    },
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        initialState={initialState}
        autoHeight
        rows={personer}
        columns={columns}
        onStateChange={handleStateChange}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoadingPersoner}
      />
    </Box>
  );
};
