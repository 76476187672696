import SearchIcon from '@mui/icons-material/Search';
import { Divider, IconButton, InputBase, Paper } from '@mui/material';

type SearchFieldProps = {
  value: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClick?: () => void;
};

export function SearchField({
  value,
  placeholder,
  onChange,
  onSearchClick,
}: SearchFieldProps) {
  const onEnterKey = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && onSearchClick) {
      onSearchClick();
    }
  };

  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
      }}
    >
      <InputBase
        className="p-2"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onEnterKey}
        fullWidth
      />
      <Divider orientation="vertical" variant="fullWidth" flexItem />
      <IconButton
        color="primary"
        sx={{ p: '10px', ml: 1 }}
        aria-label="search"
        onClick={onSearchClick}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
