type HttpsRedirectProps = {
  children: React.ReactElement;
};

export function HttpsRedirect({ children }: HttpsRedirectProps) {
  const isHttpProtocol =
    typeof window !== 'undefined' &&
    window.location &&
    window.location.protocol === 'http:';

  if (isHttpProtocol && !isLocalHost(window.location.hostname)) {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    return null;
  }

  return children;
}

function isLocalHost(hostname: string): boolean {
  return (
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) !==
      null
  );
}
