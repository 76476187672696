import { SVGAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

export const rotator = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`;

const Svg = styled.svg`
  animation: ${rotator} 1.4s linear infinite;
  transform-origin: center;
`;

const Circle = styled.circle`
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  stroke-linecap: round;
  stroke: #354754;
  stroke-width: 4;
  animation: ${dash} 1.4s ease-in-out infinite;
`;

type LoaderProps = {
  size?: number;
  active: boolean;
} & SVGAttributes<SVGElement>;

export const Loader = ({ size = 50, active, style, ...rest }: LoaderProps) => {
  return active ? (
    <Svg
      viewBox="25 25 50 50"
      style={{
        width: size,
        height: size,
        ...style,
      }}
      {...rest}
    >
      <Circle cx="50" cy="50" r="20" fill="none" />
    </Svg>
  ) : null;
};
