import { mapPersonIdToReadable, Person } from "./Person";

export type Funksjonaerrolle = {
  id: number;
  navn: string;
  aktiv: boolean;
};

export function mapFunksjonaerrolleIdToReadable(
  funksjonaerrolleId: number,
  funksjonaerroller: Funksjonaerrolle[]
) {
  return (
    funksjonaerroller.find(
      funksjonaerrolle => funksjonaerrolle.id === funksjonaerrolleId
    )?.navn || ''
  );
}

export type Dommer = {
  id: number;
  personId: number;
  navn: string;
  dommergradId: number;
  dommergrad: string;
};

export function mapDommerIdToReadable(
  dommerId: number,
  dommere: Dommer[],
  personer: Person[]
) {
  const personId = dommere.find(dommer => dommer.id === dommerId)?.personId || 0;

  return mapPersonIdToReadable(personId, personer);
}
