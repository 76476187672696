import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { Loader } from 'src/components/shared/Loader/Loader';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useSort } from 'src/hooks/useSort';
import { Stevne } from 'src/models/Stevne';
import { formatDate } from 'src/utils/date';
import { SortDirection, sortByProperty } from 'src/utils/sort';

type StevnerTableProps = {
  stevner: Stevne[];
  isLoadingStevner: boolean;
  onRowClick: (stevne: Stevne) => void;
};

export const StevnerTable = ({
  stevner,
  isLoadingStevner,
  onRowClick,
}: StevnerTableProps) => {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<Stevne>('startDato', SortDirection.Descending);

  const sortedStevner = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(stevner, sortedBy, sortDirection);
    }

    return stevner;
  }, [stevner, sortedBy, sortDirection]);

  if (stevner.length === 0) {
    return (
      <Paragraph
        className="mt-2"
        style={{ fontStyle: 'italic', color: '#494949' }}
      >
        Ingen stevner å vise fram
      </Paragraph>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('klubb.navn')}
                direction={getDirection('klubb.navn')}
                onClick={() => requestSort('klubb.navn')}
              >
                Klubb
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('sted')}
                direction={getDirection('sted')}
                onClick={() => requestSort('sted')}
              >
                Lokasjon
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('stevnetype')}
                direction={getDirection('stevnetype')}
                onClick={() => requestSort('stevnetype')}
              >
                Stevnetype
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('startDato')}
                direction={getDirection('startDato')}
                onClick={() => requestSort('startDato')}
              >
                Dato
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingStevner ? (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                <Loader active size={40} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedStevner.map(stevne => (
                <TableRow
                  key={stevne.id}
                  onClick={() => onRowClick(stevne)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{stevne.klubb.navn}</TableCell>
                  <TableCell>{stevne.sted}</TableCell>
                  <TableCell>{stevne.stevnetype}</TableCell>
                  <TableCell>
                    {stevne.startDato ? formatDate(stevne.startDato) : '–'}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
