import { AxiosError } from 'axios';
import toast, { Toaster } from 'react-hot-toast';

export function showSuccessNotification(message: string) {
  return toast.success(message);
}

export function showErrorNotification(message: string) {
  return toast.error(message);
}

export function showApiErrorNotification(error: AxiosError, defaultMessage?: string) {
  const isValidationError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (error.response?.data && isValidationError) {
    return showErrorNotification(error.response.data);
  }

  return showErrorNotification(defaultMessage || 'Noe gikk galt');
}

export function Notifications() {
  return (
    <Toaster
      position="bottom-right"
      gutter={8}
      toastOptions={{
        duration: 5000,
        success: {
          style: {
            background: '#cddbdb',
            color: '#1a2639',
          },
        },
        error: {
          style: {
            background: '#ffd6ca',
            color: '#1a2639',
          },
        },
      }}
    />
  );
}
