import { useNavigate, useParams } from 'react-router-dom';
import {
  showApiErrorNotification,
  showErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { Paths } from 'src/Routes';
import { putRequest } from 'src/ajax/ajax';
import { useInternasjonalStevne } from 'src/ajax/hooks/useInternasjonalStevne';
import { usePersoner } from 'src/ajax/hooks/usePersoner';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { Page } from 'src/components/shared/Page/Page';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { StevnerInternasjonalForm } from './StevnerInternasjonalForm';
import {
  mapInternasjonalStevneToStevnerInternasjonalFormState,
  mapStevnerInternasjonalFormStateToUpdateInternasjonaltStevne,
} from './StevnerInternasjonalForm.mappers';
import { StevnerInternasjonalFormState } from './StevnerInternasjonalForm.types';
import { RekorderApi } from 'src/ajax/rekorder.api';
import { mapRekordCandidateToRekordInput } from 'src/components/shared/Rekorder/rekord.mappers';
import { RekordCandidate } from 'src/models/Rekord';
import { useState } from 'react';
import { InternasjonaltStevneResponse } from 'src/models/InternasjonaltStevne';
import { RekordCandidatesModal } from 'src/components/shared/Rekorder/RekordCandidatesModal';

export function StevnerInternasjonalEditPage() {
  const { id: internasjonaltStevneId } = useParams();
  const { personer } = usePersoner();
  const navigate = useNavigate();

  const [showRekordModal, setShowRekordModal] = useState(false);
  const [rekordCandidates, setRekordCandidates] = useState<RekordCandidate[]>(
    []
  );

  const {
    internasjonalStevne,
    isLoadingInternasjonalStevne,
    refreshInternasjonaltStevne,
  } = useInternasjonalStevne(internasjonaltStevneId);

  const submitInternasjonaltStevne = async (
    state: StevnerInternasjonalFormState
  ) => {
    const data =
      mapStevnerInternasjonalFormStateToUpdateInternasjonaltStevne(state);
    const response = await putRequest<InternasjonaltStevneResponse>(
      `internasjonal/stevner/${internasjonaltStevneId}/`,
      data
    );

    if (response.ok) {
      showSuccessNotification('Internasjonalt stevne oppdatert');
      
      const candidates = response.data.rekordCandidates;
      if (candidates.length > 0) {
        setRekordCandidates(candidates);
        setShowRekordModal(true);
        return;
      }
      
      refreshInternasjonaltStevne(); // Sørger for at cachen er oppdatert
      navigate(Paths.stevner.base);
    } else {
      showApiErrorNotification(
        response.error,
        'Kunne ikke lagre internasjonalt stevne'
      );
    }
  };

  const createRekorder = async (candidates: RekordCandidate[]) => {
    const rekordInputs = candidates.map(mapRekordCandidateToRekordInput);

    const response = await RekorderApi.createRekordBatch(rekordInputs);

    if (response.ok) {
      showSuccessNotification('Rekorder opprettet');
      setShowRekordModal(false);
      refreshInternasjonaltStevne(); // Sørger for at cachen er oppdatert
      navigate(Paths.stevner.base);
    } else {
      showErrorNotification('Kunne ikke opprette rekorder');
    }
  };

  const cancelCreateRekorder = () => {
    setShowRekordModal(false);
    refreshInternasjonaltStevne(); // Sørger for at cachen er oppdatert
    navigate(Paths.stevner.base);
  }

  if (!internasjonalStevne || isLoadingInternasjonalStevne) {
    return <PageLoader />;
  }

  const defaultStevnerInternasjonalFormState =
    mapInternasjonalStevneToStevnerInternasjonalFormState(internasjonalStevne);

  return (
    <Page>
      <Header1 className="mb-8">Internasjonalt stevne</Header1>
      <StevnerInternasjonalForm
        personer={personer}
        submitLabel="Lagre stevne"
        defaultValues={defaultStevnerInternasjonalFormState}
        onSubmit={submitInternasjonaltStevne}
      />

      <RekordCandidatesModal
        open={showRekordModal}
        candidates={rekordCandidates}
        onConfirm={createRekorder}
        onClose={cancelCreateRekorder}
      />
    </Page>
  );
}
