import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DashboardCard } from 'src/components/shared/Card/DashboardCard';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { LeadType } from 'src/components/shared/Typography/LeadType';
import { Page } from '../../shared/Page/Page';
import {
  CardContent,
  CardDetails,
  CardHeader,
} from '../../shared/Card/Card.styles';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Header1 } from 'src/components/shared/Typography/Header1';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStevner } from 'src/ajax/hooks/useStevner';
import { DetailType } from 'src/components/shared/Typography/DetailType';
import { Paths } from 'src/Routes';

export function DashboardPage() {
  const navigate = useNavigate();
  const { stevner } = useStevner();

  const numberOfStevnerForApproval = stevner.filter(s => !s.godkjent).length;

  return (
    <Page>
      <Header1>Oversikt</Header1>

      <Grid container className="mt-6">
        <Grid item md={12} lg={4}>
          <DashboardCard
            Icon={FormatListBulletedIcon}
            onClick={() => navigate(Paths.stevner.base)}
          >
            <CardHeader>
              <Header3>Stevner</Header3>
              <ArrowForwardIcon />
            </CardHeader>

            <CardContent>
              <CardDetails>
                <LeadType>{numberOfStevnerForApproval}</LeadType>
                <DetailType>
                  {numberOfStevnerForApproval === 1 ? 'Stevne' : 'Stevner'} til
                  godkjenning
                </DetailType>
              </CardDetails>
            </CardContent>
          </DashboardCard>
        </Grid>
      </Grid>
    </Page>
  );
}
