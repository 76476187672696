import {
  RekordCandidate,
  RekordInput,
  RekordList,
  VektklasseRekord,
} from 'src/models/Rekord';
import { Alderskategori, VektklasseType } from 'src/models/Stevne';

export function mapRekordCandidateToRekordInput(
  rekordCandidate: RekordCandidate
): RekordInput {
  return {
    kategoriForkortelse: rekordCandidate.kategori.forkortelse as Alderskategori,
    vektklasseNavn: rekordCandidate.vektklasse.navn,
    personId: rekordCandidate.person.id,
    klubbId: rekordCandidate.klubb.id,
    ovelse: rekordCandidate.ovelse,
    ovelseLoft: rekordCandidate.ovelseLoft,
    navn: rekordCandidate.resultat.historicPersonNavn,
    klubbnavn: rekordCandidate.resultat.historicKlubb.navn,
    sted: rekordCandidate.resultat.sted,
    dato: rekordCandidate.resultat.dato,
    isStandard: false,
  };
}

export function mapRekorderToVektklasseRekorder(
  kategoriForkortelse: Alderskategori,
  vektklasser: VektklasseType[],
  rekorder: RekordList[]
): VektklasseRekord[] {
  const vektklasseRekorder: VektklasseRekord[] = [];

  vektklasser.forEach(vektklasseNavn => {
    const rekord = rekorder.filter(
      r =>
        r.vektklasse.navn === vektklasseNavn &&
        r.kategori.forkortelse === kategoriForkortelse
    );

    const rykkRekord = rekord.find(r => r.ovelse === 'RYKK');
    const stotRekord = rekord.find(r => r.ovelse === 'STOT');
    const sammenlagtRekord = rekord.find(r => r.ovelse === 'SAMMENLAGT');

    const rykk = rykkRekord
      ? {
          navn: rykkRekord.navn,
          klubbnavn: rykkRekord.klubbnavn,
          sted: rykkRekord.sted,
          dato: rykkRekord.dato,
          ovelseLoft: rykkRekord.ovelseLoft,
          isStandard: rykkRekord.isStandard,
        }
      : null;

    const stot = stotRekord
      ? {
          navn: stotRekord.navn,
          klubbnavn: stotRekord.klubbnavn,
          sted: stotRekord.sted,
          dato: stotRekord.dato,
          ovelseLoft: stotRekord.ovelseLoft,
          isStandard: stotRekord.isStandard,
        }
      : null;

    const sammenlagt = sammenlagtRekord
      ? {
          navn: sammenlagtRekord.navn,
          klubbnavn: sammenlagtRekord.klubbnavn,
          sted: sammenlagtRekord.sted,
          dato: sammenlagtRekord.dato,
          ovelseLoft: sammenlagtRekord.ovelseLoft,
          isStandard: sammenlagtRekord.isStandard,
        }
      : null;

    const vektklasseVekt = vektklasseNavn.startsWith('+')
      ? 999
      : parseInt(vektklasseNavn);

    vektklasseRekorder.push({
      vektklasseNavn,
      vektklasseVekt,
      kategoriForkortelse,
      rykk,
      stot,
      sammenlagt,
    });
  });

  return vektklasseRekorder;
}
