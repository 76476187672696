import { RekordList } from 'src/models/Rekord';
import { usePublicSWR } from './usePublicSWR';

export function usePublicRekorder() {
  const {
    data: rekorder,
    error,
    mutate,
  } = usePublicSWR<RekordList[]>('/public/rekorder');

  const isLoadingRekorder = !rekorder && !error;

  return {
    rekorder: rekorder || [],
    isLoadingRekorder,
    error,
    refreshRekorder: mutate,
  };
}
