import { isKlubbAdmin } from 'src/models/User';
import styled from 'styled-components';
import logo from '../../../assets/images/nvf-logo.png';
import { useAuth } from '../../../context/AuthContext';
import { Menu } from '../Menu/Menu';
import { DetailType } from '../Typography/DetailType';
import { LeadType } from '../Typography/LeadType';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  min-height: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 2rem;
  background: #fff;
  color: var(--primary-color);
  border-bottom: 1px solid var(--quaternary-color);
`;

const StyledHeaderItem = styled.div`
  display: inline-flex;
  align-items: center;
  color: var(--primary-color);

  button {
    color: inherit;
  }
`;

const Logo = styled.img`
  height: 2.5rem;
`;

const Title = styled.span`
  font-family: 'Open Sans';
  font-size: 1.5rem;
  line-height: 2rem;
  margin-left: 1.5rem;
  color: var(--primary-color);
`;

export function Header() {
  const { user, signout } = useAuth();

  const onSignout = () => {
    signout();
  };

  const menuItems = [
    {
      title: 'Logg ut',
      onClick: onSignout,
    },
  ];

  return (
    <StyledHeader>
      <StyledHeaderItem>
        <Logo src={logo} alt="Norges Vektløfterforbund logo" />
        <Title>Resultatsystem</Title>
      </StyledHeaderItem>
      <StyledHeaderItem>
        <div className="flex flexcol mr-2">
          <LeadType>{user?.firstName}</LeadType>
          {isKlubbAdmin(user) && (
            <DetailType>{user.klubb.fulltNavn}</DetailType>
          )}
        </div>

        <Menu iconType="account" items={menuItems} />
      </StyledHeaderItem>
    </StyledHeader>
  );
}
