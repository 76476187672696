import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UsersApi } from 'src/ajax/users.api';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { Klubb, mapKlubbIdToReadable } from 'src/models/Klubb';
import * as Yup from 'yup';

type UsersModalProps = {
  open: boolean;
  klubber: Klubb[];
  onClose: () => void;
};

export type UsersRegistrationLinkFormState = {
  klubbId: Nullable<number>;
};

export const validationSchema = Yup.object<
  Record<keyof UsersRegistrationLinkFormState, Yup.AnySchema>
>({
  klubbId: Yup.number().required('Klubb er påkrevd').nullable(),
});

export function UsersModal({ open, klubber, onClose }: UsersModalProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UsersRegistrationLinkFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      klubbId: null,
    },
  });

  const [registrationKey, setRegistrationKey] =
    useState<Nullable<string>>(null);

  const handleCreateRegistrationLink = async (
    state: UsersRegistrationLinkFormState
  ) => {
    const response = await UsersApi.createRegistrationLink(state);

    if (response.ok) {
      setRegistrationKey(response.data);
    }
  };

  const handleClose = () => {
    setRegistrationKey(null);
    onClose();
  };

  const registrationLink = registrationKey
    ? `${window.location.origin}/brukere/opprett/${registrationKey}`
    : null;

  return (
    <Modal open={open} onClose={handleClose} width={700}>
      <ModalHeader>
        <Header1>Opprett ny registreringslenke</Header1>
      </ModalHeader>
      <ModalBody>
        {registrationKey && (
          <Paragraph>
            <div className="mb-4">Lenke som skal videresendes til bruker:</div>
            <Block>{registrationLink}</Block>
          </Paragraph>
        )}

        {!registrationKey && (
          <>
            <Paragraph>
              Velg hvilken klubb den nye brukeren skal tilhøre og opprett lenke
              til registrering. Lenken vil være gyldig i 3 dager og kan kun
              benyttes en gang.
            </Paragraph>

            <form onSubmit={handleSubmit(handleCreateRegistrationLink)}>
              <Controller
                control={control}
                name="klubbId"
                render={({ field }) => (
                  <Autocomplete
                    options={klubber.map(klubb => klubb.id)}
                    getOptionLabel={option =>
                      mapKlubbIdToReadable(option, klubber)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Klubb"
                        error={!!errors.klubbId}
                        helperText={errors.klubbId?.message}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value)}
                    className="mt-8"
                  />
                )}
              />

              <Button variant="contained" type="submit" className="mt-8">
                Opprett lenke
              </Button>
            </form>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

type BlockProps = {
  children: React.ReactNode;
};

function Block({ children }: BlockProps) {
  return (
    <Box
      component="span"
      sx={{
        display: 'block',
        p: 1,
        m: 1,
        bgcolor: theme => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: theme =>
          theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        border: '1px solid',
        borderColor: theme =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        wordBreak: 'break-all',
      }}
    >
      {children}
    </Box>
  );
}
