import { Tabs, Tab } from '@mui/material';
import { useMemo, useState } from 'react';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { Person } from 'src/models/Person';
import { PersonerTable } from './PersonerTable';

type PersonerTabsProps = {
  klubbId: number;
  personer: Person[];
  isLoadingPersoner: boolean;
  onRowClick: (person: Person) => void;
  onDeleteClick: (person: Person) => void;
};

export function PersonerTabs({
  klubbId,
  personer,
  isLoadingPersoner,
  onRowClick,
  onDeleteClick,
}: PersonerTabsProps) {
  const [tab, setTab] = useState(0);

  const klubbPersoner = useMemo(
    () => personer.filter(p => p.klubb.id === klubbId),
    [personer, klubbId]
  );

  const handleTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  return (
    <>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={tab}
        onChange={handleTabChange}
        className="mb-8"
      >
        <Tab label="Personer i klubben" />

        <Tab label="Alle personer" />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <PersonerTable
          personer={klubbPersoner}
          isLoadingPersoner={isLoadingPersoner}
          onRowClick={onRowClick}
          onDeleteClick={onDeleteClick}
        />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <PersonerTable
          personer={personer}
          isLoadingPersoner={isLoadingPersoner}
        />
      </TabPanel>
    </>
  );
}
