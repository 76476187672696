import { StevneDetail, StevneUpload } from 'src/models/Stevne';
import {
  FunksjonaerFormState,
  ResultatFormState,
  StevnerFormState,
} from './Stevner.types';

export function mapStevneDetailToStevneFormState(
  stevne: StevneDetail
): StevnerFormState {
  return {
    arrangorklubbId: stevne.klubb.id,
    stevnetypeId: stevne.stevnetype.id,
    sted: stevne.sted,
    godkjent: stevne.godkjent,
    puljeList: stevne.puljeList.map(pulje => ({
      puljeNr: pulje.puljeNr,
      dato: pulje.dato ? new Date(pulje.dato) : undefined,
      beskrivelse: pulje.beskrivelse || undefined,
      funksjonaerList: pulje.funksjonaerList.map(funksjonaer => ({
        personId: funksjonaer.personId,
        funksjonaerrolleId: funksjonaer.funksjonaerrolle.id,
      })) as FunksjonaerFormState[],
      resultatList: pulje.resultatList.map(resultat => ({
        personId: resultat.person.id,
        kroppsvekt: +resultat.kroppsvekt,
        startnummer: resultat.startnummer,
        navn: resultat.historicPersonNavn,
        rykk1: resultat.rykk1,
        rykk2: resultat.rykk2,
        rykk3: resultat.rykk3,
        stot1: resultat.stot1,
        stot2: resultat.stot2,
        stot3: resultat.stot3,
      })) as ResultatFormState[],
    })),
  };
}

export function mapStevneUploadToStevneFormState(
  stevne: StevneUpload
): StevnerFormState {
  return {
    arrangorklubbId: stevne.arrangorklubb?.id,
    stevnetypeId: stevne.stevnetype?.id,
    sted: stevne.sted,
    godkjent: false,
    puljeList: stevne.puljeList.map(pulje => ({
      puljeNr: pulje.puljeNr,
      dato: pulje.dato ? new Date(pulje.dato) : undefined,
      beskrivelse: pulje.beskrivelse,
      funksjonaerList: pulje.funksjonaerList?.map(funksjonaer => ({
        personId: funksjonaer.person?.id,
        funksjonaerrolleId: funksjonaer.funksjonaerrolle?.id,
      })) as FunksjonaerFormState[],
      resultatList: pulje.resultatList?.map(resultat => ({
        personId: resultat.person?.id,
        kroppsvekt: resultat.kroppsvekt,
        startnummer: resultat.startnummer,
        navn: resultat.person?.fornavn + ' ' + resultat.person?.etternavn,
        rykk1: resultat.rykk1,
        rykk2: resultat.rykk2,
        rykk3: resultat.rykk3,
        stot1: resultat.stot1,
        stot2: resultat.stot2,
        stot3: resultat.stot3,
      })) as ResultatFormState[],
    })),
  };
}
