import styled from 'styled-components';

type Props = {
  compact?: boolean;
};

export const Form = styled.form<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${props => (props.compact ? '1.25rem' : '2rem')};
`;
