import { Dommergrad } from 'src/models/Dommergrad';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export function useDommergrader() {
  const requestUri = '/dommergrad/';
  const { data, error } = useSWR<Dommergrad[], ApiError>(requestUri);

  return {
    dommergrader: data ?? [],
    isLoadingDommergrader: !data && !error,
    error: error,
  };
}
