import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'src/components/shared/Form/Form';
import { FormDate } from 'src/components/shared/Form/FormDate';
import { FormInput } from 'src/components/shared/Form/FormInput';
import { FormSelect } from 'src/components/shared/Form/FormSelect';
import { ConfirmModal } from 'src/components/shared/Modal/ConfirmModal';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useAuth } from 'src/context/AuthContext';
import { useKlubb } from 'src/context/KlubbContext';
import { Dommergrad } from 'src/models/Dommergrad';
import { Person } from 'src/models/Person';
import { isKlubbAdmin, isNvfAdmin } from 'src/models/User';
import { DateFormatApi, formatDate, isValidDate } from 'src/utils/date';
import { hasEquivalentPerson } from './Personer.utils';
import { PersonFormState, validationSchema } from './PersonerForm.schema';

type PersonFormProps = {
  person: Nullable<Person>;
  personer: Person[];
  dommergrader: Dommergrad[];
  createNewNvfId: (nvfId: string) => void;
  onSubmit: (state: PersonFormState) => void;
  onCancel: () => void;
};

export function PersonForm({
  person,
  personer,
  dommergrader,
  createNewNvfId,
  onSubmit,
  onCancel,
}: PersonFormProps) {
  const { user } = useAuth();
  const { klubber } = useKlubb();

  const [isDuplicatePerson, setIsDuplicatePerson] = useState(false);
  const [open, setOpen] = useState(false);

  const isNewPerson = useMemo(() => !person?.nvfId, [person]);

  const klubberOptions = klubber.map(klubb => ({
    id: klubb.id,
    label: klubb.navn,
  }));
  const dommegraderOptions = dommergrader.map(dommergrad => ({
    id: dommergrad.id,
    label: dommergrad.navn,
  }));

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<PersonFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fornavn: person?.fornavn || '',
      etternavn: person?.etternavn || '',
      kjonn: person?.kjonn || '',
      fodselsdato: person?.fodselsdato || '',
      klubbId: person?.klubb?.id || null,
      dommergradId: person?.dommergrad?.id || null,
      dommerkursdato: person?.dommerkursdato || null,
      dommerkursansvarlig: person?.dommerkursansvarlig || null,
    },
  });

  useEffect(() => {
    if (isKlubbAdmin(user)) {
      setValue('klubbId', user.klubb.id);
    }
  }, [user, setValue]);

  const fornavn = watch('fornavn');
  const etternavn = watch('etternavn');
  const fodselsdato = watch('fodselsdato');
  const dommergradId = watch('dommergradId');

  useEffect(() => {
    if (!person?.id && isValidDate(fodselsdato)) {
      setIsDuplicatePerson(
        hasEquivalentPerson(fornavn, etternavn, fodselsdato, personer)
      );
    } else {
      setIsDuplicatePerson(false);
    }
  }, [fornavn, etternavn, fodselsdato, person?.id, personer]);

  const onFormSubmit = (state: PersonFormState) => {
    const preparedState = {
      ...state,
      fodselsdato: format(new Date(state.fodselsdato), DateFormatApi),
      dommerkursdato: state.dommerkursdato
        ? format(new Date(state.dommerkursdato), DateFormatApi)
        : null,
    };
    onSubmit(preparedState);
  };

  const handleCreateNewNvfId = () => {
    if (person?.nvfId) {
      createNewNvfId(person?.nvfId);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <FormSelect
        name="klubbId"
        label="Klubb"
        options={klubberOptions}
        disabled={isKlubbAdmin(user)}
        control={control}
      />

      <FormInput name="fornavn" label="Fornavn" control={control} />
      <FormInput name="etternavn" label="Etternavn" control={control} />

      <FormControl component="fieldset" error={!!errors.kjonn}>
        <FormLabel>Velg kjønn</FormLabel>
        <Controller
          control={control}
          name="kjonn"
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel value="K" control={<Radio />} label="Kvinne" />
              <FormControlLabel value="M" control={<Radio />} label="Mann" />
            </RadioGroup>
          )}
        />
        <FormHelperText>{errors.kjonn?.message}</FormHelperText>
      </FormControl>

      <FormDate name="fodselsdato" label="Fødselsdato" control={control} />

      {isNvfAdmin(user) && (
        <>
          <FormSelect
            name="dommergradId"
            label="Dommergrad"
            options={dommegraderOptions}
            control={control}
          />

          {dommergradId !== null && (
            <>
              <FormDate
                name="dommerkursdato"
                label="Kursdato"
                control={control}
              />

              <FormInput
                name="dommerkursansvarlig"
                label="Kursansvarlig"
                control={control}
              />
            </>
          )}
        </>
      )}

      {isDuplicatePerson && (
        <Alert severity="warning">
          Det finnes allerede en person med samme navn og fødselsdato
        </Alert>
      )}

      <div className="flex jc-sb align-center mt-8">
        <div className="flex gap-8">
          <Button variant="contained" type="submit">
            Lagre
          </Button>

          <Button variant="text" onClick={onCancel}>
            Avbryt
          </Button>
        </div>

        {isNvfAdmin(user) && !isNewPerson && (
          <Button variant="text" color="warning" onClick={() => setOpen(true)}>
            Lag ny NVF-ID
          </Button>
        )}
      </div>

      {!isNewPerson && person && (
        <ConfirmModal
          open={open}
          title="Lag ny NVF-ID"
          confirmButtonText="Lag ny"
          onConfirm={handleCreateNewNvfId}
          onClose={() => setOpen(false)}
        >
          <div className="flex flexcol gap-2">
            <Paragraph>
              {person.fornavn} {person.etternavn} har følgende NVF-ID:{' '}
              {person.nvfId}
            </Paragraph>
            <Paragraph>
              Ny NVF-ID vil genereres utifra {formatDate(person.fodselsdato)}
            </Paragraph>
          </div>
        </ConfirmModal>
      )}
    </Form>
  );
}
