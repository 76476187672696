import {
  showApiErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { useKlubber } from 'src/ajax/hooks/useKlubber';
import { usePersoner } from 'src/ajax/hooks/usePersoner';
import { RekorderApi } from 'src/ajax/rekorder.api';
import { Page } from 'src/components/shared/Page/Page';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { RekorderForm } from './RekorderForm';
import { mapRekordFormStateToRekordInput } from './RekorderForm.mappers';
import { RekordFormState } from './RekorderForm.types';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'src/Routes';

export function RekorderFormPage() {
  const navigate = useNavigate();

  const { personer, isLoadingPersoner } = usePersoner();
  const { klubber, isLoadingKlubber } = useKlubber();

  const isLoading = isLoadingPersoner || isLoadingKlubber;

  const createRekord = async (state: RekordFormState) => {
    const input = mapRekordFormStateToRekordInput(state);

    const response = await RekorderApi.createRekord(input);

    if (response.ok) {
      showSuccessNotification('Rekord opprettet');
    } else {
      showApiErrorNotification(response.error);
    }

    navigate(Paths.rekorder.base);
  };

  return (
    <Page isLoading={isLoading}>
      <Header1>Legg inn ny rekord</Header1>

      <RekorderForm
        personer={personer}
        klubber={klubber}
        onSubmit={createRekord}
      />
    </Page>
  );
}
