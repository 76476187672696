import { createContext, useContext } from 'react';
import { useKlubber } from 'src/ajax/hooks/useKlubber';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { Klubb } from 'src/models/Klubb';
import { isKlubbAdmin } from 'src/models/User';
import { useAuth } from './AuthContext';

export type KlubbContextType = {
  klubb: Nullable<Klubb>;
  klubber: Klubb[];
};

export const KlubbContext = createContext<KlubbContextType>({
  klubb: null,
  klubber: [],
});

export const useKlubb = () => {
  return useContext(KlubbContext);
};

type KlubbProviderProps = {
  children: React.ReactNode;
};

export const KlubbProvider = ({ children }: KlubbProviderProps) => {
  const { user } = useAuth();
  const { klubber, isLoadingKlubber: isLoading } = useKlubber();

  if (isLoading) return <PageLoader />;

  if (!klubber || klubber.length === 0) return <></>;

  const defaultValue = {
    klubb: isKlubbAdmin(user) ? user.klubb : null,
    klubber,
  };

  return (
    <KlubbContext.Provider value={defaultValue}>
      {children}
    </KlubbContext.Provider>
  );
};
