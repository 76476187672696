import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#283a57',
      main: '#1a2639',
      dark: '#101723',
      contrastText: '#fff',
    },
    secondary: blue,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 4,
        style: {
          flexGrow: 1,
        },
      },
    },
  },
});
