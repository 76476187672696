import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

type DashboardCardProps = {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
} & HTMLAttributes<HTMLDivElement>;

export const DashboardCard = ({
  Icon,
  children,
  ...rest
}: DashboardCardProps) => {
  return (
    <StyledDashboardCard {...rest}>
      <DashboardCardIcon>
        <Icon />
      </DashboardCardIcon>
      <DashboardCardContent>{children}</DashboardCardContent>
    </StyledDashboardCard>
  );
};

const StyledDashboardCard = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--blue);
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: #ebeced;
  position: relative;

  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const DashboardCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
`;

const DashboardCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 4rem;
    width: 4rem;
    text-align: center;
    line-height: 4rem;
    font-size: 2rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
`;
