import { RekordInput } from 'src/models/Rekord';
import { RekordFormState } from './RekorderForm.types';
import { formatToApiDate } from 'src/utils/date';

export function mapRekordFormStateToRekordInput(
  state: RekordFormState
): RekordInput {
  const dateApiFormat = formatToApiDate(state.dato!!);

  return {
    kategoriForkortelse: state.alderskategori!!,
    vektklasseNavn: state.vektklasse!!,
    personId: state.personId,
    klubbId: state.klubbId,
    ovelse: state.ovelse!!,
    ovelseLoft: state.ovelseLoft!!,
    navn: state.navn!!,
    klubbnavn: state.klubbnavn!!,
    sted: state.sted!!,
    dato: dateApiFormat,
    isStandard: state.isStandard!!,
  };
}
