import { usePublicRekorder } from 'src/ajax/hooks/useRekorder';
import { Loader } from 'src/components/shared/Loader/Loader';
import { PublicPage } from 'src/components/shared/Page/PublicPage';
import { RekorderOverview } from 'src/components/shared/Rekorder/RekorderOverview';
import { Header1 } from 'src/components/shared/Typography/Header1';

export function PublicRekorderPage() {
  const { rekorder, isLoadingRekorder } = usePublicRekorder();

  return (
    <PublicPage>
      <Header1 className="mb-8">Nasjonale rekorder</Header1>

      {isLoadingRekorder ? (
        <Loader active />
      ) : (
        <RekorderOverview rekorder={rekorder} />
      )}
    </PublicPage>
  );
}
