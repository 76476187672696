import styled from 'styled-components';
import { PublicHeaderLarge } from './PublicHeaderLarge';
import { NavigationItem } from './Navigation.types';
import { PublicPaths } from 'src/Routes';
import { useMediaQuery } from '@mui/material';
import { Breakpoint } from 'src/utils/breakpoints';
import { PublicHeaderSmall } from './PublicHeaderSmall';

export function PublicHeader() {
  const isLargeScreen = useMediaQuery(`(min-width:${Breakpoint.small})`);

  const navItems: NavigationItem[] = [
    {
      path: PublicPaths.stevner.base,
      name: 'Stevner',
    },
    {
      path: PublicPaths.resultater.base,
      name: 'Resultater',
    },
    // TODO: Ta inn denne når rekorder er klart (alle rekorder er lagt inn)
    // {
    //   path: PublicPaths.rekorder.base,
    //   name: 'Rekorder',
    // },
  ];

  return (
    <StyledHeader>
      {isLargeScreen ? (
        <PublicHeaderLarge items={navItems} />
      ) : (
        <PublicHeaderSmall items={navItems} />
      )}
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-shrink: 0;
  width: 100%;
  min-height: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 2rem;
  background: #fff;
  color: var(--primary-color);
  border-bottom: 1px solid var(--quaternary-color);
`;
