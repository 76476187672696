import { Person } from 'src/models/Person';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export const PERSONER_URI = '/person/';

function personerUriWithKlubbId(klubbId: number) {
  return PERSONER_URI + `?klubb_id=${klubbId}`;
}

export function usePersoner(klubbId?: number) {
  const { data, error, mutate } = useSWR<Person[], ApiError>(
    klubbId ? personerUriWithKlubbId(klubbId) : PERSONER_URI
  );

  return {
    personer: data ?? [],
    isLoadingPersoner: !data && !error,
    error: error,
    mutatePersoner: mutate,
  };
}

export function usePerson(nvfId: string | undefined) {
  const requestUri = nvfId ? `/person/${nvfId}/` : null;
  const { data, error } = useSWR<Person, ApiError>(requestUri);

  return {
    person: data,
    isLoadingPerson: !data && !error,
    error: error,
  };
}
