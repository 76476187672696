import { Button } from '@mui/material';
import React, { ErrorInfo } from 'react';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: Report error
  }

  resetError = () => this.setState({ hasError: false });

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h2>En uventet feil oppstod</h2>
          <p>Kontakt NVF om problemet ikke løser seg.</p>

          <hr />

          <Button onClick={this.resetError}>Prøv igjen</Button>
        </>
      );
    }

    return this.props.children;
  }
}
