import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postRequest } from 'src/ajax/ajax';
import { Dropzone } from 'src/components/shared/Dropzone/Dropzone';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { showErrorNotification } from 'src/Notifications';
import { Paths } from 'src/Routes';
import { StevnerUploadProtokollResponse } from './Stevner.types';

type StevnerUploadProtokollProps = {
  open: boolean;
  onClose: () => void;
  setValidationErrorMessages: (messages: string[]) => void;
};

export function StevnerUploadProtokollModal({
  open,
  onClose,
  setValidationErrorMessages
}: StevnerUploadProtokollProps) {
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();

  const handleChange = (files: File[]) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const handleSubmitFile = async () => {
    const formData = new FormData();
    formData.append('file', file!);

    const response = await postRequest<StevnerUploadProtokollResponse>(
      `/stevneprotokoll/`,
      formData
    );

    if (response.ok) {
      navigate(Paths.stevner.upload, {
        state: {
          stevne: response.data.stevne,
          warnings: response.data.warnings,
        },
      });
    } else {
      const errorResponse = response.error?.response;
      const messages = errorResponse?.data;
      if (errorResponse?.status === 400 && messages?.length > 0) {
        setValidationErrorMessages(messages);
      } else {
        showErrorNotification('Kunne ikke laste opp stevneprotokoll');
      }
    }
  };

  const handleOnConfirm = () => {
    handleSubmitFile();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        <Header1>Last opp stevneprotokoll</Header1>
      </ModalHeader>
      <ModalBody>
        <Dropzone
          onChange={handleChange}
          placeholder="Dra og slipp en stevneprotokoll (excel-fil) her, eller klikk for å velge."
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="contained" onClick={handleOnConfirm} disabled={!file}>
          Last opp
        </Button>

        <Button variant="text" onClick={onClose} className="ml-8">
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
}
