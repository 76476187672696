import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'src/components/shared/Form/Form';
import { FormDate } from 'src/components/shared/Form/FormDate';
import { FormInput } from 'src/components/shared/Form/FormInput';
import { FormSelect } from 'src/components/shared/Form/FormSelect';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { Klubb } from 'src/models/Klubb';
import { Person } from 'src/models/Person';
import { RekordOvelseToReadable, rekordOvelser } from 'src/models/Rekord';
import { AlderskategoriToReadable, alderskategorier } from 'src/models/Stevne';
import { rekorderSchema } from './RekorderForm.schema';
import { RekordFormState, rekordFormInitialState } from './RekorderForm.types';
import { RekorderFormVektklasseSelect } from './RekorderFormVektklasseSelect';

type RekorderFormProps = {
  personer: Person[];
  klubber: Klubb[];
  onSubmit: (state: RekordFormState) => void;
};

export function RekorderForm({
  personer,
  klubber,
  onSubmit,
}: RekorderFormProps) {
  const formMethods = useForm<RekordFormState>({
    resolver: yupResolver(rekorderSchema),
    defaultValues: rekordFormInitialState,
  });

  const { control, watch, setValue, reset, handleSubmit } = formMethods;

  const personId = watch('personId');
  const klubbId = watch('klubbId');
  const selectedAlderskategori = watch('alderskategori');
  const isStandard = watch('isStandard');

  useEffect(() => {
    if (personId) {
      const person = personer.find(person => person.id === personId);
      if (person) {
        setValue('navn', `${person.fornavn} ${person.etternavn}`);
        setValue('klubbId', person.klubb.id || null);
      }
    }
  }, [personId, personer, setValue]);

  useEffect(() => {
    if (klubbId) {
      const klubb = klubber.find(klubb => klubb.id === klubbId);
      if (klubb) {
        setValue('klubbnavn', klubb.navn);
      }
    }
  }, [klubbId, klubber, setValue]);

  useEffect(() => {
    if (isStandard) {
      setValue('personId', null);
      setValue('klubbId', null);

      setValue('navn', 'STANDARD');
      setValue('klubbnavn', 'STANDARD');
      setValue('sted', 'STANDARD');
      setValue('dato', new Date('2018-01-01'));
    } else {
      reset();
    }
  }, [isStandard, reset, setValue]);

  const disableNavn = !!personId || !!isStandard;
  const disableKlubbnavn = !!klubbId || !!isStandard;
  const disablePersonId = !!isStandard;
  const disableKlubbId = !!isStandard;
  const disableSted = !!isStandard;

  const personOptions = personer.map(person => ({
    id: person.id,
    label: `${person.fornavn} ${person.etternavn} (${person.nvfId})`,
  }));
  const klubbOptions = klubber.map(klubb => ({
    id: klubb.id,
    label: klubb.navn,
  }));
  const ovelseOptions = rekordOvelser.map(ovelse => ({
    id: ovelse,
    label: RekordOvelseToReadable[ovelse],
  }));
  const alderskategoriOptions = alderskategorier.map(kategori => ({
    id: kategori,
    label: AlderskategoriToReadable[kategori],
  }));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex jc-end">
        <Button variant="contained" type="submit">
          Opprett rekord
        </Button>
      </div>

      <div className="flex gap-8 w100">
        <div className="flex flexcol gap-4 w100">
          {/* Felter som kan generere opp andre verdier */}

          <FormSelect
            name="personId"
            label="Velg løfter"
            options={personOptions}
            disabled={disablePersonId}
            control={control}
          />
          <FormSelect
            name="klubbId"
            label="Velg klubb"
            options={klubbOptions}
            disabled={disableKlubbId}
            control={control}
          />

          <Paragraph>
            Disse feltene er valgfrie, men vil knytte en rekord til en person
            og/eller klubb som finnes i systemet.
          </Paragraph>

          <FormControlLabel
            control={
              <Controller
                name="isStandard"
                control={control}
                render={({ field }) => <Switch {...field} />}
              />
            }
            label="Rekorden er en standard"
            className="mt-8"
          />
        </div>

        <div className="flex flexcol gap-4 w100">
          <FormInput
            name="navn"
            label="Navn på person"
            disabled={disableNavn}
            control={control}
          />
          <FormSelect
            name="alderskategori"
            label="Kategori"
            options={alderskategoriOptions}
            control={control}
          />

          <RekorderFormVektklasseSelect
            alderskategori={selectedAlderskategori}
            control={control}
          />

          <FormSelect
            name="ovelse"
            label="Øvelse"
            options={ovelseOptions}
            control={control}
          />
          <FormInput
            name="ovelseLoft"
            label="Antall kilo løftet"
            type="number"
            control={control}
          />
          <FormInput
            name="klubbnavn"
            label="Klubbnavn"
            disabled={disableKlubbnavn}
            control={control}
          />
          <FormInput
            name="sted"
            label="Sted"
            disabled={disableSted}
            control={control}
          />
          <FormDate name="dato" label="Dato" control={control} />
        </div>
      </div>
    </Form>
  );
}
