import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Klubb, mapKlubbIdToReadable } from 'src/models/Klubb';
import { mapStevnetypeIdToReadable, Stevnetype } from 'src/models/Stevnetype';
import { isNvfAdmin, User } from 'src/models/User';
import styled from 'styled-components';

const StyledStevnerFormInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 40rem;
`;

type StevnerFormInfoProps = {
  user: User;
  klubber: Klubb[];
  stevnetyper: Stevnetype[];
};

export function StevnerFormInfo({
  user,
  klubber,
  stevnetyper,
}: StevnerFormInfoProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <StyledStevnerFormInfo>
      <Controller
        name="sted"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Sted"
            error={!!errors.sted}
            helperText={errors.sted?.message}
            value={field.value === undefined ? '' : field.value}
          />
        )}
      />

      <Controller
        name="arrangorklubbId"
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <Autocomplete
            {...rest}
            options={klubber.map(klubb => klubb.id)}
            getOptionLabel={option => mapKlubbIdToReadable(option, klubber)}
            renderInput={params => (
              <TextField
                {...params}
                label="Klubb"
                error={!!errors.arrangorklubbId}
                helperText={errors.arrangorklubbId?.message}
              />
            )}
            disabled={!isNvfAdmin(user)}
            value={value === undefined ? null : value}
            onChange={(_, item) => {
              onChange(item);
            }}
          />
        )}
      />

      <Controller
        name="stevnetypeId"
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <Autocomplete
            {...rest}
            options={stevnetyper.map(stevnetype => stevnetype.id)}
            getOptionLabel={option =>
              mapStevnetypeIdToReadable(option, stevnetyper)
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Stevnetype"
                error={!!errors.stevnetypeId}
                helperText={errors.stevnetypeId?.message}
              />
            )}
            value={value === undefined ? null : value}
            onChange={(_, item) => {
              onChange(item);
            }}
          />
        )}
      />
    </StyledStevnerFormInfo>
  );
}
