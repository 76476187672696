import { Button } from '@mui/material';
import { useState } from 'react';
import { useUsers } from 'src/ajax/hooks/useUsers';
import { Page } from 'src/components/shared/Page/Page';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useKlubb } from 'src/context/KlubbContext';
import { UsersModal } from './UsersModal';
import { UsersTable } from './UsersTable';

export function UsersPage() {
  const { users, isLoading } = useUsers();
  const { klubber } = useKlubb();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <Page>
      <Header1 className="mb-8">Oversikt over klubb-administratorer</Header1>

      <div className="flex jc-sb mb-10">
        <Paragraph>
          Her finner du alle administratorer i de ulike klubbene.
        </Paragraph>

        <Button variant="outlined" onClick={handleModalOpen}>
          Opprett ny registreringslenke
        </Button>
      </div>

      <UsersTable users={users} isLoadingUsers={isLoading} />

      <UsersModal
        open={modalIsOpen}
        klubber={klubber}
        onClose={handleModalClose}
      />
    </Page>
  );
}
