import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';
import { DashboardPage } from './components/pages/Dashboard/DashboardPage';
import { KlubberPage } from './components/pages/Klubber/KlubberPage';
import { LoginPage } from './components/pages/Login/LoginPage';
import { PersonerPage } from './components/pages/Personer/PersonerPage';
import { PublicRankingPage } from './components/pages/Public/Ranking/PublicRankingPage';
import { PublicStevnerPage } from './components/pages/Public/Stevner/PublicStevnerPage';
import { PublicStevnePage } from './components/pages/Public/Stevner/Stevne/PublicStevnePage';
import { DommerePage } from './components/pages/Rapporter/Dommere/DommerePage';
import { LoefterePage } from './components/pages/Rapporter/Loeftere/LoefterePage';
import { RankingPage } from './components/pages/Rapporter/Ranking/RankingPage';
import { RapporterPage } from './components/pages/Rapporter/RapporterPage';
import { StevnerInternasjonalEditPage } from './components/pages/Stevner/Internasjonal/StevnerInternasjonalEditPage';
import { StevnerInternasjonalPage } from './components/pages/Stevner/Internasjonal/StevnerInternasjonalPage';
import { StevneEditPage } from './components/pages/Stevner/StevneEditPage';
import { StevneViewPage } from './components/pages/Stevner/StevneViewPage';
import { StevnerPage } from './components/pages/Stevner/StevnerPage';
import { StevneUploadPreviewPage } from './components/pages/Stevner/Upload/StevneUploadPreviewPage';
import { UsersPage } from './components/pages/Users/UsersPage';
import { UsersRegistrationPage } from './components/pages/Users/UsersRegistrationPage';
import { AuthorizeNvfAdmin } from './components/shared/Auth/AuthorizeNvfAdmin';
import { PublicStevneInternasjonalPage } from './components/pages/Public/Stevner/Stevne/Internasjonal/PublicStevneInternasjonalPage';
import { RekorderPage } from './components/pages/Rekorder/RekorderPage';
import { RekorderFormPage } from './components/pages/Rekorder/RekorderFormPage';
import { PublicRekorderPage } from './components/pages/Public/Rekorder/PublicRekorderPage';

export const Paths = {
  base: '/admin',
  innlogging: '/admin/innlogging',
  personer: {
    base: '/admin/personer',
  },
  klubber: {
    base: '/admin/klubber',
  },
  stevner: {
    base: '/admin/stevner',
    create: '/admin/stevner/opprett',
    edit: '/admin/stevner/:id',
    upload: '/admin/stevner/opplasting',
    show: '/admin/stevner/:id/vis',
    useShow: (stevneId: number) => `/admin/stevner/${stevneId}/vis`,
    useEdit: (stevneId: number) => `/admin/stevner/${stevneId}`,
    internasjonal: {
      create: '/admin/stevner/internasjonal/opprett',
      edit: '/admin/stevner/internasjonal/:id',
      useEdit: (id: number) => `/admin/stevner/internasjonal/${id}`,
    },
  },
  rapporter: {
    base: '/admin/rapporter',
    loeftere: '/admin/rapporter/loeftere',
    ranking: '/admin/rapporter/ranking',
    dommere: '/admin/rapporter/dommere',
  },
  users: {
    base: '/admin/brukere',
  },
  rekorder: {
    base: '/admin/rekorder',
    create: '/admin/rekorder/opprett',
  },
};

export const PublicPaths = {
  base: '/',
  stevner: {
    base: '/stevner',
    show: '/stevner/:id',
    useShow: (stevneId: number) => `/stevner/${stevneId}`,
    internasjonal: {
      show: '/stevner/internasjonal/:id',
      useShow: (stevneId: number) => `/stevner/internasjonal/${stevneId}`,
    },
  },
  resultater: {
    base: '/resultater',
  },
  rekorder: {
    base: '/rekorder',
  },
  users: {
    create: '/brukere/opprett/:registrationKey',
  },
};

export function AppRoutes() {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<RequireAuth />}>
        <Route path={Paths.base} element={<DashboardPage />} />
        <Route path={Paths.personer.base} element={<PersonerPage />} />
        <Route path={Paths.klubber.base} element={<KlubberPage />} />
        <Route path={Paths.stevner.base} element={<StevnerPage />} />
        <Route
          path={Paths.stevner.edit}
          element={
            <AuthorizeNvfAdmin>
              <StevneEditPage />
            </AuthorizeNvfAdmin>
          }
        />
        <Route
          path={Paths.stevner.upload}
          element={<StevneUploadPreviewPage />}
        />
        <Route path={Paths.stevner.show} element={<StevneViewPage />} />

        <Route
          path={Paths.stevner.internasjonal.create}
          element={<StevnerInternasjonalPage />}
        />
        <Route
          path={Paths.stevner.internasjonal.edit}
          element={<StevnerInternasjonalEditPage />}
        />

        <Route path={Paths.rekorder.base} element={<RekorderPage />} />
        <Route path={Paths.rekorder.create} element={<RekorderFormPage />} />

        <Route path={Paths.rapporter.base} element={<RapporterPage />}>
          <Route path={Paths.rapporter.loeftere} element={<LoefterePage />} />
          <Route path={Paths.rapporter.ranking} element={<RankingPage />} />
          <Route path={Paths.rapporter.dommere} element={<DommerePage />} />
        </Route>

        <Route path={Paths.users.base} element={<UsersPage />} />
      </Route>
      <Route path={Paths.innlogging} element={<LoginPage />} />

      <Route
        path={PublicPaths.users.create}
        element={<UsersRegistrationPage />}
      />
      <Route path={PublicPaths.stevner.base} element={<PublicStevnerPage />} />
      <Route path={PublicPaths.stevner.show} element={<PublicStevnePage />} />
      <Route
        path={PublicPaths.stevner.internasjonal.show}
        element={<PublicStevneInternasjonalPage />}
      />

      <Route
        path={PublicPaths.resultater.base}
        element={<PublicRankingPage />}
      />
      <Route
        path={PublicPaths.base}
        element={<Navigate to={PublicPaths.stevner.base} />}
      />
      <Route
        path={PublicPaths.rekorder.base}
        element={<PublicRekorderPage />}
      />
    </Routes>
  );
}
