import { useState } from 'react';
import { DommerApi } from 'src/ajax/dommer.api';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { Stevne } from 'src/models/Stevne';
import { StevnerTable } from '../../Stevner/StevnerTable';
import { DommerStevnerForm } from './DommerStevnerForm';
import { DommerStevnerFormState } from './DommerStevnerForm.schema';
import { Dommer } from 'src/models/Funksjonaer';
import { useNavigate } from 'react-router';
import { Paths } from 'src/Routes';

type Props = {
  isOpen: boolean;
  dommere: Dommer[];
  onClose: () => void;
};

export function DommerStevnerModal({ isOpen, dommere, onClose }: Props) {
  const navigate = useNavigate();
  const [dommerStevner, setDommerStevner] = useState<Stevne[]>([]);
  const [isLoadingStevner, setIsLoadingStevner] = useState(false);

  async function fetchDommerStevner(
    personId: number,
    fromDate: Date,
    toDate: Date
  ) {
    setIsLoadingStevner(true);
    const response = await DommerApi.getDommerStevner(
      personId,
      fromDate,
      toDate
    );

    if (response.ok) {
      setDommerStevner(response.data);
    }

    setIsLoadingStevner(false);
  }

  const handleSubmit = (state: DommerStevnerFormState) => {
    fetchDommerStevner(state.personId!!, state.fromDate, state.toDate);
  };

  const handleClose = () => {
    setDommerStevner([]);
    onClose();
  };

  const navigateToStevne = (stevne: Stevne) => {
    navigate(Paths.stevner.useShow(stevne.id));
  };

  return (
    <Modal open={isOpen} onClose={handleClose} width={720}>
      <ModalHeader>
        <Header2>Hent stevner for dommer</Header2>
      </ModalHeader>
      <ModalBody className="flex gap-8">
        <DommerStevnerForm
          dommere={dommere}
          isSubmitting={isLoadingStevner}
          onSubmit={handleSubmit}
        />

        <StevnerTable
          stevner={dommerStevner}
          isLoadingStevner={false}
          onRowClick={navigateToStevne}
        />
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
}
