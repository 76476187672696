import { Control } from 'react-hook-form';
import { FormSelect } from 'src/components/shared/Form/FormSelect';
import {
  Alderskategori,
  vektklasserKvinner,
  vektklasserMenn,
  vektklasserUngdomKvinner,
  vektklasserUngdomMenn,
} from 'src/models/Stevne';
import { RekordFormState } from './RekorderForm.types';

type RekorderFormVektklasseProps = {
  control: Control<RekordFormState>;
  alderskategori: Nullable<Alderskategori>;
};

export function RekorderFormVektklasseSelect({
  control,
  alderskategori,
}: RekorderFormVektklasseProps) {
  const vektklasseUngdomKvinneOptions = vektklasserUngdomKvinner.map(
    vektklasse => ({
      id: vektklasse,
      label: vektklasse,
    })
  );
  const vetklasseKvinneOptions = vektklasserKvinner.map(vektklasse => ({
    id: vektklasse,
    label: vektklasse,
  }));
  const vektklasseUngdomMannOptions = vektklasserUngdomMenn.map(vektklasse => ({
    id: vektklasse,
    label: vektklasse,
  }));
  const vektklasseMannOptions = vektklasserMenn.map(vektklasse => ({
    id: vektklasse,
    label: vektklasse,
  }));

  let vektklasseOptions = null;

  if (!!alderskategori) {
    const isMann =
      alderskategori === 'UM' ||
      alderskategori === 'JM' ||
      alderskategori === 'SM' ||
      alderskategori.startsWith('M');

    if (isMann) {
      if (alderskategori === 'UM') {
        vektklasseOptions = vektklasseUngdomMannOptions;
      } else {
        vektklasseOptions = vektklasseMannOptions;
      }
    } else {
      if (alderskategori === 'UK') {
        vektklasseOptions = vektklasseUngdomKvinneOptions;
      } else {
        vektklasseOptions = vetklasseKvinneOptions;
      }
    }
  }

  return (
    <FormSelect
      name="vektklasse"
      label="Vektklasse"
      options={vektklasseOptions || []}
      disabled={vektklasseOptions === null}
      disabledText="Velg kategori først"
      control={control}
    />
  );
}
