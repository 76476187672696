import { Dommer } from 'src/models/Funksjonaer';
import useSWR from 'swr';
import { ApiError } from '../ajax';
import { Kjonn } from 'src/models/Person';

export function useDommere() {
  const requestUri = '/dommere/';
  const { data, error } = useSWR<Dommer[], ApiError>(requestUri);

  return {
    dommere: data ?? [],
    isLoading: !data && !error,
    error: error,
  };
}

export type DommerStatistics = {
  dommergrad: string;
  kursdato: Nullable<string>;
  personFodselsdato: string;
  personKjonn: Kjonn;
  personKlubbName: string;
  personFirstName: string;
  personLastName: string;
  personNvfId: string;
  stevnerCountLastYear: number;
  stevnerCountThisYear: number;
};

export function useDommerStatistics(klubbId?: number) {
  let requestUri = '/dommere/statistics';

  if (klubbId) {
    requestUri += `?klubb_id=${klubbId}`;
  }

  const { data, error } = useSWR<DommerStatistics[], ApiError>(requestUri);

  return {
    dommerStatistics: data ?? [],
    isLoading: !data && !error,
    error: error,
  };
}
