import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { slideUp, wiggle } from './modal.keyframes';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  max-width: 100vw;
  max-height: 90%;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 0.5rem;
  animation: ${slideUp} 300ms ease-out;

  &.wiggle {
    animation: ${slideUp} 300ms ease-out, ${wiggle} 400ms ease-out;
  }
`;

export const ModalHeader = styled.section`
  padding: 3rem 3rem 1.5rem;
  border-bottom: 1px solid var(--primary-color20);
`;

export const ModalBody = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;

  padding: 1.5rem 3rem;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 3rem;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 500;
`;
