import {
  CreateInternasjonaltStevne,
  CreateInternasjonaltStevneResultat,
  InternasjonaltStevne,
  InternasjonaltStevneResultat,
  UpdateInternasjonaltStevne,
  UpdateInternasjonaltStevneResultat,
} from 'src/models/InternasjonaltStevne';
import { formatToApiDate } from 'src/utils/date';
import {
  StevnerInternasjonalFormState,
  StevnerInternasjonalResultatFormState,
} from './StevnerInternasjonalForm.types';

export function mapStevnerInternasjonalFormStateToCreateInternasjonaltStevne(
  state: StevnerInternasjonalFormState
): CreateInternasjonaltStevne {
  return {
    ...state,
    startdato: formatToApiDate(state.startdato),
    sluttdato: formatToApiDate(state.sluttdato),
    resultater: state.resultater.map(
      mapStevnerInternasjonalResultatFormStateToCreateInternasjonaltStevneResultat
    ),
  };
}

function mapStevnerInternasjonalResultatFormStateToCreateInternasjonaltStevneResultat(
  state: StevnerInternasjonalResultatFormState
): CreateInternasjonaltStevneResultat {
  return {
    ...state,
    personId: state.personId!!,
    kroppsvekt: state.kroppsvekt!!,
    dato: formatToApiDate(state.dato),
    rykk1: state.rykk1!!,
    rykk2: state.rykk2 || null,
    rykk3: state.rykk3 || null,
    stot1: state.stot1 || null,
    stot2: state.stot2 || null,
    stot3: state.stot3 || null,
  };
}

export function mapStevnerInternasjonalFormStateToUpdateInternasjonaltStevne(
  state: StevnerInternasjonalFormState
): UpdateInternasjonaltStevne {
  return {
    ...state,
    startdato: formatToApiDate(state.startdato),
    sluttdato: formatToApiDate(state.sluttdato),
    resultater: state.resultater.map(
      mapStevnerInternasjonalResultatFormStateToUpdateInternasjonaltStevneResultat
    ),
  };
}

function mapStevnerInternasjonalResultatFormStateToUpdateInternasjonaltStevneResultat(
  state: StevnerInternasjonalResultatFormState
): UpdateInternasjonaltStevneResultat {
  return {
    ...state,
    personId: state.personId!!,
    kroppsvekt: state.kroppsvekt!!,
    dato: formatToApiDate(state.dato),
    rykk1: state.rykk1!!,
    rykk2: state.rykk2 || null,
    rykk3: state.rykk3 || null,
    stot1: state.stot1 || null,
    stot2: state.stot2 || null,
    stot3: state.stot3 || null,
  };
}

export function mapInternasjonalStevneToStevnerInternasjonalFormState(
  internasjonaltStevne: InternasjonaltStevne
): StevnerInternasjonalFormState {
  return {
    navn: internasjonaltStevne.navn,
    sted: internasjonaltStevne.sted,
    startdato: new Date(internasjonaltStevne.startdato),
    sluttdato: new Date(internasjonaltStevne.sluttdato),
    resultater: internasjonaltStevne.resultater.map(
      mapInternasjonaltStevneResultatToStevnerInternasjonalResultatFormState
    ),
  };
}

function mapInternasjonaltStevneResultatToStevnerInternasjonalResultatFormState(
  internasjonaltResultat: InternasjonaltStevneResultat
): StevnerInternasjonalResultatFormState {
  return {
    personId: internasjonaltResultat.personId,
    kroppsvekt: internasjonaltResultat.kroppsvekt,
    dato: new Date(internasjonaltResultat.dato),
    rykk1: internasjonaltResultat.rykk1,
    rykk2: internasjonaltResultat.rykk2,
    rykk3: internasjonaltResultat.rykk3,
    stot1: internasjonaltResultat.stot1,
    stot2: internasjonaltResultat.stot2,
    stot3: internasjonaltResultat.stot3,
  };
}
