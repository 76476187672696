import { List, ListItem, ListItemText } from '@mui/material';
import { red } from '@mui/material/colors';
import styled from 'styled-components';

type ValidationErrorsProps = {
  messages: string[];
};

export function ValidationErrors({ messages }: ValidationErrorsProps) {
  return (
    <StyledValidationErrors>
      <List sx={{ listStyleType: 'disc', pl: 4 }}>
        {messages.map((message, index) => (
          <ListItem key={index} sx={{ display: 'list-item' }}>
            <ListItemText>{message}</ListItemText>
          </ListItem>
        ))}
      </List>
    </StyledValidationErrors>
  );
}

const StyledValidationErrors = styled.div`
  max-width: 75ch;
  margin-bottom: 2rem;
  background-color: ${red[50]};
`;
