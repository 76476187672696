export type Klubb = {
  id: number;
  navn: string;
  fulltNavn: string;
  forkortelse: string;
};

export function mapKlubbIdToReadable(klubbId: number, klubber: Klubb[]) {
  const klubb = klubber.find(klubb => klubb.id === klubbId);

  return klubb?.navn ?? '';
}
