import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { Pulje } from 'src/models/Stevne';
import { FunksjonaerList } from './FunksjonaerList';
import { ResultatList } from './ResultatList';

const StevnePuljeTab = {
  Resultater: 0,
  Funksjonaerer: 1,
};

type StevnePuljeProps = {
  pulje: Pulje;
};

export function StevnePulje({ pulje }: StevnePuljeProps) {
  const [tab, setTab] = useState(StevnePuljeTab.Resultater);

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={onTabChange} aria-label="Product tabs">
          <Tab label="Resultater" />
          <Tab label="Funksjonærer" />
        </Tabs>
      </Box>
      <TabPanel index={StevnePuljeTab.Resultater} value={tab}>
        <ResultatList resultater={pulje.resultatList} />
      </TabPanel>
      <TabPanel index={StevnePuljeTab.Funksjonaerer} value={tab}>
        <FunksjonaerList funksjonaerer={pulje.funksjonaerList} />
      </TabPanel>
    </Paper>
  );
}
