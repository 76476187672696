import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { useSort } from 'src/hooks/useSort';
import { Klubb } from 'src/models/Klubb';
import { sortByProperty } from 'src/utils/sort';

type KlubberTableProps = {
  klubber: Klubb[];
  onRowClick: (klubb: Klubb) => void;
  onDeleteClick: (klubb: Klubb) => void;
};

export const KlubberTable = ({
  klubber,
  onRowClick,
  onDeleteClick,
}: KlubberTableProps) => {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<Klubb>('fulltNavn');

  const sortedKlubber = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(klubber, sortedBy, sortDirection);
    }

    return klubber;
  }, [klubber, sortedBy, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('fulltNavn')}
                direction={getDirection('fulltNavn')}
                onClick={() => requestSort('fulltNavn')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('forkortelse')}
                direction={getDirection('forkortelse')}
                onClick={() => requestSort('forkortelse')}
              >
                Forkortelse
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedKlubber.map(klubb => (
            <TableRow
              key={klubb.id}
              onClick={() => onRowClick(klubb)}
              hover
              style={{ cursor: 'pointer' }}
            >
              <TableCell>{klubb.fulltNavn}</TableCell>
              <TableCell>{klubb.forkortelse}</TableCell>
              <TableCell align="center" sx={{ width: '80px' }}>
                <IconButton
                  onClick={event => {
                    event.stopPropagation();
                    onDeleteClick(klubb);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
