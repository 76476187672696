import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { PublicPulje } from 'src/models/Stevne';
import { PublicFunksjonaerList } from './PublicFunksjonaerList';
import { PublicResultatList } from './PublicResultatList';

const PublicPuljeTab = {
  Resultater: 0,
  Funksjonaerer: 1,
};

type PublicStevnePuljeProps = {
  pulje: PublicPulje;
};

export function PublicStevnePulje({ pulje }: PublicStevnePuljeProps) {
  const [tab, setTab] = useState(PublicPuljeTab.Resultater);

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  return (
    <Paper className="h100">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={onTabChange} aria-label="Product tabs">
          <Tab label="Resultater" />
          <Tab label="Funksjonærer" />
        </Tabs>
      </Box>
      <TabPanel index={PublicPuljeTab.Resultater} value={tab}>
        <PublicResultatList resultater={pulje.resultater} />
      </TabPanel>
      <TabPanel index={PublicPuljeTab.Funksjonaerer} value={tab}>
        <PublicFunksjonaerList funksjonaerer={pulje.funksjonaerer} />
      </TabPanel>
    </Paper>
  );
}
