import { PublicInternasjonaltStevneResultat } from './InternasjonaltStevne';
import { Klubb } from './Klubb';
import { Person, PublicPerson } from './Person';
import {
  Alderskategori,
  Kategori,
  PublicResultat,
  Resultat,
  Vektklasse,
  VektklasseType,
} from './Stevne';

export type RekordNiva = 'NASJONAL' | 'REGION' | 'KLUBB';

export const rekordOvelser = ['RYKK', 'STOT', 'SAMMENLAGT'] as const;
export type RekordOvelse = (typeof rekordOvelser)[number];
export const RekordOvelseToReadable: { [key in RekordOvelse]: string } = {
  RYKK: 'Rykk',
  STOT: 'Støt',
  SAMMENLAGT: 'Sammenlagt',
};

export type Rekord = {
  id: number;
  kategori: Kategori;
  vektklasse: Vektklasse;
  resultat: Nullable<PublicResultat>;
  internasjonaltResultat: Nullable<PublicInternasjonaltStevneResultat>;
  person: Nullable<PublicPerson>;
  klubb: Nullable<Klubb>;
  ovelse: RekordOvelse;
  ovelseLoft: number;
  navn: string;
  klubbnavn: string;
  sted: string;
  dato: Date;
  niva: RekordNiva;
  isStandard: boolean;
};

export type RekordList = {
  id: number;
  navn: string;
  klubbnavn: string;
  kategori: Kategori;
  vektklasse: Vektklasse;
  sted: string;
  dato: Date;
  ovelse: RekordOvelse;
  ovelseLoft: number;
  isStandard: boolean;
}

export type VektklasseRekord = {
  vektklasseNavn: string;
  vektklasseVekt: number;
  kategoriForkortelse: Alderskategori;
  rykk: VektklasseRekordLoft | null;
  stot: VektklasseRekordLoft | null;
  sammenlagt: VektklasseRekordLoft | null;
};

export type VektklasseRekordLoft = {
  navn: string;
  klubbnavn: string;
  sted: string;
  dato: Date;
  ovelseLoft: number;
  isStandard: boolean;
};

export type RekordInput = {
  kategoriForkortelse: Alderskategori;
  vektklasseNavn: VektklasseType;
  personId: Nullable<number>;
  klubbId: Nullable<number>;
  ovelse: RekordOvelse;
  ovelseLoft: number;
  navn: string;
  klubbnavn: string;
  sted: string;
  dato: string;
  isStandard: boolean;
};

export type RekordResultatInput = {
  nasjonaltResultatId: Nullable<number>;
  internasjonaltResultatId: Nullable<number>;
  ovelse: RekordOvelse;
};

export type RekordCandidate = {
  person: Person;
  klubb: Klubb;
  kategori: Kategori;
  vektklasse: Vektklasse;
  ovelse: RekordOvelse;
  ovelseLoft: number;
  resultat: Resultat;
  niva: RekordNiva;
};
