export type Stevnetype = {
    id: number;
    navn: string;
    aktiv: boolean;
}

export function mapStevnetypeIdToReadable(stevnetypeId: number, stevnetyper: Stevnetype[]) {
    const stevnetype = stevnetyper.find(stevnetype => stevnetype.id === stevnetypeId);
  
    return stevnetype?.navn ?? '';
  }