import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { ApiError } from './ajax/ajax';
import { Header } from './components/shared/Navigation/Header';
import { Sidebar } from './components/shared/Navigation/Sidebar';
import { ProvideAuth } from './context/AuthContext';
import { KlubbProvider } from './context/KlubbContext';
import { Klubb } from './models/Klubb';
import { KlubbAdminUser, NvfAdminUser, UserType } from './models/User';
import { Paths } from './Routes';

type UserResponse = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  isSuperuser: boolean;
  username: string;
  canAccessKlubb: Klubb[];
};

function mapToNvfAdmin(user: UserResponse): NvfAdminUser {
  const { canAccessKlubb, ...rest } = user;

  return { ...rest, userType: UserType.NvfAdmin };
}

function mapToKlubbAdmin(user: UserResponse): KlubbAdminUser {
  const { canAccessKlubb, ...rest } = user;

  return { ...rest, userType: UserType.KlubbAdmin, klubb: canAccessKlubb[0] };
}

export const RequireAuth = () => {
  const { data: user, error } = useSWR<UserResponse, ApiError>('/users/me/');

  const location = useLocation();

  const isLoading = !user && !error;

  if (error?.status === 401) {
    return (
      <Navigate
        to={Paths.innlogging}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  if (isLoading) return <></>;

  return (
    <ProvideAuth
      user={
        user!.userType === UserType.NvfAdmin
          ? mapToNvfAdmin(user!)
          : mapToKlubbAdmin(user!)
      }
    >
      <KlubbProvider>
        <Header />
        <Sidebar />
        <Outlet />
      </KlubbProvider>
    </ProvideAuth>
  );
};
