import { Klubb } from 'src/models/Klubb';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export function useRegistrationKlubb(registrationKey: string) {
  const requestUri = `/user/registration/${registrationKey}/`;
  const { data, error } = useSWR<Klubb, ApiError>(requestUri);

  return {
    klubb: data,
    isLoading: !data && !error,
    error: error,
  };
}
