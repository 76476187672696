import { useState } from 'react';
import { SortDirection } from 'src/utils/sort';

export function useSort<T>(
  defaultSortedBy: Nullable<NestedKeyOf<T>> = null,
  defaultSortDirection: SortDirection = SortDirection.Ascending
) {
  const [sortedBy, setSortedBy] =
    useState<Nullable<NestedKeyOf<T>>>(defaultSortedBy);
  const [sortDirection, setSortDirection] =
    useState<SortDirection>(defaultSortDirection);

  const requestSort = (headId: NestedKeyOf<T>) => {
    const switchedDirection =
      sortDirection === SortDirection.Descending
        ? SortDirection.Ascending
        : SortDirection.Descending;

    // If sorting new column - always start with ascending
    const direction = isActive(headId)
      ? switchedDirection
      : SortDirection.Ascending;

    setSortedBy(headId);
    setSortDirection(direction);
  };

  const isActive = (headId: string) => {
    return sortedBy === headId;
  };

  const getDirection = (headId: string) => {
    return isActive(headId) ? sortDirection : undefined;
  };

  return {
    sortedBy,
    sortDirection,
    isActive,
    requestSort,
    getDirection,
  };
}
