import { Fab, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { Paths } from 'src/Routes';
import { postRequest, putRequest } from 'src/ajax/ajax';
import { useDommere } from 'src/ajax/hooks/useDommere';
import { useFunksjonaerroller } from 'src/ajax/hooks/useFunksjonaerroller';
import { usePersoner } from 'src/ajax/hooks/usePersoner';
import { useStevne } from 'src/ajax/hooks/useStevner';
import { useStevnetyper } from 'src/ajax/hooks/useStevnetyper';
import { ValidationErrors } from 'src/components/shared/Error/ValidationErrors';
import { Loader } from 'src/components/shared/Loader/Loader';
import { Page } from 'src/components/shared/Page/Page';
import { LeadType } from 'src/components/shared/Typography/LeadType';
import { useAuth } from 'src/context/AuthContext';
import { useKlubb } from 'src/context/KlubbContext';
import { RekordCandidate } from 'src/models/Rekord';
import { StevneResponse } from 'src/models/Stevne';
import { mutate } from 'swr';
import {
  StevnerFormState,
  StevnerUploadProtokollResponse,
} from './Stevner.types';
import { StevnerForm } from './StevnerForm/StevnerForm';
import { StevnerUploadWarningModal } from './StevnerForm/StevnerFormWarningModal';
import {
  mapStevneDetailToStevneFormState,
  mapStevneUploadToStevneFormState,
} from './Stevner.mappers';
import { RekorderApi } from 'src/ajax/rekorder.api';
import { RekordCandidatesModal } from 'src/components/shared/Rekorder/RekordCandidatesModal';
import { mapRekordCandidateToRekordInput } from 'src/components/shared/Rekorder/rekord.mappers';

export const StevneEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { klubber } = useKlubb();
  const { stevnetyper } = useStevnetyper();
  const { dommere } = useDommere();
  const { personer } = usePersoner();
  const { funksjonaerroller } = useFunksjonaerroller();
  const [showRekordModal, setShowRekordModal] = useState(false);
  const [showStevneWarnings, setShowStevneWarnings] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState<
    string[]
  >([]);
  const [rekordCandidates, setRekordCandidates] = useState<RekordCandidate[]>(
    []
  );

  const { id: idParam } = useParams();

  const { stevne, isLoadingStevne } = useStevne(Number(idParam) ?? undefined);

  const locationState = location.state as StevnerUploadProtokollResponse;

  useEffect(() => {
    setShowStevneWarnings(locationState?.warnings?.length > 0);
  }, [locationState?.warnings]);

  const onCreateStevne = async (data: StevnerFormState) => {
    const response = await postRequest<StevneResponse>('/stevne/', data);

    if (response.ok) {
      showSuccessNotification('Stevne opprettet');

      if (response.data.rekordCandidates.length > 0) {
        setRekordCandidates(response.data.rekordCandidates);
        setShowRekordModal(true);
        return;
      }

      navigate(Paths.stevner.base);
    } else {
      const errorResponse = response.error?.response;
      const messages = errorResponse?.data;
      if (errorResponse?.status === 400 && messages?.length > 0) {
        setValidationErrorMessages(messages);
      } else {
        showErrorNotification('Kunne ikke opprette stevne');
      }
    }
  };

  const onEditStevne = async (data: StevnerFormState) => {
    const response = await putRequest<StevneResponse>(
      `/stevne/${idParam}/`,
      data
    );

    if (response.ok) {
      showSuccessNotification('Stevne oppdatert');
      
      if (response.data.rekordCandidates.length > 0) {
        setRekordCandidates(response.data.rekordCandidates);
        setShowRekordModal(true);
        return;
      }
      
      mutate(`/stevne/${idParam}/`, null, false);
      navigate(Paths.stevner.base);
    } else {
      const errorResponse = response.error?.response;
      const messages = errorResponse?.data;
      if (errorResponse?.status === 400 && messages?.length > 0) {
        setValidationErrorMessages(messages);
      } else {
        showErrorNotification('Kunne ikke oppdatere stevne');
      }
    }
  };

  const onSaveStevne = (data: StevnerFormState) => {
    if (idParam) {
      onEditStevne(data);
    } else {
      onCreateStevne(data);
    }
  };

  const createRekorder = async (candidates: RekordCandidate[]) => {
    const rekordInputs = candidates.map(mapRekordCandidateToRekordInput);

    const response = await RekorderApi.createRekordBatch(rekordInputs);

    if (response.ok) {
      showSuccessNotification('Rekorder opprettet');
      mutate(`/stevne/${idParam}/`, null, false);
      navigate(Paths.stevner.base);
    } else {
      showErrorNotification('Kunne ikke opprette rekorder');
    }
  };

  const cancelCreateRekorder = () => {
    setShowRekordModal(false);
    mutate(`/stevne/${idParam}/`, null, false);
    navigate(Paths.stevner.base);
  }

  const isLoading =
    !stevnetyper ||
    !dommere ||
    !personer ||
    !funksjonaerroller ||
    isLoadingStevne;

  let initialStevne = undefined;

  if (stevne) initialStevne = mapStevneDetailToStevneFormState(stevne);

  if (locationState?.stevne)
    initialStevne = mapStevneUploadToStevneFormState(locationState.stevne);

  const hasWarnings = locationState?.warnings.length > 0;

  return (
    <Page>
      {validationErrorMessages.length > 0 && (
        <ValidationErrors messages={validationErrorMessages} />
      )}

      {isLoading ? (
        <div className="flex flexcol align-center">
          <Loader active className="mb-4" />
          <LeadType>Laster stevne</LeadType>
        </div>
      ) : (
        <>
          <StevnerForm
            initialStevne={initialStevne}
            user={user}
            klubber={klubber}
            stevnetyper={stevnetyper}
            dommere={dommere}
            personer={personer}
            funksjonaerroller={funksjonaerroller}
            onSubmit={onSaveStevne}
          />

          {hasWarnings && (
            <StyledWarningButton
              variant="extended"
              onClick={() => setShowStevneWarnings(true)}
              size="medium"
            >
              Vis merknader fra opplasting
            </StyledWarningButton>
          )}

          <RekordCandidatesModal
            open={showRekordModal}
            candidates={rekordCandidates}
            onConfirm={createRekorder}
            onClose={cancelCreateRekorder}
          />

          <StevnerUploadWarningModal
            open={showStevneWarnings}
            warnings={locationState?.warnings || []}
            onClose={() => setShowStevneWarnings(false)}
          />
        </>
      )}
    </Page>
  );
};

export const StyledWarningButton = styled(Fab)`
  position: fixed;
  bottom: 24px;
  right: 32px;
  z-index: 1;
`;
