import * as Yup from 'yup';
import { RekorderResultatFormState } from './RekorderResultatForm.types';

export const rekorderResultatSchema = Yup.object<
  Record<keyof RekorderResultatFormState, Yup.AnySchema>
>({
  nasjonaltResultatId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  internasjonaltResultatId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  ovelse: Yup.string().nullable().required('Øvelse er påkrevd'),
});
