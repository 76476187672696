import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Funksjonaerrolle,
  mapFunksjonaerrolleIdToReadable,
} from 'src/models/Funksjonaer';
import { mapPersonToReadable, Person } from 'src/models/Person';

type StevnerFormFunksjonaerProps = {
  index: number;
  puljeIndex: number;
  personer: Person[];
  funksjonaerroller: Funksjonaerrolle[];
  handleRemoveFunksjonaer: (index: number) => void;
};

export function StevnerFormFunksjonaer({
  index,
  puljeIndex,
  personer,
  funksjonaerroller,
  handleRemoveFunksjonaer,
}: StevnerFormFunksjonaerProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex gap-8">
      <Controller
        name={`puljeList.[${puljeIndex}].funksjonaerList.[${index}].personId`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            options={personer}
            getOptionLabel={person => mapPersonToReadable(person)}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                label="Funksjonær"
                error={
                  !!errors.puljeList?.[puljeIndex]?.funksjonaerList?.[index]
                    ?.personId
                }
                helperText={
                  errors.puljeList?.[puljeIndex]?.funksjonaerList?.[index]
                    ?.personId?.message
                }
              />
            )}
            value={personer.find(person => person.id === value)}
            onChange={(_, person) => {
              onChange(person ? person.id : null);
            }}
          />
        )}
      />

      <Controller
        name={`puljeList.[${puljeIndex}].funksjonaerList.[${index}].funksjonaerrolleId`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            options={funksjonaerroller.map(
              funksjonaerrolle => funksjonaerrolle.id
            )}
            getOptionLabel={option =>
              mapFunksjonaerrolleIdToReadable(option, funksjonaerroller)
            }
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                label="Funksjonærrolle"
                error={
                  !!errors.puljeList?.[puljeIndex]?.funksjonaerList?.[index]
                    ?.funksjonaerrolleId
                }
                helperText={
                  errors.puljeList?.[puljeIndex]?.funksjonaerList?.[index]
                    ?.funksjonaerrolleId?.message
                }
              />
            )}
            value={value === undefined ? null : value}
            onChange={(_, item) => {
              onChange(item);
            }}
          />
        )}
      />

      <IconButton
        style={{ alignSelf: 'center' }}
        onClick={event => {
          event.stopPropagation();
          handleRemoveFunksjonaer(index);
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}
