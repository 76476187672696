import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Loader } from 'src/components/shared/Loader/Loader';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useSort } from 'src/hooks/useSort';
import { Person, mapKjonnToReadable } from 'src/models/Person';
import { formatFromApiDate } from 'src/utils/date';
import { sortPersoner } from './Personer.utils';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';

type PersonerTableProps = {
  personer: Person[];
  isLoadingPersoner: boolean;
  onRowClick?: (person: Person) => void;
  onDeleteClick?: (person: Person) => void;
};

export const PersonerTable = ({
  personer,
  isLoadingPersoner,
  onRowClick,
  onDeleteClick,
}: PersonerTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const rowsPerPageOptions = [25, 50, 100];

  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<Person>('etternavn');

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visiblePersoner = useMemo(() => {
    const sortedPersoner = sortPersoner(personer, sortDirection, sortedBy);

    return sortedPersoner.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [page, personer, rowsPerPage, sortDirection, sortedBy]);

  const allowedToDelete = !!onDeleteClick;

  if (personer.length === 0 && !isLoadingPersoner) {
    return (
      <Paragraph
        className="mt-2"
        style={{ fontStyle: 'italic', color: '#494949' }}
      >
        Ingen personer er opprettet
      </Paragraph>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('nvfId')}
                direction={getDirection('nvfId')}
                onClick={() => requestSort('nvfId')}
              >
                NVF-ID
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('etternavn')}
                direction={getDirection('etternavn')}
                onClick={() => requestSort('etternavn')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('fodselsdato')}
                direction={getDirection('fodselsdato')}
                onClick={() => requestSort('fodselsdato')}
              >
                Fødselsdato
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('kjonn')}
                direction={getDirection('kjonn')}
                onClick={() => requestSort('kjonn')}
              >
                Kjønn
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('klubb.navn')}
                direction={getDirection('klubb.navn')}
                onClick={() => requestSort('klubb.navn')}
              >
                Klubb
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('dommergrad.forkortelse')}
                direction={getDirection('dommergrad.forkortelse')}
                onClick={() => requestSort('dommergrad.forkortelse')}
              >
                Dommergrad
              </TableSortLabel>
            </TableCellHeader>
            {allowedToDelete && <TableCellHeader />}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingPersoner ? (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                <Loader active size={40} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {visiblePersoner.map(person => (
                <TableRow
                  key={person.nvfId}
                  onClick={onRowClick ? () => onRowClick(person) : undefined}
                  hover={!!onRowClick}
                  style={{ cursor: !!onRowClick ? 'pointer' : 'inherit' }}
                >
                  <TableCell>{person.nvfId}</TableCell>
                  <TableCell>{`${person.etternavn}, ${person.fornavn}`}</TableCell>
                  <TableCell>{formatFromApiDate(person.fodselsdato)}</TableCell>
                  <TableCell>{mapKjonnToReadable(person.kjonn)}</TableCell>
                  <TableCell>{person.klubb.navn}</TableCell>
                  <TableCell>{person.dommergrad?.forkortelse || '-'}</TableCell>
                  {allowedToDelete && (
                    <TableCell align="center" sx={{ width: '80px' }}>
                      <IconButton
                        size="small"
                        onClick={event => {
                          event.stopPropagation();
                          onDeleteClick(person);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={personer.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Antall pr. side:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} av ${count}`
        }
      />
    </TableContainer>
  );
};
