import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useSort } from 'src/hooks/useSort';
import { VektklasseRekord } from 'src/models/Rekord';
import { SortDirection, sortByProperty } from 'src/utils/sort';
import { RekorderTableRow } from './RekorderTableRow';

type RekorderTableProps = {
  rekorder: VektklasseRekord[];
  expandAll: boolean;
};

export function RekorderTable({ rekorder, expandAll }: RekorderTableProps) {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<VektklasseRekord>('vektklasseVekt', SortDirection.Ascending);

  const sortedRekorder = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(rekorder, sortedBy, sortDirection);
    }

    return rekorder;
  }, [rekorder, sortDirection, sortedBy]);

  if (rekorder.length === 0) {
    return (
      <Paragraph className="mt-10">
        <i>Ingen rekorder lagt til.</i>
      </Paragraph>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader />
            <TableCellHeader>
              <TableSortLabel
                active={isActive('vektklasseVekt')}
                direction={getDirection('vektklasseVekt')}
                onClick={() => requestSort('vektklasseVekt')}
              >
                Vektklasse
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('rykk.ovelseLoft')}
                direction={getDirection('rykk.ovelseLoft')}
                onClick={() => requestSort('rykk.ovelseLoft')}
              >
                Rykk
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('stot.ovelseLoft')}
                direction={getDirection('stot.ovelseLoft')}
                onClick={() => requestSort('stot.ovelseLoft')}
              >
                Støt
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('sammenlagt.ovelseLoft')}
                direction={getDirection('sammenlagt.ovelseLoft')}
                onClick={() => requestSort('sammenlagt.ovelseLoft')}
              >
                Sammenlagt
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRekorder.map(rekord => (
            <RekorderTableRow key={rekord.vektklasseNavn} rekord={rekord} initialOpen={expandAll} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
