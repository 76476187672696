import { Box, Button, Tab, Tabs, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { Dommer, Funksjonaerrolle } from 'src/models/Funksjonaer';
import { Person } from 'src/models/Person';
import styled from 'styled-components';
import { convertDateField } from './StevnerForm.schema';
import { StevnerFormFunksjonaer } from './StevnerFormFunksjonaer';
import { StevnerFormResultat } from './StevnerFormResultat';

const StevnerFormPuljeTab = {
  Resultater: 0,
  Funksjonaerer: 1,
};

const StevnerFormPuljeInfo = styled.div`
  display: flex;
  gap: 2rem;
`;

const FormDateInput = styled.div`
  max-width: 10rem;
`;

const FormNumberInput = styled.div`
  max-width: 8rem;
`;

type StevnerFormPuljeProps = {
  index: number;
  dommere: Dommer[];
  personer: Person[];
  funksjonaerroller: Funksjonaerrolle[];
};

export function StevnerFormPulje({
  index,
  dommere,
  personer,
  funksjonaerroller,
}: StevnerFormPuljeProps) {
  const [tab, setTab] = useState(StevnerFormPuljeTab.Resultater);

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    fields: funksjonaerList,
    append: appendFunksjonaer,
    remove: removeFunksjonaer,
  } = useFieldArray({
    control,
    name: `puljeList.[${index}].funksjonaerList`,
  });

  const {
    fields: resultatList,
    append: appendResultat,
    remove: removeResultat,
  } = useFieldArray({
    control,
    name: `puljeList.[${index}].resultatList`,
  });

  return (
    <>
      <StevnerFormPuljeInfo>
        <FormNumberInput>
          <Controller
            name={`puljeList.${index}.puljeNr`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="Puljenummer"
                error={!!errors.puljeList?.[index]?.puljeNr}
                helperText={errors.puljeList?.[index]?.puljeNr?.message}
                value={
                  field.value === undefined || field.value === null
                    ? ''
                    : field.value
                }
              />
            )}
          />
        </FormNumberInput>

        <FormDateInput>
          <Controller
            name={`puljeList.${index}.dato`}
            control={control}
            render={({ field }) => (
              <DesktopDatePicker
                {...field}
                label="Dato"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.puljeList?.[index]?.dato}
                    helperText={errors.puljeList?.[index]?.dato?.message}
                    value={field.value === undefined ? '' : field.value}
                  />
                )}
                value={convertDateField(field.value)}
                onChange={item => {
                  field.onChange(item);
                }}
              />
            )}
          />
        </FormDateInput>
      </StevnerFormPuljeInfo>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={onTabChange} aria-label="Product tabs">
          <Tab label="Resultater" />
          <Tab label="Funksjonærer" />
        </Tabs>
      </Box>
      <TabPanel index={StevnerFormPuljeTab.Resultater} value={tab}>
        <div className="flex flexcol gap-8">
          {resultatList.map((field, resultatIndex) => (
            <StevnerFormResultat
              key={field.id}
              index={resultatIndex}
              puljeIndex={index}
              personer={personer}
              handleRemoveResultat={removeResultat}
            />
          ))}

          <Button
            variant="outlined"
            onClick={() => appendResultat({})}
            style={{ width: '15rem' }}
          >
            Legg til resultat
          </Button>
        </div>
      </TabPanel>
      <TabPanel index={StevnerFormPuljeTab.Funksjonaerer} value={tab}>
        <div className="flex flexcol gap-8">
          {funksjonaerList.map((field, funksjonaerIndex) => (
            <StevnerFormFunksjonaer
              key={field.id}
              index={funksjonaerIndex}
              puljeIndex={index}
              personer={personer}
              funksjonaerroller={funksjonaerroller}
              handleRemoveFunksjonaer={removeFunksjonaer}
            />
          ))}

          <Button
            variant="outlined"
            onClick={() =>
              appendFunksjonaer({ dommerId: null, funksjonaerrolleId: null })
            }
            style={{ width: '15rem' }}
          >
            Legg til funksjonær
          </Button>

          <Paragraph style={{ color: '#d32f2f' }}>
            {errors.puljeList?.[index]?.funksjonaerList?.message}
          </Paragraph>
        </div>
      </TabPanel>
    </>
  );
}
