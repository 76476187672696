import { Person } from 'src/models/Person';
import { formatDate, formatFromApiDate } from 'src/utils/date';
import { SortDirection, sortByProperty } from 'src/utils/sort';

export function hasEquivalentPerson(
  fornavn: string,
  etternavn: string,
  fodselsdato: Date,
  personer: Person[]
): boolean {
  return personer.some(
    p =>
      p.fornavn === fornavn &&
      p.etternavn === etternavn &&
      formatDate(fodselsdato) === formatFromApiDate(p.fodselsdato)
  );
}

export function sortPersoner(
  personer: Person[],
  sortDirection: SortDirection,
  sortedBy: Nullable<NestedKeyOf<Person>>
) {
  if (sortedBy) {
    return sortByProperty(personer, sortedBy, sortDirection);
  }

  return personer;
}
