import { Funksjonaerrolle } from 'src/models/Funksjonaer';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export function useFunksjonaerroller() {
  const requestUri = '/funksjonaerrolle/';
  const { data, error } = useSWR<Funksjonaerrolle[], ApiError>(requestUri);

  return {
    funksjonaerroller: data ?? [],
    isLoading: !data && !error,
    error: error,
  };
}
