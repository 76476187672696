import { RankingSearchFormState } from 'src/components/shared/RankingSearch/RankingSearchForm.types';
import {
  KategoriType,
  PublicRankingResultat,
  RankingResultat,
} from 'src/models/Stevne';
import { postRequest, publicPostRequest } from './ajax';
import { KLUBBER_URI } from './hooks/useKlubber';

const RESULTATER_URI = '/resultat';

type GenderOption = 'ALL' | 'M' | 'K';

type PublicRankingFilter = {
  fromDate: Date;
  toDate: Date;
  genderOption: GenderOption;
  kategorier: KategoriType[];
  vektklasse: Nullable<string>;
  onlyBestResultatForPerson: boolean;
};

export const ResultaterApi = {
  searchKlubbResultater: (klubbId: number, state: RankingSearchFormState) => {
    return postRequest<RankingResultat[]>(
      `${KLUBBER_URI}${klubbId}/resultater/`,
      state
    );
  },

  searchResultater: (state: RankingSearchFormState) => {
    return postRequest<RankingResultat[]>(`${RESULTATER_URI}/ranking/`, state);
  },

  searchPublicResultater: (filter: PublicRankingFilter) => {
    return publicPostRequest<PublicRankingResultat[]>(
      `public/ranking/`,
      filter
    );
  },
};
