import { TableCell } from '@mui/material';
import { VektklasseRekordLoft } from 'src/models/Rekord';
import { MISSING_VALUE } from './RekorderTableRow';

type Props = {
  rekordLoeft: VektklasseRekordLoft | null;
};

export function RekorderTableRowCellLoeft({ rekordLoeft }: Props) {
  if (rekordLoeft === null) {
    return <TableCell>{MISSING_VALUE}</TableCell>;
  }

  return (
    <TableCell className={rekordLoeft.isStandard ? 'italic' : ''}>
      {rekordLoeft.ovelseLoft}
    </TableCell>
  );
}
