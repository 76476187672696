import AssessmentIcon from '@mui/icons-material/Assessment';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Page } from 'src/components/shared/Page/Page';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { useAuth } from 'src/context/AuthContext';
import { isKlubbAdmin } from 'src/models/User';
import { Paths } from 'src/Routes';
import styled from 'styled-components';

type AppBarLinkProps = {
  active: boolean;
};

const AppBarLink = styled(NavLink)<AppBarLinkProps>`
  &.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: var(--primary-color);
  }
`;

export function RapporterPage() {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const isRapportSelected = pathname !== Paths.rapporter.base;

  return (
    <Page className="p-0">
      <AppBar
        position="static"
        variant="outlined"
        elevation={0}
        color="inherit"
      >
        <Toolbar variant="dense">
          <AssessmentIcon fontSize="large" />
          <Typography variant="h6" color="inherit" component="div">
            Rapporter
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            gap="1rem"
            marginLeft="1.5rem"
          >
            {isKlubbAdmin(user) && (
              <Button component={AppBarLink} to={Paths.rapporter.loeftere}>
                Løftere
              </Button>
            )}

            <Button component={AppBarLink} to={Paths.rapporter.ranking}>
              Ranking
            </Button>

            <Button component={AppBarLink} to={Paths.rapporter.dommere}>
              Dommere
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box p="2rem">
        {!isRapportSelected && <Header2>Velg type rapport</Header2>}
        <Outlet />
      </Box>
    </Page>
  );
}
