// Values matching MUI
export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export function sortByProperty<T, K extends NestedKeyOf<T>>(
  items: T[],
  property: K,
  direction = SortDirection.Ascending
) {
  return items.sort((a, b) => {
    const aVal = getPropertyValue(a, property);
    const bVal = getPropertyValue(b, property);

    /* Always place null-values at the bottom */

    if (aVal === null) {
      return 1;
    }

    if (bVal === null) {
      return -1;
    }

    if (aVal < bVal) {
      return direction === SortDirection.Ascending ? -1 : 1;
    }

    if (aVal > bVal) {
      return direction === SortDirection.Ascending ? 1 : -1;
    }

    return 0;
  });
}

function getPropertyValue<T, K extends NestedKeyOf<T>>(
  object: T,
  property: K
): any {
  return property
    .split('.')
    .reduce((a, b): any => a ? a[b as keyof typeof a] : null , object);
}
