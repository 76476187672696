import { useState } from 'react';
import { SessionStorage } from 'src/utils/storage';

export function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const storedItem = SessionStorage.getItem<T>(key);
    return storedItem !== null ? storedItem : initialValue;
  });

  const setValue = (value: T) => {
    setStoredValue(value);
    SessionStorage.setItem(key, value);
  };

  return [storedValue, setValue] as const;
}
