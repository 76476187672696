import WestIcon from '@mui/icons-material/West';
import { Button, Divider, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { PublicPaths } from 'src/Routes';
import { usePublicStevneInternasjonal } from 'src/ajax/hooks/usePublicStevner';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemHeader,
  DescriptionListItemValue,
} from 'src/components/shared/DescriptionList/DescriptionList';
import { Loader } from 'src/components/shared/Loader/Loader';
import { PublicPage } from 'src/components/shared/Page/PublicPage';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { formatDatePeriod } from 'src/utils/date';
import { PublicInternasjonalResultatTable } from './PublicInternasjonalResultatTable';

export const PublicStevneInternasjonalPage = () => {
  const navigate = useNavigate();
  const { id: idParam } = useParams();
  const stevneId = idParam ? Number(idParam) : undefined;

  const { stevne, isLoadingStevne } = usePublicStevneInternasjonal(stevneId);

  const goToStevnerPage = () => {
    navigate(PublicPaths.stevner.base);
  };

  if (isLoadingStevne) {
    return (
      <PublicPage className="flex jc-center">
        <Loader active={true} />
      </PublicPage>
    );
  }

  if (!stevne) {
    return null;
  }

  const numberOfLifters = stevne.resultater.length;

  const datePeriod = formatDatePeriod(
    new Date(stevne.startdato),
    new Date(stevne.sluttdato)
  );

  return (
    <PublicPage>
      <Button startIcon={<WestIcon />} onClick={goToStevnerPage}>
        Tilbake
      </Button>

      <Header2 className="mt-4">{stevne.navn}</Header2>

      <Divider className="mt-4 mb-6" />

      <Stack spacing={3}>
        <div className="flex jc-sb">
          <DescriptionList className="mb-8">
            <DescriptionListItem>
              <DescriptionListItemHeader>Type</DescriptionListItemHeader>
              <DescriptionListItemValue>
                Internasjonalt stevne
              </DescriptionListItemValue>
            </DescriptionListItem>
            <DescriptionListItem>
              <DescriptionListItemHeader>Sted</DescriptionListItemHeader>
              <DescriptionListItemValue>{stevne.sted}</DescriptionListItemValue>
            </DescriptionListItem>
            <DescriptionListItem>
              <DescriptionListItemHeader>
                Antall løftere
              </DescriptionListItemHeader>
              <DescriptionListItemValue>
                {numberOfLifters}
              </DescriptionListItemValue>
            </DescriptionListItem>
            <DescriptionListItem>
              <DescriptionListItemHeader>Dato</DescriptionListItemHeader>
              <DescriptionListItemValue>{datePeriod}</DescriptionListItemValue>
            </DescriptionListItem>
          </DescriptionList>
        </div>

        <PublicInternasjonalResultatTable resultater={stevne.resultater} />
      </Stack>
    </PublicPage>
  );
};
