import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material';
import { useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import { Form } from 'src/components/shared/Form/Form';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { Dommer, Funksjonaerrolle } from 'src/models/Funksjonaer';
import { Klubb } from 'src/models/Klubb';
import { Person } from 'src/models/Person';
import { Stevnetype } from 'src/models/Stevnetype';
import { isKlubbAdmin, User } from 'src/models/User';
import { formatDate, isValidDate } from 'src/utils/date';
import { StevnerFormState } from '../Stevner.types';
import { DOMMERROLLE_ID, STEVNELEDERROLLE_ID } from './StevneForm.constants';
import { convertDateField, stevneSchema } from './StevnerForm.schema';
import { StevnerFormInfo } from './StevnerFormInfo';
import { StevnerFormPulje } from './StevnerFormPulje';

type StevnerFormProps = {
  user: User;
  klubber: Klubb[];
  stevnetyper: Stevnetype[];
  personer: Person[];
  dommere: Dommer[];
  funksjonaerroller: Funksjonaerrolle[];
  initialStevne?: StevnerFormState;
  onSubmit: (data: StevnerFormState) => void;
};

export function StevnerForm({
  initialStevne,
  user,
  klubber,
  stevnetyper,
  personer,
  dommere,
  funksjonaerroller,
  onSubmit,
}: StevnerFormProps) {
  const [selectedPuljeIndex, setSelectedPuljeIndex] = useState(0);

  const methods = useForm<StevnerFormState>({
    mode: 'onSubmit',
    resolver: yupResolver(stevneSchema),
    defaultValues: initialStevne
      ? {
          ...initialStevne,
          puljeList: initialStevne?.puljeList.map(pulje => {
            return {
              ...pulje,
              dato: pulje.dato && convertDateField(pulje.dato),
            };
          }),
          arrangorklubbId: isKlubbAdmin(user)
            ? user.klubb.id
            : initialStevne.arrangorklubbId,
        }
      : {
          arrangorklubbId: isKlubbAdmin(user) ? user.klubb.id : undefined,
          puljeList: [
            {
              puljeNr: 1,
              dato: new Date(),
              funksjonaerList: [
                { funksjonaerrolleId: DOMMERROLLE_ID },
                { funksjonaerrolleId: DOMMERROLLE_ID },
                { funksjonaerrolleId: DOMMERROLLE_ID },
                { funksjonaerrolleId: STEVNELEDERROLLE_ID },
              ],
              resultatList: [{}],
            },
          ],
          godkjent: false,
        },
  });

  const { control, watch, handleSubmit } = methods;
  const { errors } = useFormState({ control });

  const {
    fields,
    append: appendPulje,
    remove: removePulje,
  } = useFieldArray({
    name: 'puljeList',
    control,
  });

  const handleAppendPulje = () => {
    const newSelectedPuljeIndex = fields.length;

    appendPulje({
      puljeNr: fields.length + 1,
      dato: new Date(),
      funksjonaerList: [
        { funksjonaerrolleId: DOMMERROLLE_ID },
        { funksjonaerrolleId: DOMMERROLLE_ID },
        { funksjonaerrolleId: DOMMERROLLE_ID },
        { funksjonaerrolleId: STEVNELEDERROLLE_ID },
      ],
      resultatList: [{}],
    });

    setSelectedPuljeIndex(newSelectedPuljeIndex);
  };

  const handleRemovePulje = (index: number) => {
    const newSelectedPuljeIndex =
      selectedPuljeIndex === fields.length - 1
        ? selectedPuljeIndex - 1
        : selectedPuljeIndex;
    setSelectedPuljeIndex(newSelectedPuljeIndex);
    removePulje(index);
  };

  const puljeField = fields[selectedPuljeIndex];

  const watchFieldArray = watch('puljeList');
  const watchedPuljeList = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-16" style={{ position: 'relative' }}>
          <div
            style={{
              position: 'sticky',
              alignSelf: 'flex-start',
              top: 64 + 20, // 64 is top header height (fixed)
              width: '25rem',
            }}
          >
            <StevnerFormInfo
              user={user}
              klubber={klubber}
              stevnetyper={stevnetyper}
            />

            <Paper className="mt-8">
              <Button
                className="ml-4 mt-4"
                variant="outlined"
                onClick={handleAppendPulje}
              >
                Legg til pulje
              </Button>

              <Divider className="mt-4" />

              <List
                className="mt-4"
                style={{ maxHeight: 300, overflow: 'auto' }}
              >
                {watchedPuljeList.map((field, index) => (
                  <ListItem key={field.id}>
                    <ListItemButton
                      selected={selectedPuljeIndex === index}
                      onClick={() => setSelectedPuljeIndex(index)}
                    >
                      <ListItemText
                        primary={`Pulje ${field.puljeNr || '(ikke satt)'}`}
                        secondary={
                          field.dato && isValidDate(field.dato)
                            ? formatDate(field.dato)
                            : ''
                        }
                      />
                    </ListItemButton>

                    <IconButton onClick={() => handleRemovePulje(index)}>
                      <CloseIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Paper>

            <div className="mt-8">
              <Button variant="contained" type="submit" className="mt-8">
                {isKlubbAdmin(user)
                  ? 'Lagre og send til godkjenning'
                  : 'Lagre stevne'}
              </Button>

              {isKlubbAdmin(user) && (
                <Paragraph
                  className="mt-4"
                  style={{ fontStyle: 'italic', color: '#494949' }}
                >
                  Stevne kan redigeres i ettertid så lenge det ikke har blitt
                  godkjent.
                </Paragraph>
              )}

              {Object.keys(errors).length > 0 && (
                <Paragraph className="mt-4" style={{ color: '#d32f2f' }}>
                  Kan ikke lagre stevne. Undersøk om noen felter mangler verdi
                  eller er ugyldige.
                </Paragraph>
              )}
            </div>
          </div>

          <div className="flex flexcol gap-8 w100">
            {fields && puljeField && (
              <StevnerFormPulje
                key={puljeField.id}
                index={selectedPuljeIndex}
                dommere={dommere}
                personer={personer}
                funksjonaerroller={funksjonaerroller}
              />
            )}

            <Paragraph style={{ color: '#d32f2f' }}>
              {errors.puljeList?.message}
            </Paragraph>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
}
