import WestIcon from '@mui/icons-material/West';
import { Button, Divider, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PublicPaths } from 'src/Routes';
import { usePublicStevne } from 'src/ajax/hooks/usePublicStevner';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListItemHeader,
  DescriptionListItemValue,
} from 'src/components/shared/DescriptionList/DescriptionList';
import { Loader } from 'src/components/shared/Loader/Loader';
import { PublicPage } from 'src/components/shared/Page/PublicPage';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { useRemainingPageHeight } from 'src/hooks/useRemainingPageHeight';
import { findStevnePeriodFromPublicStevne } from 'src/utils/stevne';
import { PublicStevnePulje } from './PublicStevnePulje';

export const PublicStevnePage = () => {
  const { ref: tabsRef } = useRemainingPageHeight<HTMLButtonElement>();
  const navigate = useNavigate();
  const { id: idParam } = useParams();
  const stevneId = idParam ? Number(idParam) : undefined;

  const { stevne, isLoadingStevne } = usePublicStevne(stevneId);

  const [tab, setTab] = useState(0);

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const goToStevnerPage = () => {
    navigate(PublicPaths.stevner.base);
  };

  if (isLoadingStevne) {
    return (
      <PublicPage className="flex jc-center">
        <Loader active={true} />
      </PublicPage>
    );
  }

  if (!stevne) {
    return null;
  }

  const numberOfLifters =
    stevne.puljer.reduce((acc, cum) => acc + cum.resultater.length, 0) ?? 0;

  const datePeriod = findStevnePeriodFromPublicStevne(stevne);

  return (
    <PublicPage>
      <Button startIcon={<WestIcon />} onClick={goToStevnerPage}>
        Tilbake
      </Button>

      <Header2 className="mt-4">{stevne.stevnetype}</Header2>

      <Divider className="mt-4 mb-6" />

      <div className="flex jc-sb">
        <DescriptionList className="mb-8">
          <DescriptionListItem>
            <DescriptionListItemHeader>Type</DescriptionListItemHeader>
            <DescriptionListItemValue>
              {stevne.stevnetype}
            </DescriptionListItemValue>
          </DescriptionListItem>
          <DescriptionListItem>
            <DescriptionListItemHeader>Sted</DescriptionListItemHeader>
            <DescriptionListItemValue>{stevne.sted}</DescriptionListItemValue>
          </DescriptionListItem>
          <DescriptionListItem>
            <DescriptionListItemHeader>Klubb</DescriptionListItemHeader>
            <DescriptionListItemValue>
              {stevne.klubbName}
            </DescriptionListItemValue>
          </DescriptionListItem>
          <DescriptionListItem>
            <DescriptionListItemHeader>
              Antall løftere
            </DescriptionListItemHeader>
            <DescriptionListItemValue>
              {numberOfLifters}
            </DescriptionListItemValue>
          </DescriptionListItem>
          <DescriptionListItem>
            <DescriptionListItemHeader>Dato</DescriptionListItemHeader>
            <DescriptionListItemValue>{datePeriod}</DescriptionListItemValue>
          </DescriptionListItem>
        </DescriptionList>
      </div>

      <Tabs
        ref={tabsRef}
        orientation="horizontal"
        variant="scrollable"
        value={tab}
        onChange={onTabChange}
        className="mb-8"
      >
        {stevne.puljer.map(pulje => (
          <Tab
            key={pulje.puljeNr}
            label={`Pulje ${pulje.puljeNr}`}
            className="align-start"
          />
        ))}
      </Tabs>

      {stevne.puljer.map((pulje, index) => (
        <TabPanel
          key={pulje.puljeNr}
          value={tab}
          index={index}
          className="h100"
        >
          <PublicStevnePulje pulje={pulje} />
        </TabPanel>
      ))}
    </PublicPage>
  );
};
