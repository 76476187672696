import * as Yup from 'yup';
import { StevnerInternasjonalFormState } from './StevnerInternasjonalForm.types';

const internasjonalResultatSchema = Yup.object({
  personId: Yup.number().nullable().required('Løfter er påkrevd'),
  kroppsvekt: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .positive('Kroppsvekt må være et positivt tall')
    .required('Kroppsvekt er påkrevd'),
  dato: Yup.date().nullable().required('Dato er påkrevd'),
  rykk1: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Rykk er påkrevd'),
  rykk2: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  rykk3: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  stot1: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  stot2: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  stot3: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
});

export const internasjonalStevneSchema = Yup.object<
  Record<keyof StevnerInternasjonalFormState, Yup.AnySchema>
>({
  navn: Yup.string().nullable().required('Navn er påkrevd'),
  sted: Yup.string().nullable().required('Sted er påkrevd'),
  startdato: Yup.date().nullable().required('Startdato er påkrevd'),
  sluttdato: Yup.date().nullable().required('Sluttdato er påkrevd'),
  resultater: Yup.array(internasjonalResultatSchema),
});

export const internasjonalStevneDefaultValues: StevnerInternasjonalFormState = {
  navn: '',
  sted: '',
  startdato: new Date(),
  sluttdato: new Date(),
  resultater: [{ dato: new Date() }],
};
