import { Button } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Person } from 'src/models/Person';
import { StevnerInternasjonalFormState } from './StevnerInternasjonalForm.types';
import { StevnerInternasjonalFormResultat } from './StevnerInternasjonalFormResultat';

type StevnerInternasjonalFormResultaterProps = {
  personer: Person[];
};

export function StevnerInternasjonalFormResultater({
  personer,
}: StevnerInternasjonalFormResultaterProps) {
  const { control } = useFormContext<StevnerInternasjonalFormState>();

  const {
    fields: resultater,
    append: appendResultat,
    remove,
  } = useFieldArray({
    name: 'resultater',
    control,
  });

  const handleAppendResultat = () => {
    const newResultat = { dato: new Date() };

    appendResultat(newResultat);
  };

  return (
    <>
      <Button
        onClick={handleAppendResultat}
        variant="outlined"
        className="mb-6"
      >
        Legg til resultat
      </Button>

      <div className="flex flexcol gap-6">
        {resultater.map((resultat, index) => (
          <StevnerInternasjonalFormResultat
            key={resultat.id}
            index={index}
            personer={personer}
            removeResultat={remove}
          />
        ))}
      </div>
    </>
  );
}
