import { useAuth } from 'src/context/AuthContext';
import { isNvfAdmin } from 'src/models/User';

type AuthorizeNvfAdminProps = {
  children: React.ReactNode;
};

export function AuthorizeNvfAdmin({ children }: AuthorizeNvfAdminProps) {
  const { user } = useAuth();

  if (!isNvfAdmin(user)) {
    return null;
  }

  return <>{children}</>;
}
