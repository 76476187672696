import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyle } from './App.styles';
import { ErrorBoundary } from './components/shared/Error/ErrorBoundary';
import { Notifications } from './Notifications';
import { AppRoutes } from './Routes';
import { theme } from './styles/theme';
import { Swr } from './Swr';
import { HttpsRedirect } from './components/shared/Auth/HttpsRedirect';

export const App = () => {
  const isTestEnvironment =
    window.location.host === 'nvf-frontend-test.herokuapp.com';

  return (
    <HttpsRedirect>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Notifications />
            <Swr>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </Swr>
          </ThemeProvider>
        </ErrorBoundary>

        {isTestEnvironment && <TestEnvironment>Testmiljø</TestEnvironment>}
      </LocalizationProvider>
    </HttpsRedirect>
  );
};

const TestEnvironment = styled.div`
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 10;
  color: #c92127;
  font-weight: 800;
  font-size: 1.5rem;
`;
