import { PublicInternasjonaltStevne } from 'src/models/InternasjonaltStevne';
import { PublicStevne, PublicStevneDetail } from 'src/models/Stevne';
import { usePublicSWR } from './usePublicSWR';

export function usePublicStevner(fromDateISO: string, toDateISO: string) {
  const { data: stevner, error } = usePublicSWR<PublicStevne[]>(
    `public/stevner/?fra-dato=${fromDateISO}&til-dato=${toDateISO}`
  );

  return {
    stevner: stevner ?? [],
    isLoadingStevner: !stevner && !error,
    error: error,
  };
}

export function usePublicStevne(id?: number) {
  const { data: stevne, error } = usePublicSWR<PublicStevneDetail>(
    id ? `/public/stevner/${id}/` : undefined
  );

  return {
    stevne: stevne,
    isLoadingStevne: !stevne && !error && !!id,
    error: error,
  };
}

export function usePublicStevneInternasjonal(id?: number) {
  const { data: stevne, error } = usePublicSWR<PublicInternasjonaltStevne>(
    id ? `/public/internasjonal/stevner/${id}/` : undefined
  );

  return {
    stevne: stevne,
    isLoadingStevne: !stevne && !error && !!id,
    error: error,
  };
}
