import { isBefore } from 'date-fns';
import * as Yup from 'yup';
import { RankingSearchFormState } from './RankingSearchForm.types';
import { isValidDate } from 'src/utils/date';

export const RankingSearchFormSchema = Yup.object<
  Record<keyof RankingSearchFormState, Yup.AnySchema>
>({
  fromDate: Yup.date()
    .nullable()
    .required('Må oppgi en fra dato')
    .typeError('Datoen er ikke gyldig'),
  toDate: Yup.date()
    .nullable()
    .required('Må oppgi en til dato')
    .typeError('Datoen er ikke gyldig')
    .test(
      'is-valid-date-range',
      'Til dato må være etter fra dato',
      (_, context) => {
        const { fromDate, toDate } = context.parent as RankingSearchFormState;

        if (fromDate && isValidDate(fromDate) && toDate) {
          return isBefore(fromDate, toDate);
        }

        return true;
      }
    ),
  genderOption: Yup.string().required('Påkrevd'),
  kategorier: Yup.array().min(1, 'Angi minst en kategori'),
  vektklasse: Yup.string().nullable(),
  onlyBestResultatForPerson: Yup.boolean(),
});
