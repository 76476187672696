import * as Yup from 'yup';
import { RekordFormState } from './RekorderForm.types';
import { isValidDate, parseDate } from 'src/utils/date';

export const rekorderSchema = Yup.object<
  Record<keyof RekordFormState, Yup.AnySchema>
>({
  alderskategori: Yup.string()
    .nullable()
    .required('Kategori er påkrevd'),
  vektklasse: Yup.string()
    .nullable()
    .required('Vektklasse er påkrevd'),
  personId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  klubbId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  ovelse: Yup.string().nullable().required('Øvelse er påkrevd'),
  ovelseLoft: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Løft er påkrevd'),
  navn: Yup.string().nullable().required('Navn er påkrevd'),
  klubbnavn: Yup.string().nullable().required('Klubbnavn er påkrevd'),
  sted: Yup.string().nullable().required('Sted er påkrevd'),
  dato: Yup.date()
    .transform(value => convertDateField(value))
    .nullable()
    .required('Dato er påkrevd'),
  isStandard: Yup.boolean().nullable().required('Standard er påkrevd'),
});

export function convertDateField(value: Date | string) {
  if (typeof value === 'string') {
    return parseDate(value);
  }

  return isValidDate(value) ? value : undefined;
}
