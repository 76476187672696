import { useNavigate } from 'react-router-dom';
import { postRequest } from 'src/ajax/ajax';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { setTokens } from 'src/utils/auth';
import styled from 'styled-components';
import { mutate } from 'swr';
import { LoginForm, LoginState } from './LoginForm';
import logo from '../../../assets/images/nvf-logo.png';
import { Paper, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Breakpoint } from 'src/utils/breakpoints';
import { Paths } from 'src/Routes';

const StyledLoginPage = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 16rem;

  @media (max-width: ${Breakpoint.large}) {
    padding: 0 8rem;
  }

  @media (max-width: ${Breakpoint.medium}) {
    align-items: center;
    padding: 2rem;
  }
`;

const StyledLoginPageCard = styled(Paper)`
  max-width: 480px;
  width: 100%;
  height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 2rem;
`;

const BackgroundCurves = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: auto;
`;

const Logo = styled.img`
  height: 3rem;
  width: 7rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
`;

type LoginResponse = {
  access: string;
  refresh: string;
};

export const LoginPage = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<Nullable<string>>(null);

  const isLargeScreen = useMediaQuery(`(min-width: ${Breakpoint.large})`);

  const onLogin = async (state: LoginState) => {
    setLoginError(null);
    const response = await postRequest<LoginResponse>('/token/', state);

    if (response.ok) {
      setTokens(response.data.access, response.data.refresh);
      mutate('/users/me/', null, false);
      navigate(Paths.base);
    } else {
      if (response.error.response?.status === 401) {
        setLoginError('Feil brukernavn eller passord');
      }
    }
  };

  return (
    <StyledLoginPage>
      <StyledLoginPageCard>
        <Logo src={logo} alt="Norges Vektløfterforbund logo" />
        <Header1 className="mt-4 mb-8">Resultatsystem</Header1>
        <LoginForm onSubmit={onLogin} loginError={loginError} />
      </StyledLoginPageCard>

      {isLargeScreen && (
        <BackgroundCurves
          viewBox="0 0 1440 798"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M495 637.5C346 753 393.5 1010.5 -48.5 1010.5V-112H1488.5C1411.5 44.5 1401 -14.5 1219 70C1037 154.5 898 257.5 821 411.5C744 565.5 644 522 495 637.5Z"
            fill="#F8F8F8"
          />
        </BackgroundCurves>
      )}
    </StyledLoginPage>
  );
};
