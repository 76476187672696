import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { validationSchema } from './UserForm.schema';
import { UserFormState } from './UserForm.types';

type UserFormProps = {
  onSubmit: (state: UserFormState) => void;
};

export function UserForm({ onSubmit }: UserFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: null,
      email: null,
      firstName: null,
      lastName: null,
      password: null,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flexcol"
      style={{ maxWidth: 400 }}
    >
      <Controller
        control={control}
        name="username"
        render={({ field }) => (
          <TextField
            {...field}
            label="Brukernavn"
            value={field.value || ''}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <TextField
            {...field}
            label="E-post"
            value={field.value || ''}
            error={!!errors.email}
            helperText={errors.email?.message}
            className="mt-6"
          />
        )}
      />

      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <TextField
            {...field}
            label="Fornavn"
            value={field.value || ''}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            className="mt-6"
          />
        )}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <TextField
            {...field}
            label="Etternavn"
            value={field.value || ''}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            className="mt-6"
          />
        )}
      />

      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextField
            {...field}
            label="Passord"
            type="password"
            value={field.value || ''}
            error={!!errors.password}
            helperText={
              errors.password?.message || 'Passordet må være minst 8 tegn'
            }
            className="mt-6"
          />
        )}
      />

      <Button variant="contained" type="submit" className="mt-6">
        Opprett bruker
      </Button>
    </form>
  );
}
