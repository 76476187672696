import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDommergrader } from 'src/ajax/hooks/useDommergrader';
import { usePersoner } from 'src/ajax/hooks/usePersoner';
import { PersonerApi } from 'src/ajax/personer.api';
import { ConfirmModal } from 'src/components/shared/Modal/ConfirmModal';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { Page } from 'src/components/shared/Page/Page';
import { SearchField } from 'src/components/shared/Search/SearchField';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useAuth } from 'src/context/AuthContext';
import { Person } from 'src/models/Person';
import { isKlubbAdmin, isNvfAdmin } from 'src/models/User';
import {
  showApiErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { PersonFormState } from './PersonerForm.schema';
import { PersonerTable } from './PersonerTable';
import { PersonerTabs } from './PersonerTabs';
import { PersonForm } from './PersonForm';

function filterPersoner(filterText: string, personer: Person[]) {
  if (filterText !== '') {
    return personer.filter(person =>
      `${person.fornavn} ${person.etternavn}`
        .toLocaleLowerCase()
        .includes(filterText.toLocaleLowerCase())
    );
  }

  return personer;
}

export const PersonerPage = () => {
  const { user } = useAuth();
  const { personer, isLoadingPersoner, mutatePersoner } = usePersoner();
  const { dommergrader } = useDommergrader();
  const [searchValue, setSearchValue] = useState('');
  const [filterText, setFilterText] = useState('');

  const [person, setPerson] = useState<Nullable<Person>>(null);
  const [personToDelete, setPersonToDelete] = useState<Nullable<Person>>(null);

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const handleOnSearch = () => setFilterText(searchValue);

  const filteredPersoner = useMemo(
    () => filterPersoner(filterText, personer || []),
    [filterText, personer]
  );

  const onCreate = () => {
    setPerson({} as Person);
  };

  const onCreateSubmit = async (data: PersonFormState) => {
    const response = await PersonerApi.createPerson(data);

    if (response.ok) {
      showSuccessNotification('Person opprettet');
      mutatePersoner();
    } else {
      showApiErrorNotification(response.error, 'Kunne ikke opprette person');
    }
    setPerson(null);
  };

  const onEdit = (person: Person) => {
    setPerson(person);
  };

  const onEditSubmit = async (nvfId: string, data: PersonFormState) => {
    const response = await PersonerApi.updatePerson(nvfId, data);

    if (response.ok) {
      showSuccessNotification('Person oppdatert');
      mutatePersoner();
    } else {
      showApiErrorNotification(response.error, 'Kunne ikke oppdatere person');
    }
    setPerson(null);
  };

  const onDelete = (person: Person) => {
    setPersonToDelete(person);
  };

  const onDeleteConfirm = async () => {
    if (!personToDelete) return;

    const response = await PersonerApi.deletePerson(personToDelete.nvfId);

    if (response.ok) {
      showSuccessNotification('Person slettet');
      mutatePersoner(
        personer.filter(p => p.nvfId !== personToDelete?.nvfId),
        false
      );
    } else {
      showApiErrorNotification(response.error, 'Kunne ikke slette person');
    }

    setPersonToDelete(null);
  };

  const onDeleteCancel = () => {
    setPersonToDelete(null);
  };

  const onCancel = () => {
    setPerson(null);
  };

  const createNewNvfId = async (nvfId: string) => {
    const response = await PersonerApi.createNewNvfId(nvfId);

    if (response.ok) {
      showSuccessNotification('Ny NVF-ID er opprettet');
      mutatePersoner();
    } else {
      showApiErrorNotification(response.error, 'Kunne ikke opprette ny NVF-ID');
    }

    setPerson(null);
  }

  return (
    <Page>
      <Header1>Personer</Header1>

      {isKlubbAdmin(user) ? (
        <Paragraph className="mt-6">
          Her finner du alle personer som er opprettet for din klubb i
          resultatsystemet. Dette inkluderer både løftere og funksjonærer.
        </Paragraph>
      ) : (
        <Paragraph className="mt-6">
          Her finner du alle personer som er opprettet i resultatsystemet. Dette
          inkluderer både løftere og funksjonærer.
        </Paragraph>
      )}

      <div className="flex jc-sb mt-8">
        <SearchField
          value={searchValue}
          placeholder="Søk etter person"
          onChange={handleOnSearchChange}
          onSearchClick={handleOnSearch}
        />

        <Button variant="outlined" onClick={onCreate} startIcon={<AddIcon />}>
          Legg til ny person
        </Button>
      </div>

      <section className="mt-10">
        {isNvfAdmin(user) && (
          <PersonerTable
            personer={filteredPersoner}
            isLoadingPersoner={isLoadingPersoner}
            onRowClick={onEdit}
            onDeleteClick={onDelete}
          />
        )}

        {isKlubbAdmin(user) && (
          <PersonerTabs
            klubbId={user.klubb.id}
            personer={filteredPersoner}
            isLoadingPersoner={isLoadingPersoner}
            onRowClick={onEdit}
            onDeleteClick={onDelete}
          />
        )}
      </section>

      <Modal open={!!person} onClose={onCancel}>
        <ModalHeader>
          <Header1>{person?.id ? 'Rediger' : 'Opprett'} person</Header1>
        </ModalHeader>
        <ModalBody>
          <PersonForm
            person={person}
            personer={personer}
            dommergrader={dommergrader}
            createNewNvfId={createNewNvfId}
            onSubmit={data =>
              person?.id
                ? onEditSubmit(person.nvfId, data)
                : onCreateSubmit(data)
            }
            onCancel={onCancel}
          ></PersonForm>
        </ModalBody>
      </Modal>

      <ConfirmModal
        open={!!personToDelete}
        onConfirm={onDeleteConfirm}
        onClose={onDeleteCancel}
        title="Slett person"
        confirmButtonText="Slett"
      >
        Er du sikker på at du vil slette
        <b className="ml-2">{`${personToDelete?.fornavn} ${personToDelete?.etternavn}`}</b>
        ?
      </ConfirmModal>
    </Page>
  );
};
