import { Form } from 'src/components/shared/Form/Form';
import {
  RekorderResultatFormState,
  rekorderResultatFormInitialState,
} from './RekorderResultatForm.types';
import { useForm } from 'react-hook-form';
import { rekorderResultatSchema } from './RekorderResultatForm.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResultatList } from 'src/models/Stevne';
import { FormSelect } from 'src/components/shared/Form/FormSelect';
import { formatDate } from 'src/utils/date';
import { RekordOvelseToReadable, rekordOvelser } from 'src/models/Rekord';
import { Button } from '@mui/material';

type RekorderResultatFormProps = {
  resultater: ResultatList[];
  onSubmit: (state: RekorderResultatFormState) => void;
};

export function RekorderResultatForm({
  resultater,
  onSubmit,
}: RekorderResultatFormProps) {
  const formMethods = useForm<RekorderResultatFormState>({
    resolver: yupResolver(rekorderResultatSchema),
    defaultValues: rekorderResultatFormInitialState,
  });

  const { control, watch, handleSubmit } = formMethods;

  const nasjonaltResultatId = watch('nasjonaltResultatId');
  const internasjonaltResultatId = watch('internasjonaltResultatId');

  const nasjonaleResultater = resultater.filter(
    r => r.origin === 'NASJONALT' && (r.besteRykk || r.besteStot || r.total)
  );
  const internasjonaleResultater = resultater.filter(
    r => r.origin === 'INTERNASJONALT'
  );

  const nasjonaleResultaterOptions = nasjonaleResultater.map(r => ({
    id: r.id,
    label: `${formatDate(r.dato)} - ${r.historicPersonNavn} - ${
      r.besteRykk || 'x'
    } - ${r.besteStot || 'x'} - ${r.total || 'x'}`,
  }));
  const internasjonaleResultaterOptions = internasjonaleResultater.map(r => ({
    id: r.id,
    label: `${formatDate(r.dato)} - ${r.historicPersonNavn} - ${
      r.besteRykk || 'x'
    } - ${r.besteStot || 'x'} - ${r.total || 'x'}`,
  }));

  const ovelseOptions = rekordOvelser.map(ovelse => ({
    id: ovelse,
    label: RekordOvelseToReadable[ovelse],
  }));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        name="nasjonaltResultatId"
        label="Nasjonale resultater"
        options={nasjonaleResultaterOptions}
        disabled={!!internasjonaltResultatId}
        control={control}
      />

      <FormSelect
        name="internasjonaltResultatId"
        label="Internasjonale resultater"
        options={internasjonaleResultaterOptions}
        disabled={!!nasjonaltResultatId}
        control={control}
      />

      <FormSelect
        name="ovelse"
        label="Øvelse"
        options={ovelseOptions}
        control={control}
      />

      <Button variant="contained" type="submit">
        Opprett rekord
      </Button>
    </Form>
  );
}
