import styled from 'styled-components';

type Header3Props = {
  bold?: boolean;
};

export const Header3 = styled.h3<Header3Props>`
  font-family: 'Open Sans';
  font-size: 1rem;
  font-weight: ${props => (props.bold ? 600 : 500)};
  line-height: 1.25rem;
  color: inherit;
  margin: 0;
`;
