import PublicIcon from '@mui/icons-material/Public';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'src/Routes';
import { useInternasjonalStevner } from 'src/ajax/hooks/useInternasjonalStevne';
import { useStevner } from 'src/ajax/hooks/useStevner';
import { ValidationErrors } from 'src/components/shared/Error/ValidationErrors';
import { TabPanel } from 'src/components/shared/Tab/TabPanel';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { useAuth } from 'src/context/AuthContext';
import { Stevne } from 'src/models/Stevne';
import { isNvfAdmin } from 'src/models/User';
import { Page } from '../../shared/Page/Page';
import { Header1 } from '../../shared/Typography/Header1';
import { StevnerInternasjonalTable } from './Internasjonal/StevnerInternasjonalTable';
import { StevnerTable } from './StevnerTable';
import { StevnerUploadProtokollModal } from './StevnerUploadProtokollModal';

const StevnerTab = {
  InProgress: 0,
  Approved: 1,
  Internasjonal: 2,
};

export const StevnerPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [tab, setTab] = useState(StevnerTab.InProgress);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState<
    string[]
  >([]);

  const { stevner, isLoadingStevner } = useStevner();
  const { internasjonaleStevner, isLoadingInternasjonalStevner } =
    useInternasjonalStevner();

  const onTabChange = (_event: React.SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const onViewStevne = (stevne: Stevne) => {
    navigate(Paths.stevner.useShow(stevne.id));
  };

  const goToCreateInternasjonaltStevne = () => {
    navigate(Paths.stevner.internasjonal.create);
  };

  const goToEditInternasjonaltStevne = (id: number) => {
    navigate(Paths.stevner.internasjonal.useEdit(id));
  };

  const inProgressStevner = stevner.filter(s => !s.godkjent);
  const approvedStevner = stevner.filter(s => s.godkjent);

  return (
    <Page>
      {validationErrorMessages.length > 0 && (
        <ValidationErrors messages={validationErrorMessages} />
      )}

      <Header1>Stevner</Header1>

      <div className="flex jc-sb align-end mt-6 mb-6">
        <Paragraph className="align-self-start">
          Her finner du alle stevner som er opprettet i resultatsystemet.
        </Paragraph>

        <div className="flex gap-4">
          {isNvfAdmin(user) && (
            <Button
              variant="outlined"
              onClick={goToCreateInternasjonaltStevne}
              endIcon={<PublicIcon />}
            >
              Nytt internasjonalt stevne
            </Button>
          )}

          <Button
            variant="outlined"
            onClick={() => setModalIsOpen(true)}
            endIcon={<UploadIcon />}
          >
            Last opp stevneprotokoll
          </Button>
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={onTabChange} aria-label="Product tabs">
          <Tab label="Stevner til godkjenning" />
          <Tab label="Godkjente stevner" />
          {isNvfAdmin(user) && <Tab label="Internasjonale stevner" />}
        </Tabs>
      </Box>
      <TabPanel index={StevnerTab.InProgress} value={tab}>
        <StevnerTable
          stevner={inProgressStevner}
          isLoadingStevner={isLoadingStevner}
          onRowClick={onViewStevne}
        />
      </TabPanel>
      <TabPanel index={StevnerTab.Approved} value={tab}>
        <StevnerTable
          stevner={approvedStevner}
          isLoadingStevner={isLoadingStevner}
          onRowClick={onViewStevne}
        />
      </TabPanel>

      {isNvfAdmin(user) && (
        <TabPanel index={StevnerTab.Internasjonal} value={tab}>
          <StevnerInternasjonalTable
            internasjonaleStevner={internasjonaleStevner}
            isLoading={isLoadingInternasjonalStevner}
            onRowClick={goToEditInternasjonaltStevne}
          />
        </TabPanel>
      )}

      <StevnerUploadProtokollModal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        setValidationErrorMessages={setValidationErrorMessages}
      />
    </Page>
  );
};
