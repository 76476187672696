import { yupResolver } from '@hookform/resolvers/yup';
import { startOfYear } from 'date-fns';
import { useForm } from 'react-hook-form';
import { Form } from 'src/components/shared/Form/Form';
import { FormButton } from 'src/components/shared/Form/FormButton';
import { FormDate } from 'src/components/shared/Form/FormDate';
import { FormSelect } from 'src/components/shared/Form/FormSelect';
import { Dommer } from 'src/models/Funksjonaer';
import {
  DommerStevnerFormState,
  dommerStevnerSchema,
} from './DommerStevnerForm.schema';

type Props = {
  dommere: Dommer[];
  isSubmitting: boolean;
  onSubmit: (state: DommerStevnerFormState) => void;
};

export function DommerStevnerForm({ dommere, isSubmitting, onSubmit }: Props) {
  const { control, handleSubmit } = useForm<DommerStevnerFormState>({
    resolver: yupResolver(dommerStevnerSchema),
    defaultValues: {
      fromDate: startOfYear(new Date()),
      toDate: new Date(),
      personId: null,
    },
  });

  const dommereOptions = dommere.map(dommer => ({
    id: dommer.personId,
    label: `${dommer.navn}`,
  }));

  return (
    <Form compact onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        name="personId"
        label="Velg dommer"
        options={dommereOptions}
        control={control}
      />
      <FormDate name="fromDate" label="Fra dato" control={control} />
      <FormDate name="toDate" label="Til dato" control={control} />
      <FormButton label="Hent stevner" loading={isSubmitting} />
    </Form>
  );
}
