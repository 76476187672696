import { useAuth } from 'src/context/AuthContext';
import { Stevne, StevneDetail } from 'src/models/Stevne';
import { isKlubbAdmin } from 'src/models/User';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export function useStevner() {
  const { user } = useAuth();
  const klubbId = isKlubbAdmin(user) ? user.klubb.id : undefined;

  const { data: stevner, error } = useSWR<Stevne[], ApiError>(
    klubbId ? `/stevne/?klubb_id=${klubbId}` : '/stevne/'
  );

  return {
    stevner: stevner ?? [],
    isLoadingStevner: !stevner && !error,
    error: error,
  };
}

export function useStevne(id: number | undefined) {
  const {
    data: stevne,
    error,
    mutate,
  } = useSWR<StevneDetail, ApiError>(id ? `/stevne/${id}/` : undefined);

  return {
    stevne: stevne,
    isLoadingStevne: !stevne && !error && !!id,
    mutateStevne: mutate,
    error: error,
  };
}
