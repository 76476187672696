export type UserFormState = {
  username: Nullable<string>;
  email: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  password: Nullable<string>;
};

export type UserCreateInput = {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  registrationKey: string;
};

export function mapUserFormStateToUserCreateInput(
  state: UserFormState,
  registrationKey: string
): UserCreateInput {
  return {
    username: state.username as string,
    email: state.email as string,
    firstName: state.firstName as string,
    lastName: state.lastName as string,
    password: state.password as string,
    registrationKey,
  };
}
