import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  showApiErrorNotification,
  showSuccessNotification,
} from 'src/Notifications';
import { Paths } from 'src/Routes';
import { usePublicRekorder } from 'src/ajax/hooks/useRekorder';
import { useResultater } from 'src/ajax/hooks/useResultater';
import { RekorderApi } from 'src/ajax/rekorder.api';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { Page } from 'src/components/shared/Page/Page';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { RekorderOverview } from '../../shared/Rekorder/RekorderOverview';
import { RekorderResultatForm } from './RekorderResultatForm';
import { mapRekorderResultatFormStateToRekordResultatInput } from './RekorderResultatForm.mappers';
import { RekorderResultatFormState } from './RekorderResultatForm.types';

export function RekorderPage() {
  const navigate = useNavigate();

  const { rekorder, refreshRekorder } = usePublicRekorder();
  const { resultater, isLoadingResultater } = useResultater();

  const [isRekorderResultatFormOpen, setIsRekorderResultatFormOpen] =
    useState(false);

  const createRekordFromResultat = async (state: RekorderResultatFormState) => {
    const input = mapRekorderResultatFormStateToRekordResultatInput(state);

    const response = await RekorderApi.createRekordFromResultat(input);

    if (response.ok) {
      showSuccessNotification('Rekord opprettet');
    } else {
      showApiErrorNotification(response.error);
    }

    toggleRekorderResultatForm();
    refreshRekorder();
  };

  const navigateToRekorderForm = () => {
    navigate(Paths.rekorder.create);
  };

  const toggleRekorderResultatForm = () => {
    setIsRekorderResultatFormOpen(!isRekorderResultatFormOpen);
  };

  return (
    <Page isLoading={isLoadingResultater}>
      <div className="flex jc-sb gap-8 fw-wrap">
        <Header1>Oversikt over rekorder</Header1>

        <div className="flex gap-4 fw-wrap mb-8">
          <Button onClick={navigateToRekorderForm} variant="outlined">
            Legg til ny rekord
          </Button>
          <Button onClick={toggleRekorderResultatForm} variant="outlined">
            Legg til rekord fra resultat
          </Button>
        </div>
      </div>

      <RekorderOverview rekorder={rekorder} />

      <Modal
        open={isRekorderResultatFormOpen}
        onClose={toggleRekorderResultatForm}
      >
        <ModalHeader>
          <Header1>Rekord fra resultat</Header1>
        </ModalHeader>
        <ModalBody>
          <RekorderResultatForm
            resultater={resultater}
            onSubmit={createRekordFromResultat}
          />
        </ModalBody>
      </Modal>
    </Page>
  );
}
