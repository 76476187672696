import { Button } from '@mui/material';
import { Header1 } from '../Typography/Header1';
import { Modal } from './Modal';
import { ModalBody, ModalFooter, ModalHeader } from './Modal.styles';

type ConfirmModalProps = {
  open: boolean;
  title: string;
  confirmButtonText?: string;
  closeButtonText?: string;
  isDanger?: boolean;
  children: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
};

export function ConfirmModal({
  open,
  title,
  confirmButtonText = 'Bekreft',
  closeButtonText = 'Avbryt',
  isDanger = false,
  children,
  onConfirm,
  onClose,
}: ConfirmModalProps) {
  const handleOnConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        <Header1>{title}</Header1>
      </ModalHeader>

      <ModalBody style={{ flexDirection: 'row' }}>{children}</ModalBody>

      <ModalFooter>
        <Button variant="contained" onClick={handleOnConfirm} color={isDanger ? 'error' : 'primary'}>
          {confirmButtonText}
        </Button>

        <Button variant="text" onClick={onClose} className="ml-8">
          {closeButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
