import { AxiosError } from 'axios';
import useSWR from 'swr';
import { ApiError, publicAxios } from '../ajax';

async function publicFetcher(url: string) {
  return publicAxios
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      throw new ApiError(error);
    });
}

export function usePublicSWR<T>(url?: string) {
  const data = useSWR<T, ApiError>(url, publicFetcher);

  return data;
}
