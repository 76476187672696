import { RekordOvelse } from 'src/models/Rekord';
import { Alderskategori, VektklasseType } from 'src/models/Stevne';

export type RekordFormState = {
  alderskategori: Nullable<Alderskategori>;
  vektklasse: Nullable<VektklasseType>;
  personId: Nullable<number>;
  klubbId: Nullable<number>;
  ovelse: Nullable<RekordOvelse>;
  ovelseLoft: Nullable<number>;
  navn: Nullable<string>;
  klubbnavn: Nullable<string>;
  sted: Nullable<string>;
  dato: Nullable<Date>;
  isStandard: Nullable<boolean>;
};

export const rekordFormInitialState: RekordFormState = {
  alderskategori: null,
  vektklasse: null,
  personId: null,
  klubbId: null,
  ovelse: null,
  ovelseLoft: null,
  navn: '',
  klubbnavn: '',
  sted: '',
  dato: null,
  isStandard: false,
};
