import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type FormDateProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  placeholder?: string;
};

export function FormDate<T extends FieldValues>({
  name,
  control,
  defaultValue,
  label,
  placeholder,
  ...rest
}: FormDateProps<T>) {
  const { field, fieldState } = useController({ name, control, defaultValue, ...rest });

  const error = fieldState.error?.message;

  const { ref, ...fieldRest } = field;

  return (
    <Controller
        {...fieldRest}
        control={control}
        render={({ field }) => (
          <DesktopDatePicker
            {...field}
            label={label}
            inputFormat="dd/MM/yyyy"
            renderInput={params => (
              <TextField
                {...params}
                error={!!error}
                helperText={error}
              />
            )}
          />
        )}
      />
  );
}
