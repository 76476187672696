import { Stevnetype } from 'src/models/Stevnetype';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export function useStevnetyper() {
  const requestUri = '/stevnetype/';
  const { data, error } = useSWR<Stevnetype[], ApiError>(requestUri);

  return {
    stevnetyper: data ?? [],
    isLoading: !data && !error,
    error: error,
  };
}