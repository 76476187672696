import { ResultatList } from 'src/models/Stevne';
import useSWR from 'swr';
import { ApiError } from '../ajax';

export function useResultater() {
  const { data: resultater, error } = useSWR<ResultatList[], ApiError>(
    '/resultat/'
  );

  return {
    resultater: resultater || [],
    isLoadingResultater: !resultater && !error,
    error: error,
  };
}
