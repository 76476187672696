import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Header3 } from 'src/components/shared/Typography/Header3';
import { VektklasseRekord, VektklasseRekordLoft } from 'src/models/Rekord';
import { AlderskategoriToReadable } from 'src/models/Stevne';
import { formatDate } from 'src/utils/date';
import { MISSING_VALUE } from './RekorderTableRow';

type Props = { rekord: VektklasseRekord };

export function RekorderTableRowDetail({ rekord }: Props) {
  return (
    <div
      style={{
        margin: '16px 0',
        paddingTop: 8,
        textAlign: 'center',
      }}
    >
      <Header3 className="mb-4">
        Vektklasse {rekord.vektklasseNavn} -{' '}
        {AlderskategoriToReadable[rekord.kategoriForkortelse]}
      </Header3>
      <Table>
        <TableBody>
          <DetailRow rekordLoeft={rekord.rykk} />
          <DetailRow rekordLoeft={rekord.stot} />
          <DetailRow rekordLoeft={rekord.sammenlagt} />
        </TableBody>
      </Table>
    </div>
  );
}

type DetailRowProps = {
  rekordLoeft: VektklasseRekordLoft | null;
};

function DetailRow({ rekordLoeft }: DetailRowProps) {
  return (
    <TableRow>
      <TableCell className={rekordLoeft?.isStandard ? 'italic' : ''}>
        {rekordLoeft?.isStandard
          ? 'Standard'
          : rekordLoeft?.navn || MISSING_VALUE}
      </TableCell>

      <TableCell className={rekordLoeft?.isStandard ? 'italic' : ''}>
        {rekordLoeft?.isStandard
          ? 'Standard'
          : rekordLoeft?.klubbnavn || MISSING_VALUE}
      </TableCell>

      <TableCell className={rekordLoeft?.isStandard ? 'italic' : ''}>
        {rekordLoeft?.isStandard
          ? 'Standard'
          : rekordLoeft?.sted || MISSING_VALUE}
      </TableCell>

      <TableCell>
        {rekordLoeft ? formatDate(rekordLoeft.dato) : MISSING_VALUE}
      </TableCell>
      <TableCell>
        <span style={{ fontWeight: 'bold' }}>
          {rekordLoeft?.ovelseLoft || MISSING_VALUE}
        </span>
      </TableCell>
    </TableRow>
  );
}
