import { Paper, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRegistrationKlubb } from 'src/ajax/hooks/useRegistrationLink';
import { UsersApi } from 'src/ajax/users.api';
import logo from 'src/assets/images/nvf-logo.png';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { showApiErrorNotification, showSuccessNotification } from 'src/Notifications';
import { Paths } from 'src/Routes';
import { Breakpoint } from 'src/utils/breakpoints';
import styled from 'styled-components';
import { UserForm } from './UserForm';
import { mapUserFormStateToUserCreateInput, UserFormState } from './UserForm.types';

export function UsersRegistrationPage() {
  const { registrationKey } = useParams();
  const navigate = useNavigate()

  const { klubb, isLoading } = useRegistrationKlubb(registrationKey as string);

  const isLargeScreen = useMediaQuery(`(min-width: ${Breakpoint.large})`);

  const createUser = async (state: UserFormState) => {
    if (!registrationKey) {
      return;
    }

    const createData = mapUserFormStateToUserCreateInput(state, registrationKey);
    const response = await UsersApi.createUser(createData);

    if (response.ok) {
      navigate(Paths.innlogging)
      showSuccessNotification('Bruker opprettet')
    } else {
      showApiErrorNotification(response.error)
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (!klubb || !registrationKey) {
    return null;
  }

  return (
    <StyledUsersRegistrationPage>
      <StyledUsersRegistrationPageCard>
        <Logo src={logo} alt="Norges Vektløfterforbund logo" />
        <Header1 className="mb-10">Administrator for {klubb.navn}</Header1>
        <UserForm onSubmit={createUser} />
      </StyledUsersRegistrationPageCard>

      {isLargeScreen && (
        <BackgroundCurves
          viewBox="0 0 1440 798"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M495 637.5C346 753 393.5 1010.5 -48.5 1010.5V-112H1488.5C1411.5 44.5 1401 -14.5 1219 70C1037 154.5 898 257.5 821 411.5C744 565.5 644 522 495 637.5Z"
            fill="#F8F8F8"
          />
        </BackgroundCurves>
      )}
    </StyledUsersRegistrationPage>
  );
}

const StyledUsersRegistrationPage = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 16rem;

  @media (max-width: ${Breakpoint.large}) {
    padding: 0 8rem;
  }

  @media (max-width: ${Breakpoint.medium}) {
    align-items: center;
    padding: 2rem;
  }
`;

const StyledUsersRegistrationPageCard = styled(Paper)`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 2rem;
  padding-top: 6.5rem;
  padding-bottom: 3rem;
`;

const BackgroundCurves = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: auto;
`;

const Logo = styled.img`
  height: 3rem;
  width: 7rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
`;
