import { useMemo } from 'react';
import { VektklasseRekord } from 'src/models/Rekord';
import { VeteranAlderskategori } from 'src/models/Stevne';
import { RekorderTable } from './RekorderTable';

type Props = {
  kategori: VeteranAlderskategori;
  rekorder: VektklasseRekord[];
  expandAll: boolean;
};

export function RekorderVeteran({ kategori, rekorder, expandAll }: Props) {
  const kategoriRekorder = useMemo(() => {
    return rekorder.filter(rekord => rekord.kategoriForkortelse === kategori);
  }, [kategori, rekorder]);

  return <RekorderTable rekorder={kategoriRekorder} expandAll={expandAll} />;
}
