import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/nvf-logo.png';
import { Paths } from 'src/Routes';
import { PublicNavigation } from './PublicNavigation';
import { NavigationItem } from './Navigation.types';
import { StyledHeaderItem, Logo, Title } from './Navigation.styles';

type Props = {
  items: NavigationItem[];
};

export function PublicHeaderLarge({ items }: Props) {
  return (
    <>
      <StyledHeaderItem>
        <Logo src={logo} alt="Norges Vektløfterforbund logo" />
        <Title>Resultatsystem</Title>
      </StyledHeaderItem>

      <StyledHeaderItem>
        <PublicNavigation items={items} />
      </StyledHeaderItem>

      <StyledHeaderItem style={{ marginLeft: 'auto' }}>
        <Link to={Paths.base} style={{ color: 'inherit' }}>
          <Button>Logg inn admin</Button>
        </Link>
      </StyledHeaderItem>
    </>
  );
}
