import { UserCreateInput } from 'src/components/pages/Users/UserForm.types';
import { UsersRegistrationLinkFormState } from 'src/components/pages/Users/UsersModal';
import { postRequest } from './ajax';

export const UsersApi = {
  createRegistrationLink: (state: UsersRegistrationLinkFormState) => {
    return postRequest<string>('/users/registration/', state);
  },
  createUser: (state: UserCreateInput) => {
    return postRequest('/user/', state);
  },
};
