import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridInitialState,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Paragraph } from 'src/components/shared/Typography/Paragraph';
import { User } from 'src/models/User';

const columns: GridColDef<User>[] = [
  {
    field: 'fullName',
    headerName: 'Navn',
    filterable: false,
    width: 250,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.lastName}, ${params.row.firstName}`,
  },
  {
    field: 'klubbName',
    headerName: 'Klubb',
    filterable: false,
    width: 250,
    valueGetter: (params: GridValueGetterParams) => params.row.canAccessKlubb[0].navn,
  },
  {
    field: 'username',
    headerName: 'Brukernavn',
    filterable: false,
    width: 200,
  },
  {
    field: 'email',
    headerName: 'E-post',
    filterable: false,
    width: 200,
  },
];

type UsersTableProps = {
  users: User[];
  isLoadingUsers: boolean;
};

export const UsersTable = ({ users, isLoadingUsers }: UsersTableProps) => {
  if (users.length === 0 && !isLoadingUsers) {
    return <Paragraph className="mt-2">Ingen brukere funnet</Paragraph>;
  }

  const initialState: GridInitialState = {
    sorting: {
      sortModel: [{ field: 'fullName', sort: 'asc' }],
    },
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        initialState={initialState}
        autoHeight
        rows={users}
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoadingUsers}
      />
    </Box>
  );
};
