import { RekordOvelse } from "src/models/Rekord";

export type RekorderResultatFormState = {
  nasjonaltResultatId: Nullable<number>;
  internasjonaltResultatId: Nullable<number>;
  ovelse: Nullable<RekordOvelse>;
};

export const rekorderResultatFormInitialState: RekorderResultatFormState = {
  nasjonaltResultatId: null,
  internasjonaltResultatId: null,
  ovelse: null,
};
