import { Kjonn } from 'src/models/Person';
import * as Yup from 'yup';

export type PersonFormState = {
  fornavn: string;
  etternavn: string;
  kjonn: Kjonn | '';
  fodselsdato: string;
  klubbId: Nullable<number>;
  dommergradId: Nullable<number>;
  dommerkursdato: Nullable<string>;
  dommerkursansvarlig: Nullable<string>;
};

export const validationSchema = Yup.object<
  Record<keyof PersonFormState, Yup.AnySchema>
>({
  fornavn: Yup.string().required('Fornavn er påkrevd'),
  etternavn: Yup.string().required('Etternavn er påkrevd'),
  kjonn: Yup.string().required('Kjønn er påkrevd'),
  fodselsdato: Yup.date()
    .transform(value => (!isNaN(value.getTime()) ? value : undefined))
    .required('Fødselsdato er påkrevd')
    .nullable()
    .max(new Date(), 'Fødselsdatoen må være før dagens dato')
    .test(
      'is-four-digit-year',
      'Årstallet må være fire siffer',
      value =>
        value !== undefined && value !== null && value.getFullYear() > 999
    ),
  klubbId: Yup.number().required('Klubb er påkrevd').nullable(),
  dommergradId: Yup.number().nullable(),
  dommerkursdato: Yup.string().nullable(),
  dommerkursansvarlig: Yup.string().nullable(),
});
