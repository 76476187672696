import { Button, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from 'src/components/shared/Modal/Modal';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'src/components/shared/Modal/Modal.styles';
import { BodyType } from 'src/components/shared/Typography/BodyType';
import { DetailType } from 'src/components/shared/Typography/DetailType';
import { Header2 } from 'src/components/shared/Typography/Header2';
import { RekordCandidate, RekordOvelseToReadable } from 'src/models/Rekord';

type Props = {
  open: boolean;
  candidates: RekordCandidate[];
  onConfirm: (candidates: RekordCandidate[]) => void;
  onClose: () => void;
};

export function RekordCandidatesModal({
  open,
  candidates,
  onConfirm,
  onClose,
}: Props) {
  const [selectedCandidateIndexes, setSelectedCandidateIndexes] = useState<
    number[]
  >([]);

  useEffect(() => {
    setSelectedCandidateIndexes(candidates.map((_, index) => index));
  }, [candidates]);

  const toggleCandidate = (index: number) => {
    if (selectedCandidateIndexes.includes(index)) {
      setSelectedCandidateIndexes(
        selectedCandidateIndexes.filter(i => i !== index)
      );
    } else {
      setSelectedCandidateIndexes([...selectedCandidateIndexes, index]);
    }
  };

  const handleConfirm = () => {
    const selectedCandidates = selectedCandidateIndexes.map(
      index => candidates[index]
    );
    onConfirm(selectedCandidates);
  };

  const disableConfirm = selectedCandidateIndexes.length === 0;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        <Header2>Oversikt over nye rekorder</Header2>
      </ModalHeader>

      <ModalBody className="flex gap-2">
        <BodyType>Velg hvilke rekorder som skal opprettes:</BodyType>

        <div>
          {candidates.map((candidate, index) => (
            <div key={index}>
              <Checkbox
                checked={selectedCandidateIndexes.includes(index)}
                onClick={() => toggleCandidate(index)}
              />
              <DetailType>{`${candidate.person.fornavn} ${
                candidate.person.etternavn
              } | ${candidate.kategori.forkortelse} | ${
                candidate.vektklasse.navn
              } | ${RekordOvelseToReadable[candidate.ovelse]} | ${
                candidate.ovelseLoft
              }`}</DetailType>
            </div>
          ))}
        </div>
      </ModalBody>

      <ModalFooter className="gap-8">
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={disableConfirm}
        >
          Opprett rekorder
        </Button>
        <Button onClick={onClose} variant="outlined">
          Avbryt
        </Button>
      </ModalFooter>
    </Modal>
  );
}
