import { Klubb } from './Klubb';

export enum UserType {
  KlubbAdmin = 1,
  NvfAdmin = 2,
}

type BaseUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  isSuperuser: boolean;
};

export type NvfAdminUser = BaseUser & {
  userType: UserType.NvfAdmin;
};

export type KlubbAdminUser = BaseUser & {
  userType: UserType.KlubbAdmin;
  klubb: Klubb;
};

export type User = NvfAdminUser | KlubbAdminUser;

export function isNvfAdmin(user: User): user is NvfAdminUser {
  return user.userType === UserType.NvfAdmin;
}

export function isKlubbAdmin(user: User): user is KlubbAdminUser {
  return user.userType === UserType.KlubbAdmin;
}
