import { isValidDate, parseDate } from 'src/utils/date';
import * as Yup from 'yup';
import { StevnerFormState } from '../Stevner.types';
import { DOMMERROLLE_ID, STEVNELEDERROLLE_ID } from './StevneForm.constants';

const funksjonaerSchema = Yup.object({
  personId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Funksjonær er påkrevd'),
  funksjonaerrolleId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Funksjonaerrolle er påkrevd'),
});

const resultatSchema = Yup.object({
  personId: Yup.string().required('Løfter er påkrevd').nullable(),
  kroppsvekt: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .positive('Kroppsvekt må være et positivt tall')
    .required('Kroppsvekt er påkrevd'),
  startnummer: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .positive('Startnummer må være et positivt tall')
    .required('Startnummer er påkrevd'),
  rykk1: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  rykk2: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  rykk3: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  stot1: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  stot2: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
  stot3: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(),
});

const puljeSchema = Yup.object({
  puljeNr: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Puljenummer er påkrevd')
    .min(1, 'Puljenummer kan ikke være mindre enn 1'),
  dato: Yup.date()
    .transform(value => convertDateField(value))
    .nullable()
    .required('Puljedato er påkrevd'),
  beskrivelse: Yup.string().nullable(),
  resultatList: Yup.array(resultatSchema).required('Resultater er påkrevd'),
  funksjonaerList: Yup.array(funksjonaerSchema)
    .nullable()
    .required('Funksjonærer er påkrevd')
    .test(
      'is-three-or-more-dommere',
      'En pulje må ha minst tre dommere',
      funksjonaerList =>
        (funksjonaerList?.filter(f => f?.funksjonaerrolleId === DOMMERROLLE_ID)
          ?.length || 0) >= 3
    )
    .test(
      'is-one-stevneleder',
      'En pulje må ha en stevneleder',
      funksjonaerList =>
        (funksjonaerList?.filter(
          f => f?.funksjonaerrolleId === STEVNELEDERROLLE_ID
        )?.length || 0) === 1
    ),
});

export const stevneSchema = Yup.object<
  Record<keyof StevnerFormState, Yup.AnySchema>
>({
  sted: Yup.string().required('Sted er påkrevd').nullable(),
  arrangorklubbId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Arrangørklubb er påkrevd'),
  stevnetypeId: Yup.number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable()
    .required('Stevnetype er påkrevd'),
  puljeList: Yup.array(puljeSchema)
    .required('Puljer er påkrevd')
    .test(
      'only-one-result-per-person',
      'En løfter kan ikke ha flere resultat i samme stevne',
      puljeList => {
        const allPersonIds =
          puljeList
            ?.flatMap(pulje => pulje.resultatList)
            .map(resultat => resultat?.personId)
            .filter(personId => {
              return personId !== undefined && personId !== null;
            }) || [];

        return allPersonIds.length === new Set(allPersonIds).size;
      }
    ),
  godkjent: Yup.boolean(),
});

export function convertDateField(value: Date | string) {
  if (typeof value === 'string') {
    return parseDate(value);
  }

  return isValidDate(value) ? value : undefined;
}
