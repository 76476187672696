import styled from 'styled-components';

export const StyledSidebar = styled.aside`
  position: fixed;
  width: 10rem;
  left: 0;
  bottom: 0;
  height: calc(100% - 4rem);
  margin-top: 4rem;
  border-right: 1px solid var(--quaternary-color);
  background: #ffffff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavItemsList = styled.ul`
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const NavItem = styled.li`
  position: relative;
  height: 80px;
  width: 100%;
  display: flex;
  background: transparent;
  color: var(--primary-color);
  border-right: 1px solid transparent;
  transition: all 200ms ease-out;

  :hover {
    background: rgba(var(--primary-color-rgb), 0.05);
    border-right: 1px solid var(--quaternary-color);
  }

  a {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
`;

export const InkBar = styled.span`
  position: absolute;
  width: 2px;
  height: 5rem;
  right: -1px;
  z-index: 10;
  background-color: var(--primary-color);
  transition: top 500ms cubic-bezier(0.35, 0, 0.25, 1);
`;
