import { isBefore } from 'date-fns';
import { isValidDate } from 'src/utils/date';
import * as Yup from 'yup';

export type DommerStevnerFormState = {
  fromDate: Date;
  toDate: Date;
  personId: Nullable<number>;
};

export const dommerStevnerSchema = Yup.object<
  Record<keyof DommerStevnerFormState, Yup.AnySchema>
>({
  fromDate: Yup.date().required('Fra dato er påkrevd').nullable().typeError('Datoen er ikke gyldig'),
  toDate: Yup.date().required('Til dato er påkrevd').nullable().typeError('Datoen er ikke gyldig').test(
    'is-valid-date-range',
    'Til dato må være etter fra dato',
    (_, context) => {
      const { fromDate, toDate } =
        context.parent as DommerStevnerFormState;

      if (fromDate && isValidDate(fromDate) && toDate) {
        return isBefore(fromDate, toDate);
      }

      return true;
    }
  ),
  personId: Yup.number().required('Dommer er påkrevd').nullable(),
});
