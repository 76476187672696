import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useMemo } from 'react';
import { TableCellHeader } from 'src/components/shared/Table/TableCellHeader';
import { useSort } from 'src/hooks/useSort';
import { Funksjonaer } from 'src/models/Stevne';
import { sortByProperty } from 'src/utils/sort';

type FunksjonaerListProps = {
  funksjonaerer: Funksjonaer[];
};

export function FunksjonaerList({ funksjonaerer }: FunksjonaerListProps) {
  const { sortedBy, sortDirection, isActive, requestSort, getDirection } =
    useSort<Funksjonaer>('funksjonaerrolle.navn');

  const sortedFunksjonarer = useMemo(() => {
    if (sortedBy) {
      return sortByProperty(funksjonaerer, sortedBy, sortDirection);
    }

    return funksjonaerer;
  }, [funksjonaerer, sortedBy, sortDirection]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCellHeader width={300}>
              <TableSortLabel
                active={isActive('funksjonaerrolle.navn')}
                direction={getDirection('funksjonaerrolle.navn')}
                onClick={() => requestSort('funksjonaerrolle.navn')}
              >
                Rolle
              </TableSortLabel>
            </TableCellHeader>
            <TableCellHeader>
              <TableSortLabel
                active={isActive('navn')}
                direction={getDirection('navn')}
                onClick={() => requestSort('navn')}
              >
                Navn
              </TableSortLabel>
            </TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedFunksjonarer.map(funksjonaer => (
            <TableRow key={funksjonaer.id}>
              <TableCell>{funksjonaer.funksjonaerrolle.navn}</TableCell>
              <TableCell>{funksjonaer.navn}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
