import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { mapPersonIdToReadable, Person } from 'src/models/Person';

type StevnerFormResultatProps = {
  index: number;
  puljeIndex: number;
  personer: Person[];
  handleRemoveResultat: (index: number) => void;
};

export function StevnerFormResultat({
  index,
  puljeIndex,
  personer,
  handleRemoveResultat,
}: StevnerFormResultatProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Paper style={{ padding: '1rem' }}>
      <div className="mb-2 flex jc-end">
        <IconButton onClick={() => handleRemoveResultat(index)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="flex fw-wrap gap-16">
        <Grid container columns={2} spacing={2} style={{ flex: '1 1 12rem' }}>
          <Grid item xs={2}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].personId`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={personer.map(person => person.id)}
                  getOptionLabel={option =>
                    mapPersonIdToReadable(option, personer)
                  }
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Løfter"
                      error={
                        !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                          ?.personId
                      }
                      helperText={
                        errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                          ?.personId?.message
                      }
                    />
                  )}
                  value={value === undefined ? null : value}
                  onChange={(_, item) => {
                    onChange(item);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].startnummer`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Startnummer"
                  fullWidth
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.startnummer
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.startnummer?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].kroppsvekt`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Kroppsvekt"
                  fullWidth
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.kroppsvekt
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.kroppsvekt?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columns={3} spacing={1} style={{ flex: '1 1 20rem' }}>
          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].rykk1`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Rykk 1"
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.rykk1
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]?.rykk1
                      ?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].rykk2`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Rykk 2"
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.rykk2
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]?.rykk2
                      ?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].rykk3`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Rykk 3"
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.rykk3
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]?.rykk3
                      ?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].stot1`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Støt 1"
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.stot1
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]?.stot1
                      ?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].stot2`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Støt 2"
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.stot2
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]?.stot2
                      ?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={1}>
            <Controller
              name={`puljeList.[${puljeIndex}].resultatList.[${index}].stot3`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Støt 3"
                  error={
                    !!errors.puljeList?.[puljeIndex]?.resultatList?.[index]
                      ?.stot3
                  }
                  helperText={
                    errors.puljeList?.[puljeIndex]?.resultatList?.[index]?.stot3
                      ?.message
                  }
                  value={field.value === undefined || field.value === null ? '' : field.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
