import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { isNvfAdmin } from 'src/models/User';
import { Paths } from 'src/Routes';
import { InkBar, NavItem, NavItemsList, StyledSidebar } from './Sidebar.styles';
import { EmojiEvents } from '@mui/icons-material';

type NavigationItem = {
  path: string;
  name: string;
  disabled?: boolean;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

export const Sidebar = () => {
  const location = useLocation();
  const { user } = useAuth();
  const isUserNvfAdmin = isNvfAdmin(user);

  const navItems: NavigationItem[] = [
    {
      path: Paths.base,
      name: 'Oversikt',
      Icon: DashboardIcon,
    },
    {
      path: Paths.personer.base,
      name: 'Personer',
      Icon: PeopleOutlineIcon,
    },
    {
      path: Paths.klubber.base,
      name: 'Klubber',
      disabled: !isUserNvfAdmin,
      Icon: GroupsIcon,
    },
    {
      path: Paths.stevner.base,
      name: 'Stevner',
      Icon: FormatListBulletedIcon,
    },
    {
      path: Paths.rekorder.base,
      name: 'Rekorder',
      Icon: EmojiEvents,
      disabled: !isUserNvfAdmin,
    },
    {
      path: Paths.rapporter.base,
      name: 'Rapporter',
      Icon: ArticleIcon,
    },
    {
      path: Paths.users.base,
      name: 'Brukere',
      Icon: SwitchAccountIcon,
      disabled: !isUserNvfAdmin,
    },
  ];

  const activeNavItems = navItems.filter(x => !x.disabled);
  const activeNavItemIndex = activeNavItems.findIndex(
    x => location.pathname.match(/[^/]+/g)?.at(1) === x.path.match(/[^/]+/g)?.at(1)
  );
  const itemHeight = 80;

  return (
    <StyledSidebar>
      <NavItemsList>
        {activeNavItems.map(({ path, name, Icon }) => (
          <NavItem key={path}>
            <NavLink to={path}>
              <Icon />
              {name}
            </NavLink>
          </NavItem>
        ))}

        <InkBar
          style={{
            top: activeNavItemIndex * itemHeight,
          }}
        />
      </NavItemsList>
    </StyledSidebar>
  );
};
