import { Breakpoint } from 'src/utils/breakpoints';
import { Colors } from 'src/utils/colors';
import styled from 'styled-components';

export const DescriptionListItem = styled.li`
  padding: 0 1.5rem;

  &:first-of-type {
    padding-left: 0;
  }

  &:not(:last-of-type) {
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${Colors.quaternary};
  }
`;

export const DescriptionListItemHeader = styled.h5`
  color: ${Colors.primary};
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const DescriptionListItemValue = styled.span`
  color: ${Colors.primary};
`;

export const DescriptionList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  position: relative;

  @media (max-width: ${Breakpoint.small}) {
    flex-direction: column;

    &&& ${DescriptionListItem} {
      padding: 0.5rem 0;
      border-right: 0;
      border-bottom: 1px solid ${Colors.quaternary};
    }
  }
`;
