import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { BodyType } from '../Typography/BodyType';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// Accept .xlsx format (https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv)
const acceptXlsxFormat =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const defaultPlaceholder = 'Dra og slipp en fil, eller klikk for å velge.';

type DropzoneProps = {
  multipleFiles?: boolean;
  placeholder?: string;
  allowedFileExtensions?: string[];
  onChange: (files: File[]) => void;
};

export function Dropzone({
  multipleFiles,
  placeholder = defaultPlaceholder,
  allowedFileExtensions = [acceptXlsxFormat],
  onChange,
}: DropzoneProps) {
  const [files, setFiles] = useState<File[]>([]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: allowedFileExtensions,
      multiple: multipleFiles,
      onDrop: (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles
          .filter(x => !files.some(file => file.name === x.name))
          .concat(files);
        setFiles(newFiles);
        onChange(newFiles);
      },
    });

  const onRemoveFile = (file: File) => {
    const newFiles = files.filter(x => x.name !== file.name);
    setFiles(newFiles);
    onChange(newFiles);
  };

  const showDropzone = multipleFiles || files.length !== 1;

  return (
    <>
      {files.length !== 0 && (
        <FileList>
          {files.map(file => (
            <FileEntry key={file.name}>
              <span>{file.name}</span>
              <IconButton
                onClick={() => {
                  onRemoveFile(file);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </FileEntry>
          ))}
        </FileList>
      )}

      {showDropzone && (
        <Container {...getRootProps({ isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <BodyType>{placeholder}</BodyType>
        </Container>
      )}
    </>
  );
}

type ContainerProps = {
  isDragAccept: boolean;
  isDragReject: boolean;
};

const getBorderColor = (props: ContainerProps) => {
  if (props.isDragAccept) {
    return '#a2d2ff';
  }
  if (props.isDragReject) {
    return '#EE8679';
  }
  return '#999';
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: #999;
  background-color: transparent;
  border: dashed 2px ${props => getBorderColor(props)};
  outline: none;
  transition: border 0.25s ease-in-out;
`;

const FileList = styled.ul`
  list-style: none;
  margin-bottom: 2rem;
  padding: 0;

  > li:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const FileEntry = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px #ddd;
  padding: 1rem 2rem;
`;
